import {configureStore} from "@reduxjs/toolkit";
import settingsReducer from "./loaderSlice";
import progressReducer from "./progressStatusSlice";
import classSettingReducer from "./classSlice";
import branchList from "./branchSlice";

export default configureStore({
    reducer: {
        classSetting: classSettingReducer,
        branch: branchList,
        settings: settingsReducer,
        progressStatus: progressReducer
    }
});
