import baseApi from "../../api/baseApi";

export const addEmployee = async (
  branchName,
  branchId,
  role,
  joiningDate,
  designationName,
  designationId,
  departmentName,
  departmentId,
  qualification,
  experienceDetails,
  totalExperience,
  employeeName,
  gender,
  religion,
  bloodGroup,
  dob,
  mobileNo,
  email,
  presentAddress,
  permanentAddress,
  profilePhoto,
  userName,
  password,
  facebook,
  website,
  linkedIn,
  accountName,
  accountNumber,
  routingNumber,
  bankBranch,
  bankName
) => {
  const payload = new FormData();
  payload.append("branchName", branchName);
  payload.append("branchId", branchId);
  payload.append("role", role);
  payload.append("joiningDate", joiningDate);
  payload.append("designationName", designationName);
  payload.append("designationId", designationId);
  payload.append("departmentName", departmentName);
  payload.append("departmentId", departmentId);
  payload.append("qualification", qualification);
  payload.append("experienceDetails", experienceDetails);
  payload.append("totalExperience", totalExperience);
  payload.append("employeeName", employeeName);
  payload.append("gender", gender);
  payload.append("religion", religion);
  payload.append("bloodGroup", bloodGroup);
  payload.append("dob", dob);
  payload.append("mobileNo", mobileNo);
  payload.append("email", email);
  payload.append("presentAddress", presentAddress);
  payload.append("permanentAddress", permanentAddress);
  payload.append("profilePhoto", profilePhoto);
  payload.append("userName", userName);
  payload.append("password", password);
  payload.append("facebook", facebook);
  payload.append("website", website);
  payload.append("linkedIn", linkedIn);
  payload.append("accountName", accountName);
  payload.append("accountNumber", accountNumber);
  payload.append("routingNumber", routingNumber);
  payload.append("bankBranch", bankBranch);
  payload.append("bankName", bankName);
  try {
    const response = await baseApi.post(`/api/addEmployee`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getAllEmployee = async (
  page,
  showPerPage,
  searchTerm,
  branchId,
  role
) => {
  try {
    const response = await baseApi.get(
      `/api/getAllEmployee?page=${page}&limit=${showPerPage}&search=${searchTerm}&branchId=${branchId}&role=${role}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getOneEmployee = async (id) => {
  try {
    const response = await baseApi.get(`/api/getOneEmployee/${id}`);
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateOneEmployee = async (
  id,
  branchName,
  branchId,
  role,
  joiningDate,
  designationName,
  designationId,
  departmentName,
  departmentId,
  qualification,
  experienceDetails,
  totalExperience,
  employeeName,
  gender,
  religion,
  bloodGroup,
  dob,
  mobileNo,
  email,
  presentAddress,
  permanentAddress,
  profilePhoto,
  userName,
  password,
  facebook,
  website,
  linkedIn,
  accountName,
  accountNumber,
  routingNumber,
  bankBranch,
  bankName,
  oldProfilePhoto
) => {
  const payload = new FormData();
  payload.append("branchName", branchName);
  payload.append("branchId", branchId);
  payload.append("role", role);
  payload.append("joiningDate", joiningDate);
  payload.append("designationName", designationName);
  payload.append("designationId", designationId);
  payload.append("departmentName", departmentName);
  payload.append("departmentId", departmentId);
  payload.append("qualification", qualification);
  payload.append("experienceDetails", experienceDetails);
  payload.append("totalExperience", totalExperience);
  payload.append("employeeName", employeeName);
  payload.append("gender", gender);
  payload.append("religion", religion);
  payload.append("bloodGroup", bloodGroup);
  payload.append("dob", dob);
  payload.append("mobileNo", mobileNo);
  payload.append("email", email);
  payload.append("presentAddress", presentAddress);
  payload.append("permanentAddress", permanentAddress);
  payload.append("profilePhoto", profilePhoto);
  payload.append("userName", userName);
  payload.append("password", password);
  payload.append("facebook", facebook);
  payload.append("website", website);
  payload.append("linkedIn", linkedIn);
  payload.append("accountName", accountName);
  payload.append("accountNumber", accountNumber);
  payload.append("routingNumber", routingNumber);
  payload.append("bankBranch", bankBranch);
  payload.append("bankName", bankName);
  payload.append("oldProfilePhoto", oldProfilePhoto);

  try {
    const response = await baseApi.put(
      `/api/updateOneEmployee/${id}`,
      payload,
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const deleteEmployee = async (id) => {
  try {
    const response = await baseApi.delete(`/api/deleteOneEmployee/${id}`);
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return { deleteStatus: response?.data?.message };
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateEmployeePassword = async (id, newPassword) => {
  try {
    const response = await baseApi.get(
      `/api/updateEmployeePassword/${id}?newPassword=${newPassword}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};
