export const transformDataForSelectOptions = ({ data, name, id, numeric }) => {
  return data.map((item) => {
    return {
      value: JSON.stringify({
        ...(name ? { name: item[name] } : {}),
        ...(id ? { id: item[id] } : {}),
        ...(numeric ? { numeric: item[numeric] } : {}),
      }),
      name: item[name],
    };
  });
};
