import React from 'react';
import {useLocation} from "react-router-dom";
import qs from "querystring";
import {localServer} from "../../../../api/baseURL.json";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => qs.parse(search), [search]);
}

const PaymentCancelled = () => {
    const data = useQuery();

    React.useEffect(() => {
        window.opener.postMessage({type: "cancelled", data, source: "school"}, "https://portal.sjs.edu.bd");
    }, [data]);
    return (
        <div className="text-center text-warning">
            <h1>Payment Cancelled</h1>
        </div>
    );
};

export default PaymentCancelled;
