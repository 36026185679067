import intLogo from "assets/img/logo.png";
import principalSignature from "assets/img/partials/principal-signature.png";
import vicePrincipalSignature from "assets/img/partials/vice-principal-signature.png";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { calculateProportionalValue } from "utils/calculateProportionalValue";
import { NaNHandler } from "utils/NaNHandler";
import "./style.css";

const getShortName = (subject) => {
  if (subject === "English Language") {
    return "Eng Lang";
  } else if (subject === "Spelling and Dictation") {
    return "Spelling";
  } else if (subject === "English Literature") {
    return "Eng Lit";
  } else {
    return subject;
  }
};

function alphaSortSubject(subjects) {
  let sortedArray = subjects?.sort((a, b) => {
    const nameA = a.subject.toUpperCase();
    const nameB = b.subject.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
}

function getGrade(percentage) {
  percentage = Math.round(Number(percentage));

  if (percentage === 0) {
    return "";
  }

  if (percentage >= 95) {
    return "A+";
  }
  if (percentage < 95 && percentage >= 90) {
    return "A";
  }
  if (percentage < 90 && percentage >= 85) {
    return "B+";
  }
  if (percentage < 85 && percentage >= 80) {
    return "B";
  }
  if (percentage < 80 && percentage >= 75) {
    return "C+";
  }
  if (percentage < 75 && percentage >= 70) {
    return "C";
  }
  if (percentage < 70 && percentage >= 65) {
    return "D+";
  }
  if (percentage < 65 && percentage >= 60) {
    return "D";
  }
  if (percentage < 60) {
    return "U";
  }
}

function getGradeFinal(percentage) {
  percentage = Math.round(Number(percentage));

  if (percentage === 0) {
    return "";
  }

  if (percentage >= 95 * 2) {
    return "A+";
  }
  if (percentage < 95 * 2 && percentage >= 90 * 2) {
    return "A";
  }
  if (percentage < 90 * 2 && percentage >= 85 * 2) {
    return "B+";
  }
  if (percentage < 85 * 2 && percentage >= 80 * 2) {
    return "B";
  }
  if (percentage < 80 * 2 && percentage >= 75 * 2) {
    return "C+";
  }
  if (percentage < 75 * 2 && percentage >= 70 * 2) {
    return "C";
  }
  if (percentage < 70 * 2 && percentage >= 65 * 2) {
    return "D+";
  }
  if (percentage < 65 * 2 && percentage >= 60 * 2) {
    return "D";
  }
  if (percentage < 60 * 2) {
    return "U";
  }
}

function getGrade_11_12(percentage) {
  // getGrade_11_12
  percentage = Math.round(Number(percentage));

  if (percentage === 0) {
    return "";
  }

  if (percentage >= 90) {
    return "A+";
  }
  if (percentage < 90 && percentage >= 80) {
    return "A";
  }
  if (percentage < 80 && percentage >= 70) {
    return "B";
  }
  if (percentage < 70 && percentage >= 60) {
    return "C";
  }
  if (percentage < 60 && percentage >= 50) {
    return "D";
  }
  if (percentage < 50) {
    return "U";
  }
}

const SingleSheet = ({
  className,
  classNumeric,
  semester,
  marks,
  highestMarks,
  fullMark,
  studentInfo,
  qualities,
  filteredSubjects,
  paperMarks,
  style = { width: "100%" },
}) => {
  // Results
  const [semResult, setSemResult] = React.useState({});
  const [sem2Result, setSem2Result] = React.useState({});
  const [halfYearlyResult, setHalfYearlyResult] = React.useState({});
  const [yearlyResult, setYearlyResult] = React.useState({});
  const [preQualifyingResult, setPreQualifyingResult] = React.useState({});
  const [qualifyingResult, setQualifyingResult] = React.useState({});
  const [preparationResult, setPreparationResult] = React.useState({});
  const [preCambridgeResult, setPreCambridgeResult] = React.useState({});
  const [interimResult, setInterimResult] = React.useState({});

  // Highest Mark
  const [sem1HighestMarks, setSem1HighestMarks] = React.useState([]);
  const [sem2HighestMarks, setSem2HighestMarks] = React.useState([]);

  // Subjects
  const [subjects, setSubjects] = React.useState([]);
  const [preSubjects, setPreSubjects] = React.useState([]);
  const [qualifyingSubjects, setQualifyingSubjects] = React.useState([]);
  const [halfSubjects, setHalfSubjects] = React.useState([]);
  const [yearlySubjects, setYearlySubjects] = React.useState([]);
  const [preparationSubjects, setPreparationSubjects] = React.useState([]);
  const [preCambridgeSubjects, setPreCambridgeSubjects] = React.useState([]);
  const [interimSubjects, setInterimSubjects] = React.useState([]);

  React.useEffect(() => {
    setSemResult(_.find(marks, { _id: "1stSemester" }));
    setSem2Result(_.find(marks, { _id: "2ndSemester" }));
    setPreQualifyingResult(_.find(marks, { _id: "pre_qualifying" }));
    setQualifyingResult(_.find(marks, { _id: "qualifying" }));
    setHalfYearlyResult(_.find(marks, { _id: "half_yearly" }));
    setYearlyResult(_.find(marks, { _id: "yearly" }));
    setPreparationResult(_.find(marks, { _id: "preparation" }));
    setPreCambridgeResult(_.find(marks, { _id: "pre_cambridge" }));
    setInterimResult(_.find(marks, { _id: "interim" }));

    setSubjects(
      _.uniq(
        _.find(marks, { _id: "1stSemester" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setPreSubjects(
      _.uniq(
        _.find(marks, { _id: "pre_qualifying" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setQualifyingSubjects(
      _.uniq(
        _.find(marks, { _id: "qualifying" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setHalfSubjects(
      _.uniq(
        _.find(marks, { _id: "half_yearly" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setYearlySubjects(
      _.uniq(_.find(marks, { _id: "yearly" })?.results.map((x) => x.subject))
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setPreparationSubjects(
      _.uniq(
        _.find(marks, { _id: "preparation" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setPreCambridgeSubjects(
      _.uniq(
        _.find(marks, { _id: "pre_cambridge" })?.results.map((x) => x.subject)
      )
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
    setInterimSubjects(
      _.uniq(_.find(marks, { _id: "interim" })?.results.map((x) => x.subject))
        .sort()
        ?.filter((s) => filteredSubjects?.find((f) => f?.label === s))
    );
  }, [marks, highestMarks, fullMark, filteredSubjects, paperMarks, qualities]);

  React.useEffect(() => {
    if (highestMarks?.length > 0) {
      const sem1 =
        highestMarks?.filter((item) => item?.semester === "1stSemester") || [];
      const sem2 =
        highestMarks?.filter((item) => item?.semester === "2ndSemester") || [];
      setSem1HighestMarks(sem1);
      setSem2HighestMarks(sem2);
    }
  }, [marks, highestMarks]);

  // Get highest mark
  function getHighestMark(data, sub) {
    const subject = data?.find((x) => x?.subjectName === sub);
    const highestMark = subject?.highestMark;
    return highestMark;
  }

  // Get full mark
  function getHalfYearlyFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "half_yearly")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getYearlyFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "yearly")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getPreQualifyingFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "pre_qualifying")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getQualifyingFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "qualifying")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getPreparationFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "preparation")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getPreCambridgeFullMark(subject, examType) {
    const full = fullMark
      ?.find((e) => e?._id === "pre_cambridge")
      ?.fullMarks?.find(
        (x) => x?.subject === subject && x?.examType === examType
      );
    return Number(full?.marks || 0);
  }

  function getInterimFullMark(subject, examType) {
    // const full = fullMark
    //   ?.find((e) => e?._id === "interim")
    //   ?.fullMarks?.find(
    //     (x) => x?.subject === subject && x?.examType === examType
    //   );
    // return full?.marks;
    return 50;
  }

  // Get subject result
  function getSubjectResult(results = [], subject, examType, markIndex) {
    const marks =
      _.find(results, { subject, examType })?.marks?.map(
        (mark) =>
          mark?.reduce((acc, cv) => acc + Math.round(Number(cv || 0)), 0) || 0
      ) || [];

    if (markIndex !== undefined && markIndex !== null) {
      return Math.round(Number(marks[markIndex || 0] || 0));
    } else {
      return Math.round(
        Number(
          marks?.reduce((acc, cv) => acc + Math.round(Number(cv || 0)), 0) || 0
        )
      );
    }
  }

  function getPaperWiseSubjectResult(results = [], subject, examType, paper) {
    const marks = _.find(results, { subject, examType })?.marks?.flat();
    return Math.round(Number(marks[marks?.length - paper] || 0));
  }

  return (
    <div style={style} className="printable-content">
      {semester === "1stSemester" &&
        Number(classNumeric) >= 3 &&
        Number(classNumeric) <= 7 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Half Yearly Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">95%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">85%</td>
                          <td className="customTdg">B+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">75%</td>
                          <td className="customTdg">C+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">65%</td>
                          <td className="customTdg">D+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;60%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh"></th>
                      <th className="customTh" colSpan={5}>
                        Half Yearly Examination
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">SUBJECT</th>
                      <th className="customTh">CLASS TEST</th>
                      <th className="customTh">SBA</th>
                      <th className="customTh">ASSIGNMENT</th>
                      <th className="customTh">TERM</th>
                      <th className="customTh">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "classTest"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(semResult?.results || [], x, "sba")
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "assignment"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "term"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Number(
                              getSubjectResult(
                                semResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "assignment"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "term"
                                )
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="customTd font-weight-bold">Total Mark</td>
                      <td colSpan={5} className="customTd"></td>
                    </tr>
                  </tfoot>
                </table>
                <table className="customTable table2">
                  <thead>
                    <tr>
                      <th colSpan={3} className="customTh">
                        &nbsp;
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">G.TOTAL</th>
                      <th className="customTh">GRADE</th>
                      <th className="customTh">HIGHEST</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">
                          {Math.round(
                            Number(
                              getSubjectResult(
                                semResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "assignment"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "term"
                                )
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {getGrade(
                            Math.round(
                              getSubjectResult(
                                semResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "assignment"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "term"
                                )
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Number(
                              getHighestMark(sem1HighestMarks || [], x) || 0
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="customTd font-weight-bold">
                        {/* {JSON.stringify(semResult?.results?.filter(s => filteredSubjects?.find(f => f?.label === s?.subject))
                          ?.map((x) => x?.marks))} */}
                        {Math.round(
                          Number(
                            filteredSubjects
                              ?.map((sub) =>
                                semResult?.results
                                  ?.filter((s) => sub?.label === s?.subject)
                                  ?.map((x) => x?.marks)
                                  ?.flat(Infinity)
                                  ?.reduce((total, mark) => (total += mark), 0)
                              )
                              ?.filter(Boolean)
                              ?.flat(Infinity)
                              ?.reduce(
                                (total, subjMark) =>
                                  (total += Math.round(subjMark)),
                                0
                              ) || 0
                          )
                        )}
                      </td>
                      <td className="customTd"></td>
                      <td className="customTd"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }} className="ndPart">
                <u>Evaluation</u>
              </p>
            </div>
            <div>
              <p className="ndPart">Results</p>
            </div>
            <div className="ndPart d-flex justify-content-between p-3">
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <th className="customThg" scope="col">
                        Grade
                      </th>
                      <th className="customThg" scope="col">
                        Meaning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">A+</td>
                      <td className="customTdg">Excellent</td>
                    </tr>
                    <tr>
                      <td className="customTdg">A</td>
                      <td className="customTdg">Good</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B+</td>
                      <td className="customTdg">Satisfactory</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B</td>
                      <td className="customTdg">Needs Improvement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Regularity</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.regularity || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Patriotism</td>
                      <td className="customTdg">
                        {qualities?.patriotism || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Honesty</td>
                      <td className="customTdg">{qualities?.honesty || ""}</td>
                    </tr>
                    <tr>
                      <td className="customTdg">Leadership</td>
                      <td className="customTdg">
                        {qualities?.leadership || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Discipline</td>
                      <td className="customTdg">
                        {qualities?.discipline || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Cooperation</td>
                      <td className="customTdg">
                        {qualities?.cooperation || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Participation</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.participation || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Tolerance</td>
                      <td className="customTdg">
                        {qualities?.tolerance || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Awareness</td>
                      <td className="customTdg">
                        {qualities?.awareness || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Punctuality</td>
                      <td className="customTdg">
                        {qualities?.punctuality || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Handwriting</td>
                      <td className="customTdg">
                        {qualities?.handwriting || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Music</td>
                      <td className="customTdg">{qualities?.homework || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "2ndSemester" &&
        Number(classNumeric) >= 3 &&
        Number(classNumeric) <= 7 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Annual Examination {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">95%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">85%</td>
                          <td className="customTdg">B+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">75%</td>
                          <td className="customTdg">C+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">65%</td>
                          <td className="customTdg">D+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;60%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "50%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh"></th>
                      <th className="customTh" colSpan={5}>
                        {semResult?._id === "2ndSemester"
                          ? "Annual Examination"
                          : "Half Yearly Examination"}
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">SUBJECT</th>
                      <th className="customTh">CLASS TEST</th>
                      <th className="customTh">SBA</th>
                      <th className="customTh">ASSIGNMENT</th>
                      <th className="customTh">TERM</th>
                      <th className="customTh">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map((x, idx) => (
                      <tr key={idx}>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {getShortName(x)}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "classTest"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(semResult?.results || [], x, "sba")
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "assignment"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              semResult?.results || [],
                              x,
                              "term"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {Math.round(
                            Number(
                              getSubjectResult(
                                semResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "assignment"
                                ) +
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "term"
                                )
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="customTd font-weight-bold">Total</td>
                      <td colSpan={4} className="customTd"></td>
                      <td className="customTd font-weight-bold">
                        {Math.round(
                          Number(
                            filteredSubjects
                              ?.map((sub) =>
                                semResult?.results
                                  ?.filter((s) => sub?.label === s?.subject)
                                  ?.map((x) => x?.marks)
                                  ?.flat(Infinity)
                                  ?.reduce((total, mark) => (total += mark), 0)
                              )
                              ?.filter(Boolean)
                              ?.flat(Infinity)
                              ?.reduce(
                                (total, subjMark) =>
                                  (total += Math.round(subjMark)),
                                0
                              ) || 0
                          )
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <table style={{ width: "50%" }} className="customTable table2">
                  <thead>
                    <tr>
                      <th className="customTh" colSpan={5}>
                        Annual Examination
                      </th>
                      <th className="customTh" colSpan={3}></th>
                    </tr>
                    <tr>
                      <th className="customTh">CLASS TEST</th>
                      <th className="customTh">SBA</th>
                      <th className="customTh">ASSIGNMENT</th>
                      <th className="customTh">TERM</th>
                      <th className="customTh">TOTAL</th>
                      <th className="customTh">G.TOTAL</th>
                      <th className="customTh">GRADE</th>
                      <th className="customTh">HIGHEST</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map((x, idx) => (
                      <tr key={idx}>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              sem2Result?.results || [],
                              x,
                              "classTest"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              sem2Result?.results || [],
                              x,
                              "sba"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              sem2Result?.results || [],
                              x,
                              "assignment"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              sem2Result?.results || [],
                              x,
                              "term"
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {Math.round(
                            Number(
                              getSubjectResult(
                                sem2Result?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  sem2Result?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  sem2Result?.results || [],
                                  x,
                                  "assignment"
                                ) +
                                getSubjectResult(
                                  sem2Result?.results || [],
                                  x,
                                  "term"
                                )
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {Math.round(
                            Number(
                              Math.round(
                                getSubjectResult(
                                  semResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    semResult?.results || [],
                                    x,
                                    "sba"
                                  ) +
                                  getSubjectResult(
                                    semResult?.results || [],
                                    x,
                                    "assignment"
                                  ) +
                                  getSubjectResult(
                                    semResult?.results || [],
                                    x,
                                    "term"
                                  )
                              ) +
                                Math.round(
                                  getSubjectResult(
                                    sem2Result?.results || [],
                                    x,
                                    "classTest"
                                  ) +
                                    getSubjectResult(
                                      sem2Result?.results || [],
                                      x,
                                      "sba"
                                    ) +
                                    getSubjectResult(
                                      sem2Result?.results || [],
                                      x,
                                      "assignment"
                                    ) +
                                    getSubjectResult(
                                      sem2Result?.results || [],
                                      x,
                                      "term"
                                    )
                                )
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {getGradeFinal(
                            Math.round(
                              Number(
                                Math.round(
                                  getSubjectResult(
                                    semResult?.results || [],
                                    x,
                                    "classTest"
                                  ) +
                                    getSubjectResult(
                                      semResult?.results || [],
                                      x,
                                      "sba"
                                    ) +
                                    getSubjectResult(
                                      semResult?.results || [],
                                      x,
                                      "assignment"
                                    ) +
                                    getSubjectResult(
                                      semResult?.results || [],
                                      x,
                                      "term"
                                    )
                                ) +
                                  Math.round(
                                    getSubjectResult(
                                      sem2Result?.results || [],
                                      x,
                                      "classTest"
                                    ) +
                                      getSubjectResult(
                                        sem2Result?.results || [],
                                        x,
                                        "sba"
                                      ) +
                                      getSubjectResult(
                                        sem2Result?.results || [],
                                        x,
                                        "assignment"
                                      ) +
                                      getSubjectResult(
                                        sem2Result?.results || [],
                                        x,
                                        "term"
                                      )
                                  )
                              )
                            )
                          )}
                        </td>
                        <td style={{ maxHeight: "10px" }} className="customTd">
                          {Math.round(
                            Number(
                              getHighestMark(sem1HighestMarks || [], x) +
                                getHighestMark(sem2HighestMarks || [], x) || 0
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} className="customTd"></td>
                      <td className="customTd font-weight-bold">
                        {Math.round(
                          Number(
                            filteredSubjects
                              ?.map((sub) =>
                                sem2Result?.results
                                  ?.filter((s) => sub?.label === s?.subject)
                                  ?.map((x) => x?.marks)
                                  ?.flat(Infinity)
                                  ?.reduce((total, mark) => (total += mark), 0)
                              )
                              ?.filter(Boolean)
                              ?.flat(Infinity)
                              ?.reduce(
                                (total, subjMark) =>
                                  (total += Math.round(subjMark)),
                                0
                              ) || 0
                          )
                        )}
                      </td>
                      <td className="customTd font-weight-bold">
                        {Math.round(
                          Number(
                            filteredSubjects
                              ?.map((sub) =>
                                semResult?.results
                                  ?.filter((s) => sub?.label === s?.subject)
                                  ?.map((x) => x?.marks)
                                  ?.flat(Infinity)
                                  ?.reduce((total, mark) => (total += mark), 0)
                              )
                              ?.filter(Boolean)
                              ?.flat(Infinity)
                              ?.reduce(
                                (total, subjMark) =>
                                  (total += Math.round(subjMark)),
                                0
                              ) || 0
                          )
                        ) +
                          Math.round(
                            Number(
                              filteredSubjects
                                ?.map((sub) =>
                                  sem2Result?.results
                                    ?.filter((s) => sub?.label === s?.subject)
                                    ?.map((x) => x?.marks)
                                    ?.flat(Infinity)
                                    ?.reduce(
                                      (total, mark) => (total += mark),
                                      0
                                    )
                                )
                                ?.filter(Boolean)
                                ?.flat(Infinity)
                                ?.reduce(
                                  (total, subjMark) =>
                                    (total += Math.round(subjMark)),
                                  0
                                ) || 0
                            )
                          )}
                      </td>
                      <td className="customTd"></td>
                      <td className="customTd"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }} className="ndPart">
                <u>Evaluation</u>
              </p>
            </div>
            <div>
              <p className="ndPart">Results</p>
            </div>
            <div className="ndPart d-flex justify-content-between p-3">
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <th className="customThg" scope="col">
                        Grade
                      </th>
                      <th className="customThg" scope="col">
                        Meaning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">A+</td>
                      <td className="customTdg">Excellent</td>
                    </tr>
                    <tr>
                      <td className="customTdg">A</td>
                      <td className="customTdg">Good</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B+</td>
                      <td className="customTdg">Satisfactory</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B</td>
                      <td className="customTdg">Needs Improvement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Regularity</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.regularity || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Patriotism</td>
                      <td className="customTdg">
                        {qualities?.patriotism || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Honesty</td>
                      <td className="customTdg">{qualities?.honesty || ""}</td>
                    </tr>
                    <tr>
                      <td className="customTdg">Leadership</td>
                      <td className="customTdg">
                        {qualities?.leadership || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Discipline</td>
                      <td className="customTdg">
                        {qualities?.discipline || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Cooperation</td>
                      <td className="customTdg">
                        {qualities?.cooperation || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Participation</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.participation || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Tolerance</td>
                      <td className="customTdg">
                        {qualities?.tolerance || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Awareness</td>
                      <td className="customTdg">
                        {qualities?.awareness || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Punctuality</td>
                      <td className="customTdg">
                        {qualities?.punctuality || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Handwriting</td>
                      <td className="customTdg">
                        {qualities?.handwriting || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Music</td>
                      <td className="customTdg">{qualities?.homework || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "half_yearly" &&
        Number(classNumeric) >= 8 &&
        Number(classNumeric) <= 9 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Half Yearly Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">95%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">85%</td>
                          <td className="customTdg">B+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">75%</td>
                          <td className="customTdg">C+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">65%</td>
                          <td className="customTdg">D+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;60%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh"></th>
                      <th className="customTh" colSpan={9}>
                        {halfYearlyResult?._id === "half_yearly"
                          ? "Half Yearly Examination"
                          : "Half Yearly Examination"}
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">SUBJECT</th>
                      <th className="customTh">Class Test</th>
                      <th className="customTh">SBA</th>
                      <th className="customTh">Assignment</th>
                      <th className="customTh">Mark (40%)</th>
                      <th className="customTh">Exam</th>
                      <th className="customTh">Mark (60%)</th>
                      <th className="customTh">Total (100%)</th>
                      <th className="customTh">Grade</th>
                      {/*<th className="customTh">Height</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {halfSubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              halfYearlyResult?.results || [],
                              x,
                              "classTest"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              halfYearlyResult?.results || [],
                              x,
                              "sba"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              halfYearlyResult?.results || [],
                              x,
                              "assignment"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {/*converted 40*/}
                          {NaNHandler(
                            Math.round(
                              (((getSubjectResult(
                                halfYearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getHalfYearlyFullMark(x, "classTest") * 2 +
                                  getHalfYearlyFullMark(x, "sba") +
                                  getHalfYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {getSubjectResult(
                            halfYearlyResult?.results || [],
                            x,
                            "term"
                          )}
                        </td>
                        <td className="customTd">
                          {/*Converted 60*/}
                          {NaNHandler(
                            Math.round(
                              ((getSubjectResult(
                                halfYearlyResult?.results || [],
                                x,
                                "term"
                              ) /
                                getHalfYearlyFullMark(x, "term")) *
                                100 *
                                60) /
                                100
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {NaNHandler(
                            Math.round(
                              (((getSubjectResult(
                                halfYearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getHalfYearlyFullMark(x, "classTest") * 2 +
                                  getHalfYearlyFullMark(x, "sba") +
                                  getHalfYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            ) +
                              Math.round(
                                ((getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "term"
                                ) /
                                  getHalfYearlyFullMark(x, "term")) *
                                  100 *
                                  60) /
                                  100
                              )
                          )}
                        </td>
                        <td className="customTd">
                          {getGrade(
                            Math.round(
                              (((getSubjectResult(
                                halfYearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getHalfYearlyFullMark(x, "classTest") * 2 +
                                  getHalfYearlyFullMark(x, "sba") +
                                  getHalfYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            ) +
                              Math.round(
                                ((getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "term"
                                ) /
                                  getHalfYearlyFullMark(x, "term")) *
                                  100 *
                                  60) /
                                  100
                              )
                          )}
                        </td>
                        {/*<td className="customTd">*/}
                        {/*    {getHalfYearlyHighMark(halfYearlyHigh?.results || [], x)}*/}
                        {/*</td>*/}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }} className="ndPart">
                <u>Evaluation</u>
              </p>
            </div>
            <div>
              <p className="ndPart">Results</p>
            </div>
            <div className="ndPart d-flex justify-content-between p-3">
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <th className="customThg" scope="col">
                        Grade
                      </th>
                      <th className="customThg" scope="col">
                        Meaning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">A+</td>
                      <td className="customTdg">Excellent</td>
                    </tr>
                    <tr>
                      <td className="customTdg">A</td>
                      <td className="customTdg">Good</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B+</td>
                      <td className="customTdg">Satisfactory</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B</td>
                      <td className="customTdg">Needs Improvement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Regularity</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.regularity || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Patriotism</td>
                      <td className="customTdg">
                        {qualities?.patriotism || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Honesty</td>
                      <td className="customTdg">{qualities?.honesty || ""}</td>
                    </tr>
                    <tr>
                      <td className="customTdg">Leadership</td>
                      <td className="customTdg">
                        {qualities?.leadership || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Discipline</td>
                      <td className="customTdg">
                        {qualities?.discipline || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Cooperation</td>
                      <td className="customTdg">
                        {qualities?.cooperation || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Participation</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.participation || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Tolerance</td>
                      <td className="customTdg">
                        {qualities?.tolerance || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Awareness</td>
                      <td className="customTdg">
                        {qualities?.awareness || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Punctuality</td>
                      <td className="customTdg">
                        {qualities?.punctuality || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Handwriting</td>
                      <td className="customTdg">
                        {qualities?.handwriting || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Homework</td>
                      <td className="customTdg">{qualities?.homework || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "yearly" &&
        Number(classNumeric) >= 8 &&
        Number(classNumeric) <= 9 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Annual Examination {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">95%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">85%</td>
                          <td className="customTdg">B+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">75%</td>
                          <td className="customTdg">C+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">65%</td>
                          <td className="customTdg">D+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;60%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh"></th>
                      <th className="customTh" colSpan={1}>
                        Half Yearly
                      </th>
                      <th className="customTh" colSpan={9}>
                        {yearlyResult?._id === "half_yearly"
                          ? "Annual Examination"
                          : "Annual Examination"}
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">SUBJECT</th>
                      <th className="customTh">Total (100%)</th>
                      <th className="customTh">Class Test</th>
                      <th className="customTh">SBA</th>
                      <th className="customTh">Assignment</th>
                      <th className="customTh">Mark (40%)</th>
                      <th className="customTh">Exam</th>
                      <th className="customTh">Mark (60%)</th>
                      <th className="customTh">Total (100%)</th>
                      <th className="customTh">G.Total (Average)</th>
                      <th className="customTh">Grade</th>
                      {/*<th className="customTh">Height</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {yearlySubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {NaNHandler(
                            Math.round(
                              (((getSubjectResult(
                                halfYearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getHalfYearlyFullMark(x, "classTest") * 2 +
                                  getHalfYearlyFullMark(x, "sba") +
                                  getHalfYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            ) +
                              Math.round(
                                ((getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "term"
                                ) /
                                  getHalfYearlyFullMark(x, "term")) *
                                  100 *
                                  60) /
                                  100
                              )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              yearlyResult?.results || [],
                              x,
                              "classTest"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              yearlyResult?.results || [],
                              x,
                              "sba"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {JSON.stringify(
                            getSubjectResult(
                              yearlyResult?.results || [],
                              x,
                              "assignment"
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {/*converted 40*/}
                          {NaNHandler(
                            Math.round(
                              (((getSubjectResult(
                                yearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  yearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  yearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getYearlyFullMark(x, "classTest") * 2 +
                                  getYearlyFullMark(x, "sba") +
                                  getYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {getSubjectResult(
                            yearlyResult?.results || [],
                            x,
                            "term"
                          )}
                        </td>
                        <td className="customTd">
                          {/*Converted 60*/}
                          {NaNHandler(
                            Math.round(
                              ((getSubjectResult(
                                yearlyResult?.results || [],
                                x,
                                "term"
                              ) /
                                getYearlyFullMark(x, "term")) *
                                100 *
                                60) /
                                100
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {NaNHandler(
                            Math.round(
                              (((getSubjectResult(
                                yearlyResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  yearlyResult?.results || [],
                                  x,
                                  "sba"
                                ) +
                                getSubjectResult(
                                  yearlyResult?.results || [],
                                  x,
                                  "assignment"
                                )) /
                                (getYearlyFullMark(x, "classTest") * 2 +
                                  getYearlyFullMark(x, "sba") +
                                  getYearlyFullMark(x, "assignment"))) *
                                100 *
                                40) /
                                100
                            ) +
                              Math.round(
                                ((getSubjectResult(
                                  yearlyResult?.results || [],
                                  x,
                                  "term"
                                ) /
                                  getYearlyFullMark(x, "term")) *
                                  100 *
                                  60) /
                                  100
                              )
                          )}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            NaNHandler(
                              (Math.round(
                                (((getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "sba"
                                  ) +
                                  getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "assignment"
                                  )) /
                                  (getHalfYearlyFullMark(x, "classTest") * 2 +
                                    getHalfYearlyFullMark(x, "sba") +
                                    getHalfYearlyFullMark(x, "assignment"))) *
                                  100 *
                                  40) /
                                  100
                              ) +
                                Math.round(
                                  ((getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "term"
                                  ) /
                                    getHalfYearlyFullMark(x, "term")) *
                                    100 *
                                    60) /
                                    100
                                ) +
                                (Math.round(
                                  (((getSubjectResult(
                                    yearlyResult?.results || [],
                                    x,
                                    "classTest"
                                  ) +
                                    getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "sba"
                                    ) +
                                    getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "assignment"
                                    )) /
                                    (getYearlyFullMark(x, "classTest") * 2 +
                                      getYearlyFullMark(x, "sba") +
                                      getYearlyFullMark(x, "assignment"))) *
                                    100 *
                                    40) /
                                    100
                                ) +
                                  Math.round(
                                    ((getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "term"
                                    ) /
                                      getYearlyFullMark(x, "term")) *
                                      100 *
                                      60) /
                                      100
                                  ))) /
                                2
                            )
                          )}
                        </td>
                        <td className="customTd">
                          {getGrade(
                            NaNHandler(
                              (Math.round(
                                (((getSubjectResult(
                                  halfYearlyResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "sba"
                                  ) +
                                  getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "assignment"
                                  )) /
                                  (getHalfYearlyFullMark(x, "classTest") * 2 +
                                    getHalfYearlyFullMark(x, "sba") +
                                    getHalfYearlyFullMark(x, "assignment"))) *
                                  100 *
                                  40) /
                                  100
                              ) +
                                Math.round(
                                  ((getSubjectResult(
                                    halfYearlyResult?.results || [],
                                    x,
                                    "term"
                                  ) /
                                    getHalfYearlyFullMark(x, "term")) *
                                    100 *
                                    60) /
                                    100
                                ) +
                                (Math.round(
                                  (((getSubjectResult(
                                    yearlyResult?.results || [],
                                    x,
                                    "classTest"
                                  ) +
                                    getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "sba"
                                    ) +
                                    getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "assignment"
                                    )) /
                                    (getYearlyFullMark(x, "classTest") * 2 +
                                      getYearlyFullMark(x, "sba") +
                                      getYearlyFullMark(x, "assignment"))) *
                                    100 *
                                    40) /
                                    100
                                ) +
                                  Math.round(
                                    ((getSubjectResult(
                                      yearlyResult?.results || [],
                                      x,
                                      "term"
                                    ) /
                                      getYearlyFullMark(x, "term")) *
                                      100 *
                                      60) /
                                      100
                                  ))) /
                                2
                            )
                          )}
                        </td>
                        {/*<td className="customTd">*/}
                        {/*    {getHalfYearlyHighMark(halfYearlyHigh?.results || [], x)}*/}
                        {/*</td>*/}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }} className="ndPart">
                <u>Evaluation</u>
              </p>
            </div>
            <div>
              <p className="ndPart">Results</p>
            </div>
            <div className="ndPart d-flex justify-content-between p-3">
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <th className="customThg" scope="col">
                        Grade
                      </th>
                      <th className="customThg" scope="col">
                        Meaning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">A+</td>
                      <td className="customTdg">Excellent</td>
                    </tr>
                    <tr>
                      <td className="customTdg">A</td>
                      <td className="customTdg">Good</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B+</td>
                      <td className="customTdg">Satisfactory</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B</td>
                      <td className="customTdg">Needs Improvement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Regularity</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.regularity || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Patriotism</td>
                      <td className="customTdg">
                        {qualities?.patriotism || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Honesty</td>
                      <td className="customTdg">{qualities?.honesty || ""}</td>
                    </tr>
                    <tr>
                      <td className="customTdg">Leadership</td>
                      <td className="customTdg">
                        {qualities?.leadership || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Discipline</td>
                      <td className="customTdg">
                        {qualities?.discipline || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Cooperation</td>
                      <td className="customTdg">
                        {qualities?.cooperation || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Participation</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.participation || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Tolerance</td>
                      <td className="customTdg">
                        {qualities?.tolerance || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Awareness</td>
                      <td className="customTdg">
                        {qualities?.awareness || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Punctuality</td>
                      <td className="customTdg">
                        {qualities?.punctuality || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Handwriting</td>
                      <td className="customTdg">
                        {qualities?.handwriting || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Homework</td>
                      <td className="customTdg">{qualities?.homework || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "pre_qualifying" && Number(classNumeric) === 10 && (
        <div
          style={{ margin: "8px" }}
          className="modal-body p-2 modal-body-custom"
        >
          <div className="text-center">
            <img style={{ width: "80px" }} src={intLogo} alt="logo" />
            <h4>St. Joseph International School</h4>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>REPORT CARD</p>
          </div>
          <div className="customResult">
            <div className="part1">
              <div className="std_info_grading">
                <div className="std_info">
                  <h4>
                    <u>Student's Information</u>
                  </h4>
                  <div>
                    <div>Student's ID</div>
                    <div>: {studentInfo?.regNo}</div>
                  </div>
                  <div>
                    <div>Student's Name</div>
                    <div>
                      : {studentInfo?.firstName} {studentInfo?.lastName}
                    </div>
                  </div>
                  <div>
                    <div>Date of Birth</div>
                    <div>
                      :{" "}
                      {moment(new Date(studentInfo?.dob)).format("DD-MMM-yyyy")}
                    </div>
                  </div>
                  <div>
                    <div>Gender</div>
                    <div>
                      : {studentInfo?.gender === "female" ? "Female" : "Male"}
                    </div>
                  </div>
                  <div>
                    <div>Academic Session</div>
                    <div>: {studentInfo?.session?.sessionName}</div>
                  </div>
                  <div>
                    <div>Roll</div>
                    <div>: {studentInfo?.roll}</div>
                  </div>
                  <div>
                    <div>Standard</div>
                    <div>
                      : {studentInfo?.class?.className} (
                      {studentInfo?.class?.classNumeric})
                    </div>
                  </div>
                  <div>
                    <div>Section</div>
                    <div>: {studentInfo?.section?.sectionName}</div>
                  </div>
                  <div>
                    <div>Name of Examination</div>
                    <div>
                      : Pre-Qualifying Examination{" "}
                      {studentInfo?.session?.sessionName}
                    </div>
                  </div>
                </div>
                <div className="grading">
                  <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th className="customThg" scope="col">
                          Marks
                        </th>
                        <th className="customThg" scope="col">
                          Grade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="customTdg">95%</td>
                        <td className="customTdg">A+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">90%</td>
                        <td className="customTdg">A</td>
                      </tr>
                      <tr>
                        <td className="customTdg">85%</td>
                        <td className="customTdg">B+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">80%</td>
                        <td className="customTdg">B</td>
                      </tr>
                      <tr>
                        <td className="customTdg">75%</td>
                        <td className="customTdg">C+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">70%</td>
                        <td className="customTdg">C</td>
                      </tr>
                      <tr>
                        <td className="customTdg">65%</td>
                        <td className="customTdg">D+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">60%</td>
                        <td className="customTdg">D</td>
                      </tr>
                      <tr>
                        <td className="customTdg">&lt;60%</td>
                        <td className="customTdg">U</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                Result In Details
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <table style={{ width: "100%" }} className="customTable">
                <thead>
                  <tr>
                    <th className="customTh"></th>
                    <th className="customTh" colSpan={5}>
                      {preQualifyingResult?._id === "pre_qualifying" &&
                        "Pre Qualifying Examination"}
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh">SUBJECT</th>
                    <th className="customTh">CLASS TEST (15)</th>
                    <th className="customTh">TERM (85%)</th>
                    <th className="customTh">TOTAL (100)</th>
                    <th className="customTh">GRADE</th>
                  </tr>
                </thead>
                <tbody>
                  {preSubjects?.map((x, idx) => (
                    <tr key={idx}>
                      <td className="customTd">{x}</td>
                      <td className="customTd">
                        {getSubjectResult(
                          preQualifyingResult?.results || [],
                          x,
                          "classTest"
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          ((getSubjectResult(
                            preQualifyingResult?.results || [],
                            x,
                            "term"
                          ) /
                            getPreQualifyingFullMark(x, "term")) *
                            100 *
                            85) /
                            100
                        ) || 0}
                      </td>
                      <td className="customTd">
                        {(getSubjectResult(
                          preQualifyingResult?.results || [],
                          x,
                          "classTest"
                        ) || 0) +
                          Math.round(
                            ((getSubjectResult(
                              preQualifyingResult?.results || [],
                              x,
                              "term"
                            ) /
                              getPreQualifyingFullMark(x, "term")) *
                              100 *
                              85) /
                              100
                          ) || 0}
                      </td>
                      <td className="customTd">
                        {getGrade(
                          Math.round(
                            (getSubjectResult(
                              preQualifyingResult?.results || [],
                              x,
                              "classTest"
                            ) || 0) +
                              ((getSubjectResult(
                                preQualifyingResult?.results || [],
                                x,
                                "term"
                              ) /
                                getPreQualifyingFullMark(x, "term")) *
                                100 *
                                85) /
                                100
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <table style={{ width: "90%" }} className="customTable">
              <thead>
                <tr>
                  <th colSpan={4} className="customTh">
                    Subjects and Paper wise Marks
                  </th>
                </tr>
                <tr>
                  <th className="customTh" scope="col">
                    Subject
                  </th>
                  <th className="customTh" scope="col">
                    Paper 1
                  </th>
                  <th className="customTh" scope="col">
                    Paper 2
                  </th>
                  <th className="customTh" scope="col">
                    Paper 3
                  </th>
                </tr>
              </thead>
              <tbody>
                {alphaSortSubject(
                  paperMarks?.filter(
                    (f) =>
                      f?.examType === "term" && f?.semester === "pre_qualifying"
                  )
                )?.map((p) => (
                  <tr key={p?.subject}>
                    <td className="customTd">{p?.subject}</td>
                    <td className="customTd">{p?.paper1 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Row className="mt-5">
            <Col md="4" sm="4" lg="4" className="text-center">
              <img style={{ width: "90px" }} src={principalSignature} alt="" />
              <p>----------------</p>
              <h5>Principal</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center mt-4">
              <p className="mt-2">----------------</p>
              <h5>Class Teacher</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center">
              <img
                style={{ width: "90px" }}
                src={vicePrincipalSignature}
                alt=""
              />
              <p>----------------</p>
              <h5>Vice-Principal</h5>
            </Col>
          </Row>
        </div>
      )}
      {semester === "qualifying" && Number(classNumeric) === 10 && (
        <div
          style={{ margin: "8px" }}
          className="modal-body p-2 modal-body-custom"
        >
          <div className="text-center">
            <img style={{ width: "80px" }} src={intLogo} alt="logo" />
            <h4>St. Joseph International School</h4>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>REPORT CARD</p>
          </div>
          <div className="customResult">
            <div className="part1">
              <div className="std_info_grading">
                <div className="std_info">
                  <h4>
                    <u>Student's Information</u>
                  </h4>
                  <div>
                    <div>Student's ID</div>
                    <div>: {studentInfo?.regNo}</div>
                  </div>
                  <div>
                    <div>Student's Name</div>
                    <div>
                      : {studentInfo?.firstName} {studentInfo?.lastName}
                    </div>
                  </div>
                  <div>
                    <div>Date of Birth</div>
                    <div>
                      :{" "}
                      {moment(new Date(studentInfo?.dob)).format("DD-MMM-yyyy")}
                    </div>
                  </div>
                  <div>
                    <div>Gender</div>
                    <div>
                      : {studentInfo?.gender === "female" ? "Female" : "Male"}
                    </div>
                  </div>
                  <div>
                    <div>Academic Session</div>
                    <div>: {studentInfo?.session?.sessionName}</div>
                  </div>
                  <div>
                    <div>Roll</div>
                    <div>: {studentInfo?.roll}</div>
                  </div>
                  <div>
                    <div>Standard</div>
                    <div>
                      : {studentInfo?.class?.className} (
                      {studentInfo?.class?.classNumeric})
                    </div>
                  </div>
                  <div>
                    <div>Section</div>
                    <div>: {studentInfo?.section?.sectionName}</div>
                  </div>
                  <div>
                    <div>Name of Examination</div>
                    <div>
                      : Qualifying Examination{" "}
                      {studentInfo?.session?.sessionName}
                    </div>
                  </div>
                </div>
                <div className="grading">
                  <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th className="customThg" scope="col">
                          Marks
                        </th>
                        <th className="customThg" scope="col">
                          Grade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="customTdg">95%</td>
                        <td className="customTdg">A+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">90%</td>
                        <td className="customTdg">A</td>
                      </tr>
                      <tr>
                        <td className="customTdg">85%</td>
                        <td className="customTdg">B+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">80%</td>
                        <td className="customTdg">B</td>
                      </tr>
                      <tr>
                        <td className="customTdg">75%</td>
                        <td className="customTdg">C+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">70%</td>
                        <td className="customTdg">C</td>
                      </tr>
                      <tr>
                        <td className="customTdg">65%</td>
                        <td className="customTdg">D+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">60%</td>
                        <td className="customTdg">D</td>
                      </tr>
                      <tr>
                        <td className="customTdg">&lt;60%</td>
                        <td className="customTdg">U</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                Result In Details
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <table style={{ width: "100%" }} className="customTable">
                <thead>
                  <tr>
                    <th className="customTh"></th>
                    <th className="customTh" colSpan={5}>
                      {qualifyingResult?._id === "qualifying" &&
                        "Qualifying Examination"}
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh">SUBJECT</th>
                    <th className="customTh">Paper 1</th>
                    <th className="customTh">Paper 2</th>
                    <th className="customTh">Paper 3</th>
                    <th className="customTh">Total (100%)</th>
                    <th className="customTh">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {qualifyingSubjects?.map((x, idx) => (
                    <tr key={idx}>
                      <td className="customTd">{x}</td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            qualifyingResult?.results || [],
                            x,
                            "term",
                            3
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            qualifyingResult?.results || [],
                            x,
                            "term",
                            2
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            qualifyingResult?.results || [],
                            x,
                            "term",
                            1
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          (((Math.round(
                            getPaperWiseSubjectResult(
                              qualifyingResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                qualifyingResult?.results || [],
                                x,
                                "term",
                                2
                              )
                            ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                qualifyingResult?.results || [],
                                x,
                                "term",
                                1
                              )
                            )) /
                            getQualifyingFullMark(x, "term")) *
                            100 *
                            100) /
                            100
                        )}
                      </td>
                      <td className="customTd">
                        {getGrade(
                          Math.round(
                            (((Math.round(
                              getPaperWiseSubjectResult(
                                qualifyingResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  qualifyingResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  qualifyingResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )) /
                              getQualifyingFullMark(x, "term")) *
                              100 *
                              100) /
                              100
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <table style={{ width: "90%" }} className="customTable">
              <thead>
                <tr>
                  <th colSpan={4} className="customTh">
                    Subjects and Paper wise Marks
                  </th>
                </tr>
                <tr>
                  <th className="customTh" scope="col">
                    Subject
                  </th>
                  <th className="customTh" scope="col">
                    Paper 1
                  </th>
                  <th className="customTh" scope="col">
                    Paper 2
                  </th>
                  <th className="customTh" scope="col">
                    Paper 3
                  </th>
                </tr>
              </thead>
              <tbody>
                {alphaSortSubject(
                  paperMarks?.filter(
                    (f) =>
                      f?.examType === "term" && f?.semester === "qualifying"
                  )
                )?.map((p) => (
                  <tr key={p?.subject}>
                    <td className="customTd">{p?.subject}</td>
                    <td className="customTd">{p?.paper1 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Row className="mt-5">
            <Col md="4" sm="4" lg="4" className="text-center">
              <img style={{ width: "90px" }} src={principalSignature} />
              <p>----------------</p>
              <h5>Principal</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center mt-4">
              <p className="mt-2">----------------</p>
              <h5>Class Teacher</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center">
              <img style={{ width: "90px" }} src={vicePrincipalSignature} />
              <p>----------------</p>
              <h5>Vice-Principal</h5>
            </Col>
          </Row>
        </div>
      )}
      {semester === "preparation" && Number(classNumeric) === 10 && (
        <div
          style={{ margin: "8px" }}
          className="modal-body p-2 modal-body-custom"
        >
          <div className="text-center">
            <img style={{ width: "80px" }} src={intLogo} alt="logo" />
            <h4>St. Joseph International School</h4>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>REPORT CARD</p>
          </div>
          <div className="customResult">
            <div className="part1">
              <div className="std_info_grading">
                <div className="std_info">
                  <h4>
                    <u>Student's Information</u>
                  </h4>
                  <div>
                    <div>Student's ID</div>
                    <div>: {studentInfo?.regNo}</div>
                  </div>
                  <div>
                    <div>Student's Name</div>
                    <div>
                      : {studentInfo?.firstName} {studentInfo?.lastName}
                    </div>
                  </div>
                  <div>
                    <div>Date of Birth</div>
                    <div>
                      :{" "}
                      {moment(new Date(studentInfo?.dob)).format("DD-MMM-yyyy")}
                    </div>
                  </div>
                  <div>
                    <div>Gender</div>
                    <div>
                      : {studentInfo?.gender === "female" ? "Female" : "Male"}
                    </div>
                  </div>
                  <div>
                    <div>Academic Session</div>
                    <div>: {studentInfo?.session?.sessionName}</div>
                  </div>
                  <div>
                    <div>Roll</div>
                    <div>: {studentInfo?.roll}</div>
                  </div>
                  <div>
                    <div>Standard</div>
                    <div>
                      : {studentInfo?.class?.className} (
                      {studentInfo?.class?.classNumeric})
                    </div>
                  </div>
                  <div>
                    <div>Section</div>
                    <div>: {studentInfo?.section?.sectionName}</div>
                  </div>
                  <div>
                    <div>Name of Examination</div>
                    <div>
                      : O Level Preparation Examination{" "}
                      {studentInfo?.session?.sessionName}
                    </div>
                  </div>
                </div>
                <div className="grading">
                  <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th className="customThg" scope="col">
                          Marks
                        </th>
                        <th className="customThg" scope="col">
                          Grade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="customTdg">95%</td>
                        <td className="customTdg">A+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">90%</td>
                        <td className="customTdg">A</td>
                      </tr>
                      <tr>
                        <td className="customTdg">85%</td>
                        <td className="customTdg">B+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">80%</td>
                        <td className="customTdg">B</td>
                      </tr>
                      <tr>
                        <td className="customTdg">75%</td>
                        <td className="customTdg">C+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">70%</td>
                        <td className="customTdg">C</td>
                      </tr>
                      <tr>
                        <td className="customTdg">65%</td>
                        <td className="customTdg">D+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">60%</td>
                        <td className="customTdg">D</td>
                      </tr>
                      <tr>
                        <td className="customTdg">&lt;60%</td>
                        <td className="customTdg">U</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                Result In Details
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <table style={{ width: "100%" }} className="customTable">
                <thead>
                  <tr>
                    <th className="customTh"></th>
                    <th className="customTh" colSpan={5}>
                      {preparationResult?._id === "preparation"
                        ? "Preparation Examination"
                        : "Preparation Examination"}
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh">SUBJECT</th>
                    <th className="customTh">Paper 1</th>
                    <th className="customTh">Paper 2</th>
                    <th className="customTh">Paper 3</th>
                    <th className="customTh">Total (100%)</th>
                    <th className="customTh">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {preparationSubjects?.map((x, idx) => (
                    <tr key={idx}>
                      <td className="customTd">{x}</td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preparationResult?.results || [],
                            x,
                            "term",
                            3
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preparationResult?.results || [],
                            x,
                            "term",
                            2
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preparationResult?.results || [],
                            x,
                            "term",
                            1
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          (((Math.round(
                            getPaperWiseSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                preparationResult?.results || [],
                                x,
                                "term",
                                2
                              )
                            ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                preparationResult?.results || [],
                                x,
                                "term",
                                1
                              )
                            )) /
                            getPreparationFullMark(x, "term")) *
                            100 *
                            100) /
                            100
                        )}
                      </td>
                      <td className="customTd">
                        {getGrade(
                          Math.round(
                            (((Math.round(
                              getPaperWiseSubjectResult(
                                preparationResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preparationResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preparationResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )) /
                              getPreparationFullMark(x, "term")) *
                              100 *
                              100) /
                              100
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <table style={{ width: "90%" }} className="customTable">
              <thead>
                <tr>
                  <th colSpan={4} className="customTh">
                    Subjects and Paper wise Marks
                  </th>
                </tr>
                <tr>
                  <th className="customTh" scope="col">
                    Subject
                  </th>
                  <th className="customTh" scope="col">
                    Paper 1
                  </th>
                  <th className="customTh" scope="col">
                    Paper 2
                  </th>
                  <th className="customTh" scope="col">
                    Paper 3
                  </th>
                </tr>
              </thead>
              <tbody>
                {alphaSortSubject(
                  paperMarks?.filter(
                    (f) =>
                      f?.examType === "term" && f?.semester === "preparation"
                  )
                )?.map((p) => (
                  <tr key={p?.subject}>
                    <td className="customTd">{p?.subject}</td>
                    <td className="customTd">{p?.paper1 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Row className="mt-5">
            <Col md="4" sm="4" lg="4" className="text-center">
              <img style={{ width: "90px" }} src={principalSignature} alt="" />
              <p>----------------</p>
              <h5>Principal</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center mt-4">
              <p className="mt-2">----------------</p>
              <h5>Class Teacher</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center">
              <img
                style={{ width: "90px" }}
                src={vicePrincipalSignature}
                alt=""
              />
              <p>----------------</p>
              <h5>Vice-Principal</h5>
            </Col>
          </Row>
        </div>
      )}
      {semester === "pre_cambridge" && Number(classNumeric) === 10 && (
        <div
          style={{ margin: "8px" }}
          className="modal-body p-2 modal-body-custom"
        >
          <div className="text-center">
            <img style={{ width: "80px" }} src={intLogo} alt="logo" />
            <h4>St. Joseph International School</h4>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>REPORT CARD</p>
          </div>
          <div className="customResult">
            <div className="part1">
              <div className="std_info_grading">
                <div className="std_info">
                  <h4>
                    <u>Student's Information</u>
                  </h4>
                  <div>
                    <div>Student's ID</div>
                    <div>: {studentInfo?.regNo}</div>
                  </div>
                  <div>
                    <div>Student's Name</div>
                    <div>
                      : {studentInfo?.firstName} {studentInfo?.lastName}
                    </div>
                  </div>
                  <div>
                    <div>Date of Birth</div>
                    <div>
                      :{" "}
                      {moment(new Date(studentInfo?.dob)).format("DD-MMM-yyyy")}
                    </div>
                  </div>
                  <div>
                    <div>Gender</div>
                    <div>
                      : {studentInfo?.gender === "female" ? "Female" : "Male"}
                    </div>
                  </div>
                  <div>
                    <div>Academic Session</div>
                    <div>: {studentInfo?.session?.sessionName}</div>
                  </div>
                  <div>
                    <div>Roll</div>
                    <div>: {studentInfo?.roll}</div>
                  </div>
                  <div>
                    <div>Standard</div>
                    <div>
                      : {studentInfo?.class?.className} (
                      {studentInfo?.class?.classNumeric})
                    </div>
                  </div>
                  <div>
                    <div>Section</div>
                    <div>: {studentInfo?.section?.sectionName}</div>
                  </div>
                  <div>
                    <div>Name of Examination</div>
                    <div>
                      : Pre-Cambridge Examination{" "}
                      {studentInfo?.session?.sessionName}
                    </div>
                  </div>
                </div>
                <div className="grading">
                  <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                  <table className="customTable">
                    <thead>
                      <tr>
                        <th className="customThg" scope="col">
                          Marks
                        </th>
                        <th className="customThg" scope="col">
                          Grade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="customTdg">95%</td>
                        <td className="customTdg">A+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">90%</td>
                        <td className="customTdg">A</td>
                      </tr>
                      <tr>
                        <td className="customTdg">85%</td>
                        <td className="customTdg">B+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">80%</td>
                        <td className="customTdg">B</td>
                      </tr>
                      <tr>
                        <td className="customTdg">75%</td>
                        <td className="customTdg">C+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">70%</td>
                        <td className="customTdg">C</td>
                      </tr>
                      <tr>
                        <td className="customTdg">65%</td>
                        <td className="customTdg">D+</td>
                      </tr>
                      <tr>
                        <td className="customTdg">60%</td>
                        <td className="customTdg">D</td>
                      </tr>
                      <tr>
                        <td className="customTdg">&lt;60%</td>
                        <td className="customTdg">U</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                Result In Details
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <table style={{ width: "100%" }} className="customTable">
                <thead>
                  <tr>
                    <th className="customTh"></th>
                    <th className="customTh" colSpan={5}>
                      {preCambridgeResult?._id === "pre_cambridge"
                        ? "Pre-Cambridge Examination"
                        : "Pre-Cambridge Examination"}
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh">SUBJECT</th>
                    <th className="customTh">Paper 1</th>
                    <th className="customTh">Paper 2</th>
                    <th className="customTh">Paper 3</th>
                    <th className="customTh">Total (100%)</th>
                    <th className="customTh">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {preCambridgeSubjects?.map((x, idx) => (
                    <tr key={idx}>
                      <td className="customTd">{x}</td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preCambridgeResult?.results || [],
                            x,
                            "term",
                            3
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preCambridgeResult?.results || [],
                            x,
                            "term",
                            2
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          getPaperWiseSubjectResult(
                            preCambridgeResult?.results || [],
                            x,
                            "term",
                            1
                          )
                        ) || ""}
                      </td>
                      <td className="customTd">
                        {Math.round(
                          (((Math.round(
                            getPaperWiseSubjectResult(
                              preCambridgeResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                preCambridgeResult?.results || [],
                                x,
                                "term",
                                2
                              )
                            ) +
                            Math.round(
                              getPaperWiseSubjectResult(
                                preCambridgeResult?.results || [],
                                x,
                                "term",
                                1
                              )
                            )) /
                            getPreCambridgeFullMark(x, "term")) *
                            100 *
                            100) /
                            100
                        )}
                      </td>
                      <td className="customTd">
                        {getGrade(
                          Math.round(
                            (((Math.round(
                              getPaperWiseSubjectResult(
                                preCambridgeResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preCambridgeResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preCambridgeResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )) /
                              getPreCambridgeFullMark(x, "term")) *
                              100 *
                              100) /
                              100
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <table style={{ width: "90%" }} className="customTable">
              <thead>
                <tr>
                  <th colSpan={4} className="customTh">
                    Subjects and Paper wise Marks
                  </th>
                </tr>
                <tr>
                  <th className="customTh" scope="col">
                    Subject
                  </th>
                  <th className="customTh" scope="col">
                    Paper 1
                  </th>
                  <th className="customTh" scope="col">
                    Paper 2
                  </th>
                  <th className="customTh" scope="col">
                    Paper 3
                  </th>
                </tr>
              </thead>
              <tbody>
                {alphaSortSubject(
                  paperMarks?.filter(
                    (f) =>
                      f?.examType === "term" && f?.semester === "pre_cambridge"
                  )
                )?.map((p) => (
                  <tr key={p?.subject}>
                    <td className="customTd">{p?.subject}</td>
                    <td className="customTd">{p?.paper1 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                    <td className="customTd">{p?.paper2 || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Row className="mt-5">
            <Col md="4" sm="4" lg="4" className="text-center">
              <img style={{ width: "90px" }} src={principalSignature} alt="" />
              <p>----------------</p>
              <h5>Principal</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center mt-4">
              <p className="mt-2">----------------</p>
              <h5>Class Teacher</h5>
            </Col>
            <Col md="4" sm="4" lg="4" className="text-center">
              <img
                style={{ width: "90px" }}
                src={vicePrincipalSignature}
                alt=""
              />
              <p>----------------</p>
              <h5>Vice-Principal</h5>
            </Col>
          </Row>
        </div>
      )}
      {semester === "interim" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body p-2 modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Interim Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">50%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;50%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh">Subject</th>
                      <th className="customTh">CT 1 (20)</th>
                      <th className="customTh">CT 2 (20)</th>
                      <th className="customTh">CP (10)</th>
                      <th className="customTh">Total (50)</th>
                      <th className="customTh">Attendance (%)</th>
                      <th className="customTh">Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {interimSubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {getSubjectResult(
                            interimResult?.results || [],
                            x,
                            "classTest",
                            0
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getSubjectResult(
                            interimResult?.results || [],
                            x,
                            "classTest",
                            1
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getSubjectResult(
                            interimResult?.results || [],
                            x,
                            "cp"
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            calculateProportionalValue(
                              getSubjectResult(
                                interimResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  interimResult?.results || [],
                                  x,
                                  "cp"
                                ),
                              getInterimFullMark(),
                              50
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getSubjectResult(
                              interimResult?.results || [],
                              x,
                              "studentQuality"
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getGrade_11_12(
                            Math.round(
                              calculateProportionalValue(
                                getSubjectResult(
                                  interimResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    interimResult?.results || [],
                                    x,
                                    "cp"
                                  ),
                                getInterimFullMark(),
                                100
                              )
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Row className="mt-4">
              <Col md="12" className="pt-4">
                <h4 className="mt-4">Teacher's Comments</h4>
                <p className="mb-0 pb-0">
                  (Teacher's comments on student's performance, behavior, and
                  improvement areas.)
                </p>
              </Col>
              <Col md="12" className="my-4">
                ----------------
              </Col>
              <Col md="12" className="mb-4">
                <h4 className="mb-0 pb-0">Signatures,</h4>
              </Col>
              <Col md="12" className="mb-4 d-flex align-items-end">
                <h4 className="mb-0 pb-0">Teacher's Signature: </h4>
                <span
                  style={{ width: "200px" }}
                  className="d-block mb-0 mx-2 pb-0 border-bottom border-dark"
                />
              </Col>
              <Col md="12" className="d-flex align-items-end">
                <h4 className="mb-0 pb-0">Parent's/Guardian's Signature: </h4>
                <span
                  style={{ width: "200px" }}
                  className="d-block mb-0 mx-2 pb-0 border-bottom border-dark"
                />
              </Col>
            </Row>
          </div>
        )}
      {semester === "qualifying" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body p-2 modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Qualifying Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">50%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;50%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh" rowSpan={2}>
                        Subject
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Interim (40%)
                      </th>
                      <th className="customTh" colSpan={5}>
                        Hall Examination
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Total (100%)
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Grade
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">Paper 1</th>
                      <th className="customTh">Paper 2</th>
                      <th className="customTh">Paper 3</th>
                      <th className="customTh">Total</th>
                      <th className="customTh">(60%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualifyingSubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {Math.round(
                            calculateProportionalValue(
                              getSubjectResult(
                                interimResult?.results || [],
                                x,
                                "classTest"
                              ) +
                                getSubjectResult(
                                  interimResult?.results || [],
                                  x,
                                  "cp"
                                ),
                              getInterimFullMark(),
                              40
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              qualifyingResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              qualifyingResult?.results || [],
                              x,
                              "term",
                              2
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              qualifyingResult?.results || [],
                              x,
                              "term",
                              1
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Math.round(
                              getPaperWiseSubjectResult(
                                qualifyingResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  qualifyingResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  qualifyingResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            calculateProportionalValue(
                              Math.round(
                                Math.round(
                                  getPaperWiseSubjectResult(
                                    qualifyingResult?.results || [],
                                    x,
                                    "term",
                                    3
                                  )
                                ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      qualifyingResult?.results || [],
                                      x,
                                      "term",
                                      2
                                    )
                                  ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      qualifyingResult?.results || [],
                                      x,
                                      "term",
                                      1
                                    )
                                  )
                              ),
                              getQualifyingFullMark(x, "term"),
                              60
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Math.round(
                              calculateProportionalValue(
                                getSubjectResult(
                                  interimResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    interimResult?.results || [],
                                    x,
                                    "cp"
                                  ),
                                getInterimFullMark(),
                                40
                              )
                            ) +
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        qualifyingResult?.results || [],
                                        x,
                                        "term",
                                        3
                                      )
                                    ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          qualifyingResult?.results || [],
                                          x,
                                          "term",
                                          2
                                        )
                                      ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          qualifyingResult?.results || [],
                                          x,
                                          "term",
                                          1
                                        )
                                      )
                                  ),
                                  getQualifyingFullMark(x, "term"),
                                  60
                                )
                              )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getGrade_11_12(
                            Math.round(
                              calculateProportionalValue(
                                getSubjectResult(
                                  interimResult?.results || [],
                                  x,
                                  "classTest"
                                ) +
                                  getSubjectResult(
                                    interimResult?.results || [],
                                    x,
                                    "cp"
                                  ),
                                getInterimFullMark(),
                                40
                              )
                            ) +
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        qualifyingResult?.results || [],
                                        x,
                                        "term",
                                        3
                                      )
                                    ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          qualifyingResult?.results || [],
                                          x,
                                          "term",
                                          2
                                        )
                                      ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          qualifyingResult?.results || [],
                                          x,
                                          "term",
                                          1
                                        )
                                      )
                                  ),
                                  getQualifyingFullMark(x, "term"),
                                  60
                                )
                              )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <table style={{ width: "90%" }} className="customTable">
                <thead>
                  <tr>
                    <th colSpan={4} className="customTh">
                      Subjects and Paper wise Marks
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh" scope="col">
                      Subject
                    </th>
                    <th className="customTh" scope="col">
                      Paper 1
                    </th>
                    <th className="customTh" scope="col">
                      Paper 2
                    </th>
                    <th className="customTh" scope="col">
                      Paper 3
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {alphaSortSubject(
                    paperMarks?.filter(
                      (f) =>
                        f?.examType === "term" && f?.semester === "qualifying"
                    )
                  )?.map((p) => (
                    <tr key={p?.subject}>
                      <td className="customTd">{p?.subject}</td>
                      <td className="customTd">{p?.paper1 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img style={{ width: "90px" }} src={principalSignature} />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img style={{ width: "90px" }} src={vicePrincipalSignature} />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "preparation" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body p-2 modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Preparation Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">50%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;50%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh" rowSpan={2}>
                        Subject
                      </th>
                      <th className="customTh" colSpan={2}>
                        20
                      </th>
                      <th className="customTh" colSpan={5}>
                        {preparationResult?._id === "preparation"
                          ? "Preparation Examination"
                          : "Preparation Examination"}
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Total (100%)
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Grade
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">PBL</th>
                      <th className="customTh">CP</th>
                      <th className="customTh">Paper 1</th>
                      <th className="customTh">Paper 2</th>
                      <th className="customTh">Paper 3</th>
                      <th className="customTh">Total</th>
                      <th className="customTh">(80%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preparationSubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {Math.round(
                            getSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "pbc"
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "cp"
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "term",
                              2
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preparationResult?.results || [],
                              x,
                              "term",
                              1
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Math.round(
                              getPaperWiseSubjectResult(
                                preparationResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preparationResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preparationResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            calculateProportionalValue(
                              Math.round(
                                Math.round(
                                  getPaperWiseSubjectResult(
                                    preparationResult?.results || [],
                                    x,
                                    "term",
                                    3
                                  )
                                ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      preparationResult?.results || [],
                                      x,
                                      "term",
                                      2
                                    )
                                  ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      preparationResult?.results || [],
                                      x,
                                      "term",
                                      1
                                    )
                                  )
                              ),
                              getPreparationFullMark(x, "term"),
                              80
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Math.round(
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    getSubjectResult(
                                      preparationResult?.results || [],
                                      x,
                                      "pbc"
                                    )
                                  ) +
                                    Math.round(
                                      getSubjectResult(
                                        preparationResult?.results || [],
                                        x,
                                        "cp"
                                      )
                                    ),
                                  20,
                                  20
                                )
                              )
                            ) +
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        preparationResult?.results || [],
                                        x,
                                        "term",
                                        3
                                      )
                                    ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          preparationResult?.results || [],
                                          x,
                                          "term",
                                          2
                                        )
                                      ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          preparationResult?.results || [],
                                          x,
                                          "term",
                                          1
                                        )
                                      )
                                  ),
                                  getPreparationFullMark(x, "term"),
                                  80
                                )
                              )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getGrade_11_12(
                            Math.round(
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    getSubjectResult(
                                      preparationResult?.results || [],
                                      x,
                                      "pbc"
                                    )
                                  ) +
                                    Math.round(
                                      getSubjectResult(
                                        preparationResult?.results || [],
                                        x,
                                        "cp"
                                      )
                                    ),
                                  20,
                                  20
                                )
                              )
                            ) +
                              Math.round(
                                calculateProportionalValue(
                                  Math.round(
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        preparationResult?.results || [],
                                        x,
                                        "term",
                                        3
                                      )
                                    ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          preparationResult?.results || [],
                                          x,
                                          "term",
                                          2
                                        )
                                      ) +
                                      Math.round(
                                        getPaperWiseSubjectResult(
                                          preparationResult?.results || [],
                                          x,
                                          "term",
                                          1
                                        )
                                      )
                                  ),
                                  getPreparationFullMark(x, "term"),
                                  80
                                )
                              )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <table style={{ width: "90%" }} className="customTable">
                <thead>
                  <tr>
                    <th colSpan={4} className="customTh">
                      Subjects and Paper wise Marks
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh" scope="col">
                      Subject
                    </th>
                    <th className="customTh" scope="col">
                      Paper 1
                    </th>
                    <th className="customTh" scope="col">
                      Paper 2
                    </th>
                    <th className="customTh" scope="col">
                      Paper 3
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {alphaSortSubject(
                    paperMarks?.filter(
                      (f) =>
                        f?.examType === "term" && f?.semester === "preparation"
                    )
                  )?.map((p) => (
                    <tr key={p?.subject}>
                      <td className="customTd">{p?.subject}</td>
                      <td className="customTd">{p?.paper1 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
      {semester === "pre_cambridge" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <div
            style={{ margin: "8px" }}
            className="modal-body p-2 modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                REPORT CARD
              </p>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : Pre-Cambridge Examination{" "}
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div className="grading">
                    <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
                    <table className="customTable">
                      <thead>
                        <tr>
                          <th className="customThg" scope="col">
                            Marks
                          </th>
                          <th className="customThg" scope="col">
                            Grade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="customTdg">90%</td>
                          <td className="customTdg">A+</td>
                        </tr>
                        <tr>
                          <td className="customTdg">80%</td>
                          <td className="customTdg">A</td>
                        </tr>
                        <tr>
                          <td className="customTdg">70%</td>
                          <td className="customTdg">B</td>
                        </tr>
                        <tr>
                          <td className="customTdg">60%</td>
                          <td className="customTdg">C</td>
                        </tr>
                        <tr>
                          <td className="customTdg">50%</td>
                          <td className="customTdg">D</td>
                        </tr>
                        <tr>
                          <td className="customTdg">&lt;50%</td>
                          <td className="customTdg">U</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
                  Result In Details
                </h4>
              </div>
              <div style={{ display: "flex" }}>
                <table style={{ width: "100%" }} className="customTable">
                  <thead>
                    <tr>
                      <th className="customTh" rowSpan={2}>
                        Subject
                      </th>
                      <th className="customTh" colSpan={4}>
                        Hall Examination
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Total (100%)
                      </th>
                      <th className="customTh" rowSpan={2}>
                        Grade
                      </th>
                    </tr>
                    <tr>
                      <th className="customTh">Paper 1</th>
                      <th className="customTh">Paper 2</th>
                      <th className="customTh">Paper 3</th>
                      <th className="customTh">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preCambridgeSubjects?.map((x, idx) => (
                      <tr key={idx}>
                        <td className="customTd">{x}</td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preCambridgeResult?.results || [],
                              x,
                              "term",
                              3
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preCambridgeResult?.results || [],
                              x,
                              "term",
                              2
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            getPaperWiseSubjectResult(
                              preCambridgeResult?.results || [],
                              x,
                              "term",
                              1
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            Math.round(
                              getPaperWiseSubjectResult(
                                preCambridgeResult?.results || [],
                                x,
                                "term",
                                3
                              )
                            ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preCambridgeResult?.results || [],
                                  x,
                                  "term",
                                  2
                                )
                              ) +
                              Math.round(
                                getPaperWiseSubjectResult(
                                  preCambridgeResult?.results || [],
                                  x,
                                  "term",
                                  1
                                )
                              )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {Math.round(
                            calculateProportionalValue(
                              Math.round(
                                Math.round(
                                  getPaperWiseSubjectResult(
                                    preCambridgeResult?.results || [],
                                    x,
                                    "term",
                                    3
                                  )
                                ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      preCambridgeResult?.results || [],
                                      x,
                                      "term",
                                      2
                                    )
                                  ) +
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      preCambridgeResult?.results || [],
                                      x,
                                      "term",
                                      1
                                    )
                                  )
                              ),
                              getPreCambridgeFullMark(x, "term"),
                              100
                            )
                          ) || ""}
                        </td>
                        <td className="customTd">
                          {getGrade_11_12(
                            Math.round(
                              calculateProportionalValue(
                                Math.round(
                                  Math.round(
                                    getPaperWiseSubjectResult(
                                      preCambridgeResult?.results || [],
                                      x,
                                      "term",
                                      3
                                    )
                                  ) +
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        preCambridgeResult?.results || [],
                                        x,
                                        "term",
                                        2
                                      )
                                    ) +
                                    Math.round(
                                      getPaperWiseSubjectResult(
                                        preCambridgeResult?.results || [],
                                        x,
                                        "term",
                                        1
                                      )
                                    )
                                ),
                                getPreCambridgeFullMark(x, "term"),
                                100
                              )
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <table style={{ width: "90%" }} className="customTable">
                <thead>
                  <tr>
                    <th colSpan={4} className="customTh">
                      Subjects and Paper wise Marks
                    </th>
                  </tr>
                  <tr>
                    <th className="customTh" scope="col">
                      Subject
                    </th>
                    <th className="customTh" scope="col">
                      Paper 1
                    </th>
                    <th className="customTh" scope="col">
                      Paper 2
                    </th>
                    <th className="customTh" scope="col">
                      Paper 3
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {alphaSortSubject(
                    paperMarks?.filter(
                      (f) =>
                        f?.examType === "term" &&
                        f?.semester === "pre_cambridge"
                    )
                  )?.map((p) => (
                    <tr key={p?.subject}>
                      <td className="customTd">{p?.subject}</td>
                      <td className="customTd">{p?.paper1 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                      <td className="customTd">{p?.paper2 || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Row className="mt-5">
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={principalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Principal</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center mt-4">
                <p className="mt-2">----------------</p>
                <h5>Class Teacher</h5>
              </Col>
              <Col md="4" sm="4" lg="4" className="text-center">
                <img
                  style={{ width: "90px" }}
                  src={vicePrincipalSignature}
                  alt=""
                />
                <p>----------------</p>
                <h5>Vice-Principal</h5>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

export default SingleSheet;
