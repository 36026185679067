import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "../../../constants/jsons/defaultTableHelper.json";
import NotifyContext from "../../../context/NotifyContext";
import { getTokenFinal } from "../../../firebase";
import { getAllBranch } from "../../../network/branch/loadBranch";
import { manageUserLogin } from "../../../network/login/apiManageLogin";
import { HideLoader, ShowLoader } from "../../../redux/loaderSlice";
import store from "../../../redux/store";

function Login(props) {
  const [allBranch, setAllBranch] = React.useState([]);
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [focusedEmail, setFocusedEmail] = React.useState(false);
  const [focusedPassword, setFocusedPassword] = React.useState(false);
  const [userType, setUserType] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [passShow, setPassShow] = React.useState(false);
  const { Notify } = React.useContext(NotifyContext);
  const [isTokenFound, setTokenFound] = React.useState(false);
  const [currentToken, setCurrentToken] = React.useState("");
  getTokenFinal(setTokenFound, setCurrentToken);
  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      await setAllBranch(data?.data);
    }

    loadBranch();
  }, []);

  const manageLogin = async () => {
    await store.dispatch(ShowLoader());
    const user = await manageUserLogin(
      branchName,
      branchId,
      userType,
      userId,
      userPassword,
      true,
      currentToken
    );
    if (user.status === 400) {
      await store.dispatch(HideLoader());
      Notify("danger", user?.data?.message, "Login");
    }
    if ((user.status === 200 || user.status === 201) && user?.data?._id) {
      await localStorage.setItem("user", JSON.stringify(user?.data));
      await localStorage.setItem(
        "stj_v",
        JSON.stringify(
          "0d69b50094e21b4a5a4c0ca2bfd86a0b86603a8dbcf649376f11aae1ab1ba43a"
        )
      );
      props.history.push("/admin/dashboard");
      await store.dispatch(HideLoader());
      Notify("success", "Successfully Login", "Login");
    } else {
      await store.dispatch(HideLoader());
      Notify("danger", "No User Found", "Login");
    }
  };

  const handleButtonLogin = (e) => {
    if (e.charCode === 13) {
      manageLogin();
    }
  };

  return (
    <>
      <AuthHeader
        title="Welcome to St Joseph Portal"
        lead="Use Your Organizational ID And Password To Login"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardTitle className="text-center mt-3">
                <img
                  alt="..."
                  src={require("assets/img/logo.png").default}
                  width="100px"
                />
              </CardTitle>
              <CardBody>
                <div className="d-flex justify-content-around">
                  <div className="custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="customRadio1"
                      name="user"
                      type="radio"
                      value="student"
                      onChange={(e) => setUserType(e.target.value)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                    >
                      Student
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id="customRadio2"
                      name="user"
                      type="radio"
                      value="admin"
                      onChange={(e) => setUserType(e.target.value)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      Academic
                    </label>
                  </div>
                </div>
                {userType === "student" && (
                  <div className="mt-4 mb-4">
                    <FormGroup>
                      <label
                        className="form-control-label ml-1"
                        htmlFor="example4cols1Input"
                        style={{ color: "purple" }}
                      >
                        Select Branch
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        onChange={(e) => {
                          const values = JSON.parse(e.target.value);
                          setBranchName(values?.name);
                          setBranchId(values?.id);
                        }}
                        id="exampleFormControlSelect1"
                        type="select"
                      >
                        <option
                          selected={
                            branchName === "" && branchId === "" && true
                          }
                        >
                          Select
                        </option>
                        {allBranch?.map((branch) => (
                          <option
                            value={JSON.stringify({
                              name: branch?.branchName,
                              id: branch?._id,
                            })}
                          >
                            {branch?.branchName}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                )}
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={
                          userType === "student" ? "Register Number" : "User Id"
                        }
                        type="email"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(true)}
                        onChange={(e) => setUserId(e.target.value)}
                        onKeyPress={handleButtonLogin}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type={passShow ? "text" : "password"}
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(true)}
                        onChange={(e) => setUserPassword(e.target.value)}
                        onKeyPress={handleButtonLogin}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {passShow === true ? (
                            <FaEye
                              color="red"
                              onClick={() => setPassShow(!passShow)}
                            />
                          ) : (
                            <FaEyeSlash
                              color="green"
                              onClick={() => setPassShow(!passShow)}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      onClick={manageLogin}
                      className="my-4"
                      color="info"
                      type="button"
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
