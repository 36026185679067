import baseApi from "../../api/baseApi";

export const addExamList = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  semester,
  examType,
  name,
  remarks,
  user
) => {
  const payload = {
    branchName: branchName,
    branchId: branchId,
    sessionName: sessionName,
    sessionId: sessionId,
    semester: semester,
    examType: examType,
    name: name,
    remarks: remarks,
    createdBy: user,
  };
  try {
    const response = await baseApi.post(`/api/addExamList`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getAllExamList = async (
  page,
  showPerPage,
  searchTerm,
  branchName,
  branchId,
  sessionName,
  sessionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getExamList?page=${page}&limit=${showPerPage}&search=${searchTerm}&branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getOneExamList = async (id, branchName, sessionName) => {
  try {
    const response = await baseApi.get(
      `/api/getOneExamList/${id}?branchName=${branchName}&sessionName=${sessionName}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateOneExamList = async (
  id,
  branchName,
  branchId,
  sessionName,
  sessionId,
  semester,
  examType,
  name,
  remarks,
  user
) => {
  const payload = {
    branchName,
    branchId,
    sessionName,
    sessionId,
    semester,
    examType,
    name,
    remarks,
    user,
  };
  try {
    const response = await baseApi.put(
      `/api/updateOneExamList/${id}`,
      payload,
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const deleteOneExamList = async (id, branchName, sessionName) => {
  try {
    const response = await baseApi.delete(
      `/api/deleteOneExamList/${id}?branchName=${branchName}&sessionName=${sessionName}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return { deleteStatus: response?.data?.message };
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchSessionWiseExamList = async (
  branchName,
  branchId,
  sessionName,
  sessionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchSessionWiseExamList?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchSessionClassWiseExamListFromExamSchedule = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchSessionClassWiseExamListFromExamSchedule?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data[0];
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getSemesterWiseExams = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  semester
) => {
  try {
    const response = await baseApi.get(
      `/api/getSemesterWiseExams?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&semester=${semester}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};
