import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import NotifyContext from "context/NotifyContext";
import { getBranchSessionWiseExamList } from "network/apiExamList/apiExamList";
import { updateOneMarks } from "network/apiMarkEntries/apiMarkEntries";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  branchWiseSubject,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import React, { useState } from "react";
import {
  Button,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";

const EditMarks = ({ data, examSchedule }) => {
  const { Notify } = React.useContext(NotifyContext);

  console.log(data);

  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");
  const [examList, setExamList] = React.useState([]);

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [editedMarks, setEditedMarks] = React.useState([]);

  React.useEffect(() => {
    const data = rowsData?.map((x) => {
      return {
        studentId: x?.studentId,
        ...(x?.written && { written: x?.written }),
        ...(x?.practical && { practical: x?.practical }),
        ...(x?.attendance && { attendance: x?.attendance }),
        ...(x?.viva && { viva: x?.viva }),
        ...(x?.presentation && { presentation: x?.presentation }),
        ...(x?.paper1 && { paper1: x?.paper1 }),
        ...(x?.paper2 && { paper2: x?.paper2 }),
        ...(x?.paper3 && { paper3: x?.paper3 }),
      };
    });
    setEditedMarks(data);
  }, [rowsData]);

  React.useEffect(() => {
    if (data?.branch?._id) {
      setBranchName(data?.branch?.branchName);
      setBranchId(data?.branch?._id);
    }
    if (data?.session?._id) {
      setSessionName(data?.session?.sessionName);
      setSessionId(data?.session?._id);
    }
    if (data?.class?._id) {
      setClassName(data?.class?.branchName);
      setClassId(data?.class?._id);
    }
    if (data?.section?._id) {
      setSectionName(data?.section?.sectionName);
      setSectionId(data?.section?._id);
    }
    if (data?.marks) {
      setStudents(data?.marks);
    }
  }, [data]);

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const fullMark =
      Number(examSchedule[name + "FullMark"]) ||
      Number(students[index][name + "FullMark"]);
    const rowsInput = [...students];

    if (fullMark < Number(value)) {
      Notify("warning", "Invalidated mark", "Marks");
      evnt.target.value = fullMark;
    } else {
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }
  };

  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      setAllBranch(data?.data);
    }

    loadBranch();
  }, []);

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(branchId);
      setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);
      setAllClass(data?.data);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  React.useEffect(() => {
    async function branchSessionToExam() {
      const data = await getBranchSessionWiseExamList(
        branchName,
        branchId,
        sessionName,
        sessionId
      );
      setExamList(data);
    }

    if (branchId && sessionId) {
      branchSessionToExam();
    }
  }, [sessionId]);

  React.useEffect(() => {
    async function branchToSubject() {
      const data = await branchWiseSubject(
        branchName,
        branchId,
        sessionName,
        sessionId
      );
      setAllSubject(data?.data);
    }

    if (branchId && sessionId) {
      branchToSubject();
    }
  }, [branchId, sessionId]);

  const handleUpdate = async () => {
    store.dispatch(ShowLoader());
    const response = await updateOneMarks(
      data?.branch?.branchName,
      data?.branch?._id,
      data?.session?.sessionName,
      data?.session?._id,
      data?.class?._id,
      data?.section?._id,
      data?.exam?._id,
      data?.subject?._id,
      editedMarks?.length > 0 && editedMarks
    );
    store.dispatch(HideLoader());
    Notify("success", response, "Mark");
  };
  return (
    <div>
      {data && examSchedule && (
        <div>
          <section>
            <CardTitle>
              <h3 className="mb-0">
                <i className="ni ni-active-40 text-orange" />
                <span
                  className="ml-2"
                  style={{ fontSize: "large", color: "orange" }}
                >
                  Select Ground
                </span>
                <hr />
              </h3>
            </CardTitle>
            <Row>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Branch
                  </label>
                  <Input
                    disabled={true}
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setBranchName(values?.name);
                      setBranchId(values?.id);
                    }}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {allBranch?.map((branch) => (
                      <option
                        selected={data?.branch?._id === branch?._id && true}
                        value={JSON.stringify({
                          name: branch?.branchName,
                          id: branch?._id,
                        })}
                      >
                        {branch?.branchName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Session
                  </label>
                  <Input
                    disabled={true}
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setSessionName(values?.name);
                      setSessionId(values?.id);
                    }}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {allSession?.map((session) => (
                      <option
                        selected={data?.session?._id === session?._id && true}
                        value={JSON.stringify({
                          name: session?.sessionName,
                          id: session?._id,
                        })}
                      >
                        {session?.sessionName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Class
                  </label>
                  <Input
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setClassName(values?.name);
                      setClassId(values?.id);
                    }}
                    disabled={true}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {allClass?.map((cls) => (
                      <option
                        selected={data?.class?._id === cls?._id && true}
                        value={JSON.stringify({
                          name: cls?.className,
                          id: cls?._id,
                        })}
                      >
                        {cls?.className}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Section
                  </label>
                  <Input
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setSectionName(values?.name);
                      setSectionId(values?.id);
                    }}
                    disabled={true}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {allSection?.map((section) => (
                      <option
                        selected={data?.section?._id === section?._id && true}
                        value={JSON.stringify({
                          name: section?.sectionName,
                          id: section?._id,
                        })}
                      >
                        {section?.sectionName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Select Exam
                  </label>
                  <Input
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setExamName(values?.name);
                      setExamId(values?.id);
                    }}
                    disabled={true}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {examList?.map((exam) => (
                      <option
                        selected={data?.exam?._id === exam?._id && true}
                        value={JSON.stringify({
                          name: exam?.name,
                          id: exam?._id,
                        })}
                      >
                        {exam?.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" sm="4" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    Subject
                  </label>
                  <Input
                    onChange={(e) => {
                      const values = JSON.parse(e.target.value);
                      setSubjectName(values?.name);
                      setSubjectId(values?.id);
                    }}
                    disabled={true}
                    id="exampleFormControlSelect1"
                    type="select"
                  >
                    <option>Select</option>
                    {allSubject?.map((subject) => (
                      <option
                        selected={subject?._id === data?.subject?._id && true}
                        value={JSON.stringify({
                          name: subject?.subjectName,
                          id: subject?._id,
                        })}
                      >
                        {subject?.subjectName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </section>
          <div className="mt-5">
            <Container fluid>
              <Table responsive>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Student Name</th>
                    <th>Roll</th>
                    <th>Subject</th>
                    {examSchedule?.writtenFullMark &&
                      examSchedule?.writtenPassMark && (
                        <th>Written ({examSchedule?.writtenFullMark})</th>
                      )}
                    {examSchedule?.practicalFullMark &&
                      examSchedule?.practicalPassMark && (
                        <th>Practical ({examSchedule?.practicalFullMark})</th>
                      )}
                    {examSchedule?.attendanceFullMark &&
                      examSchedule?.attendancePassMark && (
                        <th>Attendance ({examSchedule?.attendanceFullMark})</th>
                      )}
                    {examSchedule?.vivaFullMark &&
                      examSchedule?.vivaPassMark && (
                        <th>Viva ({examSchedule?.vivaFullMark})</th>
                      )}
                    {examSchedule?.presentationFullMark &&
                      examSchedule?.presentationPassMark && (
                        <th>
                          Presentation ({examSchedule?.presentationFullMark})
                        </th>
                      )}
                    {examSchedule?.paper1FullMark &&
                      examSchedule?.paper1PassMark && (
                        <th>Paper 1 ({examSchedule?.paper1FullMark})</th>
                      )}
                    {examSchedule?.paper2FullMark &&
                      examSchedule?.paper2PassMark && (
                        <th>Paper 2 ({examSchedule?.paper2FullMark})</th>
                      )}
                    {examSchedule?.paper3FullMark &&
                      examSchedule?.paper3PassMark && (
                        <th>Paper 3 ({examSchedule?.paper3FullMark})</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {data?.marks?.map((x, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {x?.firstName} {x?.lastName}
                      </td>
                      <td>{x?.roll}</td>
                      <td>{data?.subject?.subjectName}</td>
                      {examSchedule?.writtenFullMark &&
                        examSchedule?.writtenPassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.written}
                                type="number"
                                name="written"
                                min={0}
                                max={Number(examSchedule?.writtenFullMark || 0)}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.practicalFullMark &&
                        examSchedule?.practicalPassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.practical}
                                type="number"
                                name="practical"
                                min={0}
                                max={Number(
                                  examSchedule?.practicalFullMark || 0
                                )}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.attendanceFullMark &&
                        examSchedule?.attendancePassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.attendance}
                                type="number"
                                name="attendance"
                                min={0}
                                max={Number(
                                  examSchedule?.attendanceFullMark || 0
                                )}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.vivaFullMark &&
                        examSchedule?.vivaPassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.viva}
                                type="number"
                                name="viva"
                                min={0}
                                max={Number(examSchedule?.vivaFullMark || 0)}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.presentationFullMark &&
                        examSchedule?.presentationPassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.presentation}
                                type="number"
                                name="presentation"
                                min={0}
                                max={Number(
                                  examSchedule?.presentationFullMark || 0
                                )}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.paper1FullMark &&
                        examSchedule?.paper1PassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.paper1}
                                type="number"
                                name="paper1"
                                min={0}
                                max={Number(examSchedule?.paper1FullMark || 0)}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.paper2FullMark &&
                        examSchedule?.paper2PassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.paper2}
                                type="number"
                                name="paper2"
                                min={0}
                                max={Number(examSchedule?.paper2FullMark || 0)}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                      {examSchedule?.paper3FullMark &&
                        examSchedule?.paper3PassMark && (
                          <td>
                            <FormGroup>
                              <Input
                                onWheel={() => document.activeElement.blur()}
                                id="example-time-input"
                                defaultValue={x?.paper3}
                                type="number"
                                name="paper3"
                                min={0}
                                max={Number(examSchedule?.paper3FullMark || 0)}
                                onChange={(evnt) => handleChange(index, evnt)}
                              />
                            </FormGroup>
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
            <div className="text-right mt-4">
              {editedMarks?.length > 0 && (
                <Button onClick={handleUpdate} color="success">
                  Update
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMarks;
