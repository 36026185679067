import baseApi from "../../api/baseApi";

export const getExpenseChart = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getExpenseChart?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getIncomeChart = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getIncomeChart?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentFeesChart = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentFeesChart?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchWiseStudentCount = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchWiseStudentCount?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchWiseEmployeeCount = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchWiseEmployeeCount?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchWiseTeacherCount = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchWiseTeacherCount?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBranchWiseEventCount = async (branchName, branchId) => {
  try {
    const response = await baseApi.get(
      `/api/getBranchWiseEventCount?branchName=${branchName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentEventCount = async (branchId, classId) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentEventCount?branchId=${branchId}&classId=${classId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentIssuedBookCount = async (studentId) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentIssuedBookCount?studentId=${studentId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentFeesHistoryChart = async (
  branchName,
  branchId,
  studentId
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentFeesHistoryChart?branchName=${branchName}&branchId=${branchId}&studentId=${studentId}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getGlobalSearchInfo = async (branchName, branchId, searchText) => {
  try {
    const response = await baseApi.get(
      `/api/getSearchResult?branchName=${branchName}&branchId=${branchId}&searchText=${searchText}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};
