import { branchWiseSession } from "network/helpers/basedOnApis";
import { useEffect, useState } from "react";

const useFetchBranchSessions = ({ branchId }) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSessions() {
      setLoading(true);
      try {
        const data = await branchWiseSession(branchId);
        setSessions(data?.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (branchId) {
      fetchSessions();
    }
  }, [branchId]);

  return { sessions, loading, error };
};

export default useFetchBranchSessions;
