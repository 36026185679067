import { getOneStudentQualities } from "network/apiStudentQualities/apiStudentQualities";
import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Modal } from "reactstrap";
import SingleSheet from "./SingleSheet";
import "./style.css";

const MarkSheetModal = ({
  modalOpen,
  setModalOpen,
  className,
  classNumeric,
  semester,
  marks,
  highestMarks,
  fullMark,
  studentInfo,
  filteredSubjects,
  paperMarks,
}) => {
  const [qualities, setQualities] = React.useState({});

  React.useEffect(() => {
    const fetchData = async () => {
      if (studentInfo?.branch && studentInfo?.session) {
        try {
          const data = await getOneStudentQualities(
            studentInfo._id,
            studentInfo.branch?.branchName,
            studentInfo.session?.sessionName
          );
          setQualities(data);
        } catch (error) {
          console.error("Error fetching student qualities:", error);
        }
      }
    };

    fetchData();
  }, [studentInfo]);

  const componentRef = React.useRef(null);

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-xl"
      >
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="modal-title" id="exampleModalScrollableTitle">
              Report Card
            </h5>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  size="md"
                  className="buttons-copy buttons-html5 mr-auto ml-3"
                  id="print-tooltip"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <div ref={componentRef} style={{ minWidth: "1131.1px" }}>
          <SingleSheet
            className={className}
            classNumeric={classNumeric}
            semester={semester}
            marks={marks}
            qualities={qualities}
            highestMarks={highestMarks}
            fullMark={fullMark}
            studentInfo={studentInfo}
            filteredSubjects={filteredSubjects}
            paperMarks={paperMarks}
          />
        </div>
        <div style={{ marginTop: "400px" }} className="modal-footer">
          <Button
            color="primary"
            outline
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MarkSheetModal;
