import baseApi from "../../api/baseApi";

export const getTabulationSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  exam
) => {
  try {
    const response = await baseApi.get(
      `/api/getTabulation?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&exam=${exam}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    return e;
  }
};

export const getTabulationTenSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  exam
) => {
  try {
    const response = await baseApi.get(
      `/api/getTenUpTabulation?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&exam=${exam}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    return e;
  }
};

export const getTabulationSemester = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  classNumeric,
  sectionId,
  semester
) => {
  try {
    const response = await baseApi.get(
      `/api/getTabulationTotal?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&classNumeric=${classNumeric}&sectionId=${sectionId}&semester=${semester}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    return e;
  }
};
