import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import NotifyContext from "context/NotifyContext";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import {
  getFilterWiseExamMarks,
  getResultsPublishedAndLockedStatus,
  publishResult,
  resultsLockStatusUpdate,
} from "network/apiMarkEntries/apiMarkEntries";
import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import React from "react";
import ReactSwitch from "react-switch";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";

const PublishResult = ({ data }) => {
  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [page, setPage] = React.useState(defaultPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);
  //Filters
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [allExam, setAllExam] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);
  const [filteredMarks, setFilteredMarks] = React.useState({});

  const [isLocked, setIsLocked] = React.useState(false);
  const [isPublish, setIsPublish] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessComplete, setIsProcessComplete] = React.useState(false);

  const { Notify } = React.useContext(NotifyContext);

  const user = JSON.parse(localStorage.getItem("user"));
  const currentSession = JSON.parse(localStorage.getItem("session"));

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(page, showPerPage, searchTerm);
      setAllBranch(data?.data);
    }

    loadBranch();
  }, [page, showPerPage, searchTerm]);

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(branchId);
      setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);
      setAllClass(data?.data);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  React.useEffect(() => {
    // async function branchSessionToExamList() {
    //     const data = await getBranchSessionWiseExamList(user?.branch?.branchName, user?.branch?._id, user?.session?.sessionName, user?.session?._id);
    //     setAllExam(data);
    // }
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionClassSectionToExamList();
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  React.useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAssignSubjectForFilter(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId
      );
      setAllSubject(data);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionToSubject();
    }
  }, [sectionId]);

  React.useEffect(() => {
    if (branchId && sessionId) {
      handleFilter();
    }
  }, [page, showPerPage, searchTerm]);

  const handleFilter = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsProcessComplete(false);
      setFilteredMarks({});
      const response = await getFilterWiseExamMarks(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );

      if (Object.keys(response[0])?.length > 0) {
        setFilteredMarks(response[0]);
        const isPublishAndLocked = await getResultsPublishedAndLockedStatus(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId,
          examId,
          subjectId
        );
        setIsPublish(isPublishAndLocked?.isPublish || false);
        setIsLocked(isPublishAndLocked?.isLocked || false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  const handlePublishResult = async () => {
    try {
      await store.dispatch(ShowLoader());
      const response = await publishResult(filteredMarks);
      if (response?.message === "Result Published") {
        await Notify("success", response?.message, "Result");
        await handleFilter();
      }
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
    }
  };

  const handleLockResult = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsLoading(true);

      const response = await resultsLockStatusUpdate(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId,
        !isLocked
      );

      if (response?.message === "Result Lock Status Updated") {
        setIsLocked(!isLocked);
        await Notify("success", response?.message, "Result");
        await handleFilter();
      }
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
      setIsLoading(false);
    }
  };

  return (
    <div>
      <SimpleHeader name="Publish" parentName="Result" />
      <Container className="mt--6" fluid>
        <Card>
          <Container fluid>
            <Card className="shadow mt-4">
              <CardBody>
                <section>
                  <CardTitle>
                    <h3 className="mb-0">
                      <i className="ni ni-active-40 text-orange" />
                      <span
                        className="ml-2"
                        style={{ fontSize: "large", color: "orange" }}
                      >
                        Select Ground
                      </span>
                      <hr />
                    </h3>
                  </CardTitle>
                  {user?.role === "superAdmin" && (
                    <Row>
                      <Col md="6" sm="6" lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example4cols1Input"
                          >
                            Branch
                          </label>
                          <Input
                            onChange={(e) => {
                              const values = JSON.parse(e.target.value);
                              setBranchName(values?.name);
                              setBranchId(values?.id);
                            }}
                            id="exampleFormControlSelect1"
                            type="select"
                          >
                            <option
                              selected={
                                branchName === "" && branchId === "" && true
                              }
                            >
                              Select
                            </option>
                            {allBranch?.map((branch) => (
                              <option
                                value={JSON.stringify({
                                  name: branch?.branchName,
                                  id: branch?._id,
                                })}
                              >
                                {branch?.branchName}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="6" sm="6" lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example4cols1Input"
                          >
                            Session
                          </label>
                          <Input
                            onChange={(e) => {
                              const values = JSON.parse(e.target.value);
                              setSessionName(values?.name);
                              setSessionId(values?.id);
                            }}
                            id="exampleFormControlSelect1"
                            type="select"
                          >
                            <option
                              selected={
                                sessionName === "" && sessionId === "" && true
                              }
                            >
                              Select
                            </option>
                            {allSession?.map((session) => (
                              <option
                                value={JSON.stringify({
                                  name: session?.sessionName,
                                  id: session?._id,
                                })}
                              >
                                {session?.sessionName}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md="3" sm="3" lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Class
                        </label>
                        <Input
                          onChange={(e) => {
                            const values = JSON.parse(e.target.value);
                            setClassName(values?.name);
                            setClassId(values?.id);
                          }}
                          id="exampleFormControlSelect1"
                          type="select"
                        >
                          <option
                            selected={
                              className === "" && classId === "" && true
                            }
                          >
                            Select
                          </option>
                          {allClass?.map((cls) => (
                            <option
                              value={JSON.stringify({
                                name: cls?.className,
                                id: cls?._id,
                              })}
                            >
                              {cls?.className}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3" sm="3" lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Section
                        </label>
                        <Input
                          onChange={(e) => {
                            const values = JSON.parse(e.target.value);
                            setSectionName(values?.name);
                            setSectionId(values?.id);
                          }}
                          id="exampleFormControlSelect1"
                          type="select"
                        >
                          <option
                            selected={
                              sectionName === "" && sectionId === "" && true
                            }
                          >
                            Select
                          </option>
                          {allSection?.map((section) => (
                            <option
                              value={JSON.stringify({
                                name: section?.sectionName,
                                id: section?._id,
                              })}
                            >
                              {section?.sectionName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3" sm="3" lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Exam
                        </label>
                        <Input
                          onChange={(e) => {
                            const values = JSON.parse(e.target.value);
                            setExamName(values?.name);
                            setExamId(values?.id);
                          }}
                          id="exampleFormControlSelect1"
                          type="select"
                        >
                          <option
                            selected={examName === "" && examId === "" && true}
                          >
                            Select
                          </option>
                          {allExam?.map((exam) => (
                            <option
                              value={JSON.stringify({
                                name: exam?.name,
                                id: exam?._id,
                              })}
                            >
                              {exam?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3" sm="3" lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Subject
                        </label>
                        <Input
                          onChange={(e) => {
                            const values = JSON.parse(e.target.value);
                            setSubjectName(values?.name);
                            setSubjectId(values?.id);
                          }}
                          id="exampleFormControlSelect1"
                          type="select"
                        >
                          <option
                            selected={
                              subjectName === "" && subjectId === "" && true
                            }
                          >
                            Select
                          </option>
                          {allSubject?.map((subject) => (
                            <option
                              value={JSON.stringify({
                                name: subject?.label,
                                id: subject?.value,
                              })}
                            >
                              {subject?.label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button onClick={handleFilter} color="success">
                      Filter
                    </Button>
                  </div>
                </section>
                {isProcessComplete === true &&
                  Object.keys(filteredMarks)?.length > 0 && (
                    <div
                      style={{ gap: "2rem" }}
                      className="d-flex justify-content-between"
                    >
                      <Card
                        style={{
                          alignSelf: "stretch",
                          flex: "1",
                          maxWidth: "50%",
                        }}
                        className="card-stats w-full mx-4 mt-4"
                      >
                        <CardBody className="h-full d-flex align-items-center">
                          {isPublish === true ? (
                            <h3
                              style={{ lineHeight: "1" }}
                              className="text-uppercase mb-0 text-green"
                            >
                              <span>Result Published</span>{" "}
                              {isLocked && (
                                <span className="text-red">& Locked</span>
                              )}
                            </h3>
                          ) : (
                            <div
                              style={{ flex: "1" }}
                              className="d-flex w-full align-items-center"
                            >
                              <Button
                                style={{ flex: "1" }}
                                onClick={handlePublishResult}
                                color="warning"
                                outline
                              >
                                Click Here To Publish
                              </Button>
                              <div className="icon ml-2 icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                      {(user?.role === "superAdmin" ||
                        user?.role === "admin") && (
                        <Card
                          style={{ alignSelf: "stretch", flex: "1" }}
                          className="card-stats w-full mx-4 mt-4"
                        >
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div
                                style={{ flex: "1" }}
                                color="warning"
                                outline
                              >
                                {isLocked ? (
                                  <div>
                                    <strong className="text-green">
                                      "LOCKED"
                                    </strong>{" "}
                                    <span>Click Here To UnLock ~</span>
                                  </div>
                                ) : (
                                  <div>
                                    <strong className="text-red">
                                      "UNLOCKED"
                                    </strong>{" "}
                                    <span>Click Here To Lock ~</span>
                                  </div>
                                )}
                              </div>
                              <ReactSwitch
                                checked={isLocked}
                                onChange={handleLockResult}
                                disabled={
                                  user?.role !== "superAdmin" &&
                                  user?.role !== "admin" &&
                                  isLoading
                                }
                              />
                              <div className="icon ml-2 icon-shape bg-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  )}

                {isProcessComplete === true && (
                  <>
                    {Object.keys(filteredMarks)?.length > 0 ? (
                      <div>
                        <Container fluid className="mt-4">
                          <Table responsive hover bordered>
                            <thead>
                              <tr>
                                {/* <th>SL</th> */}
                                <th>Name</th>
                                {/* <th>Register No</th> */}
                                <th>Roll</th>
                                <th>Exam</th>
                                <th>Subject</th>
                                {filteredMarks?.marks?.some(
                                  (m) => m?.written
                                ) && <th>Written</th>}
                                {filteredMarks?.marks?.some(
                                  (m) => m?.practical
                                ) && <th>Practical</th>}
                                {filteredMarks?.marks?.some((m) => m?.viva) && (
                                  <th>Viva</th>
                                )}
                                {filteredMarks?.marks?.some(
                                  (m) => m?.presentation
                                ) && <th>Presentation</th>}
                                {filteredMarks?.marks?.some(
                                  (m) => m?.paper1
                                ) && <th>Paper 1</th>}
                                {filteredMarks?.marks?.some(
                                  (m) => m?.paper2
                                ) && <th>Paper 2</th>}
                                {filteredMarks?.marks?.some(
                                  (m) => m?.paper3
                                ) && <th>Paper 3</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {filteredMarks?.marks?.map((x, index) => (
                                <tr>
                                  {/* <td>{index + 1}</td> */}
                                  <td>
                                    {x?.firstName} {x?.lastName}
                                  </td>
                                  {/* <td>{x?.regNo}</td> */}
                                  <td>{x?.roll}</td>
                                  <td>{filteredMarks?.exam?.name}</td>
                                  <td>{filteredMarks?.subject?.subjectName}</td>
                                  {(x?.written ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.written
                                    )) &&
                                    (x?.written ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.written) /
                                            Number(x?.writtenFullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.written) /
                                                Number(x?.writtenFullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.written})`) ||
                                          (Math.round(
                                            (Number(x?.written) /
                                              Number(x?.writtenFullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.written})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.practical ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.practical
                                    )) &&
                                    (x?.practical ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.practical) /
                                            Number(x?.practicalFullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.practical) /
                                                Number(x?.practicalFullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.practical})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.practicalFullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.practical})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.attendance ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.attendance
                                    )) &&
                                    (x?.attendance ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.attendance) /
                                            Number(x?.attendanceFullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.practical) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.attendance) /
                                                Number(x?.attendanceFullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.attendance})`) ||
                                          (Math.round(
                                            (Number(x?.attendance) /
                                              Number(x?.attendanceFullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.attendance})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.viva ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.viva
                                    )) &&
                                    (x?.viva ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.viva) /
                                            Number(x?.vivaFullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.viva) /
                                                Number(x?.vivaFullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.viva})`) ||
                                          (Math.round(
                                            (Number(x?.viva) /
                                              Number(x?.vivaFullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.viva})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.presentation ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.presentation
                                    )) &&
                                    (x?.presentation ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.presentation) /
                                            Number(x?.presentationFullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.presentation) /
                                                Number(
                                                  x?.presentationFullMark
                                                )) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.presentation})`) ||
                                          (Math.round(
                                            (Number(x?.presentation) /
                                              Number(x?.presentationFullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.presentation})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.paper1 ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.paper1
                                    )) &&
                                    (x?.paper1 ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.paper1) /
                                            Number(x?.paper1FullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.paper1) /
                                                Number(x?.paper1FullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.paper1})`) ||
                                          (Math.round(
                                            (Number(x?.paper1) /
                                              Number(x?.paper1FullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.paper1})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.paper2 ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.paper2
                                    )) &&
                                    (x?.paper2 ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.paper2) /
                                            Number(x?.paper2FullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.paper2) /
                                                Number(x?.paper2FullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.paper2})`) ||
                                          (Math.round(
                                            (Number(x?.paper2) /
                                              Number(x?.paper2FullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.paper2})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                  {(x?.paper3 ||
                                    filteredMarks?.marks?.some(
                                      (m) => m?.paper3
                                    )) &&
                                    (x?.paper3 ? (
                                      <td>
                                        {(Math.round(
                                          (Number(x?.paper3) /
                                            Number(x?.paper3FullMark)) *
                                            100
                                        ) >= 95 &&
                                          `A+ (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 90 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 95 &&
                                            `A (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 85 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 90 &&
                                            `B+ (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 80 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 85 &&
                                            `B (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 75 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 80 &&
                                            `C+ (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 70 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 75 &&
                                            `C (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 65 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 70 &&
                                            `D+ (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) >= 60 &&
                                            Math.round(
                                              (Number(x?.paper3) /
                                                Number(x?.paper3FullMark)) *
                                                100
                                            ) < 65 &&
                                            `D (${x?.paper3})`) ||
                                          (Math.round(
                                            (Number(x?.paper3) /
                                              Number(x?.paper3FullMark)) *
                                              100
                                          ) < 60 &&
                                            `U (${x?.paper3})`)}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    ))}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Container>
                      </div>
                    ) : (
                      <div className="text-center mt-4">
                        <h1>No Data Found</h1>
                      </div>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default PublishResult;
