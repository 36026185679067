import axios from "axios";

/*let liveHost = "154.12.229.20";
portal.sjs.edu.bd
let localHost = "localhost";*/

// const base = axios.create({
//   baseURL: `http://localhost:4000`,
// });

const base = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://portal.sjs.edu.bd"
      : "https://portal.sjs.edu.bd",
});

base.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("user"));
  config.headers.Authorization = token ? `Bearer ${token?.accessToken}` : "";
  return config;
});
export default base;
