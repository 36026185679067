import baseApi from "../../api/baseApi";

export const manageUserLogin = async (branchName,branchId,userType,userId,userPassword,isWeb,webId) => {
    const payload = {
        branchName:branchName,
        branchId:branchId,
        userType:userType,
        userId:userId,
        userPassword:userPassword,
        isWeb:isWeb,
        webId:webId
    }
    try {
        const response = await baseApi.post(`/api/manageUserLogin`,payload,{
            headers:{"Content-type":"application/json"}
        });
        if (response){
            return response;
        }else {
            return `"danger","Something Went Wrong","Session"`;
        }
    }catch (e) {
        return (e.response);
    }
};
