import { classWiseSection } from "network/helpers/basedOnApis";
import { useEffect, useState } from "react";

const useFetchClassSections = ({ classId }) => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSections() {
      setLoading(true);
      try {
        const data = await classWiseSection(classId);
        setSections(data?.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (classId) {
      fetchSections();
    }
  }, [classId]);

  return { sections, loading, error };
};

export default useFetchClassSections;
