import {createSlice} from "@reduxjs/toolkit";

export const percentageStatusSlice = createSlice({
    name: 'progressStatus',
    initialState: {
        status: 0
    },
    reducers: {
        Percentage: (state, {payload}) => {
            state.status = payload;
        },
    }
});
export const {Percentage} = percentageStatusSlice.actions;
export default percentageStatusSlice.reducer;
