import baseApi from "../../api/baseApi";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "../../constants/jsons/defaultTableHelper.json";
import { getAllSession } from "../manageSession/apiManageSession";

export const addStudent = async (
  sessionId,
  sessionName,
  branchId,
  branchName,
  classId,
  className,
  sectionId,
  sectionName,
  groupId,
  groupName,
  regNo,
  roll,
  admissionDate,
  firstName,
  lastName,
  gender,
  bloodGroup,
  dob,
  religion,
  caste,
  mobileNo,
  email,
  city,
  division,
  presentAddress,
  permanentAddress,
  guardianName,
  relation,
  fatherName,
  motherName,
  fatherMobile,
  motherMobile,
  occupation,
  guardianMobileNo,
  guardianEmail,
  guardianCity,
  guardianDivision,
  address,
  schoolName,
  qualification,
  remarks,
  profilePhoto
) => {
  const payload = new FormData();
  payload.append("sessionId", sessionId);
  payload.append("sessionName", sessionName);
  payload.append("branchId", branchId);
  payload.append("branchName", branchName);
  payload.append("className", className);
  payload.append("classId", classId);
  payload.append("sectionName", sectionName);
  payload.append("sectionId", sectionId);
  payload.append("groupName", groupName);
  payload.append("groupId", groupId);
  payload.append("regNo", regNo);
  payload.append("roll", roll);
  payload.append("admissionDate", admissionDate);
  payload.append("firstName", firstName);
  payload.append("lastName", lastName);
  payload.append("gender", gender);
  payload.append("bloodGroup", bloodGroup);
  payload.append("dob", dob);
  payload.append("religion", religion);
  payload.append("caste", caste);
  payload.append("mobileNo", mobileNo);
  payload.append("email", email);
  payload.append("city", city);
  payload.append("division", division);
  payload.append("presentAddress", presentAddress);
  payload.append("permanentAddress", permanentAddress);
  payload.append("guardianName", guardianName);
  payload.append("relation", relation);
  payload.append("fatherName", fatherName);
  payload.append("motherName", motherName);
  payload.append("fatherMobile", fatherMobile);
  payload.append("motherMobile", motherMobile);
  payload.append("occupation", occupation);
  payload.append("guardianMobileNo", guardianMobileNo);
  payload.append("guardianEmail", guardianEmail);
  payload.append("guardianCity", guardianCity);
  payload.append("guardianDivision", guardianDivision);
  payload.append("address", address);
  payload.append("schoolName", schoolName);
  payload.append("qualification", qualification);
  payload.append("remarks", remarks);
  payload.append("profilePhoto", profilePhoto);
  try {
    const response = await baseApi.post(`/api/addStudent`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getAllStudent = async (
  page,
  showPerPage,
  searchTerm,
  branchName,
  sessionName,
  branchId,
  classId,
  sectionId,
  sort
) => {
  try {
    const response = await baseApi.get(
      `/api/getAllStudent?page=${page}&limit=${showPerPage}&search=${searchTerm}&branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&classId=${classId}&sectionId=${sectionId}&sort=${sort}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentForCashier = async (
  searchTerm,
  branchName,
  sessionName,
  branchId
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentForCashier?search=${searchTerm}&branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getOneStudent = async (id, branchName, sessionName) => {
  try {
    const response = await baseApi.get(
      `/api/getOneStudent/${id}?branchName=${branchName}&sessionName=${sessionName}`
    );
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateOneStudent = async (
  id,
  sessionId,
  sessionName,
  branchId,
  branchName,
  classId,
  className,
  sectionId,
  sectionName,
  groupId,
  groupName,
  regNo,
  roll,
  admissionDate,
  firstName,
  lastName,
  gender,
  bloodGroup,
  dob,
  motherTongue,
  religion,
  caste,
  mobileNo,
  email,
  city,
  division,
  presentAddress,
  permanentAddress,
  guardianName,
  relation,
  fatherName,
  motherName,
  fatherMobile,
  motherMobile,
  occupation,
  income,
  education,
  guardianMobileNo,
  guardianEmail,
  guardianCity,
  guardianDivision,
  address,
  schoolName,
  qualification,
  remarks,
  profilePhoto,
  oldProfilePhoto,
  password
) => {
  const payload = new FormData();
  payload.append("sessionId", sessionId);
  payload.append("sessionName", sessionName);
  payload.append("branchId", branchId);
  payload.append("branchName", branchName);
  payload.append("className", className);
  payload.append("classId", classId);
  payload.append("sectionName", sectionName);
  payload.append("sectionId", sectionId);
  payload.append("groupName", groupName);
  payload.append("groupId", groupId);
  payload.append("regNo", regNo);
  payload.append("roll", roll);
  payload.append("admissionDate", admissionDate);
  payload.append("firstName", firstName);
  payload.append("lastName", lastName);
  payload.append("gender", gender);
  payload.append("bloodGroup", bloodGroup);
  payload.append("dob", dob);
  payload.append("motherTongue", motherTongue);
  payload.append("religion", religion);
  payload.append("caste", caste);
  payload.append("mobileNo", mobileNo);
  payload.append("email", email);
  payload.append("city", city);
  payload.append("division", division);
  payload.append("presentAddress", presentAddress);
  payload.append("permanentAddress", permanentAddress);
  payload.append("guardianName", guardianName);
  payload.append("relation", relation);
  payload.append("fatherName", fatherName);
  payload.append("motherName", motherName);
  payload.append("fatherMobile", fatherMobile);
  payload.append("motherMobile", motherMobile);
  payload.append("occupation", occupation);
  payload.append("income", income);
  payload.append("education", education);
  payload.append("guardianMobileNo", guardianMobileNo);
  payload.append("guardianEmail", guardianEmail);
  payload.append("guardianCity", guardianCity);
  payload.append("guardianDivision", guardianDivision);
  payload.append("address", address);
  payload.append("schoolName", schoolName);
  payload.append("qualification", qualification);
  payload.append("remarks", remarks);
  payload.append("password", password);
  payload.append("oldProfilePhoto", oldProfilePhoto);
  payload.append("profilePhoto", profilePhoto);

  try {
    const response = await baseApi.put(`/api/updateOneStudent/${id}`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      console.log("Something Went Wrong");
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const deleteStudent = async (id, branchName, sessionName) => {
  try {
    const response = await baseApi.delete(
      `/api/deleteOneStudent/${id}?branchName=${branchName}&sessionName=${sessionName}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      const allStudent = await getAllSession(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      return {
        allStudent: allStudent?.data,
        deleteStatus: response?.data?.message,
      };
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const addBulkStudent = async (branchName, sessionName, bulkStudent) => {
  const payload = {
    branchName: branchName,
    sessionName: sessionName,
    bulkStudent: bulkStudent,
  };
  try {
    const response = await baseApi.post(`/api/addBulkStudent`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const addPromotionStudent = async (
  branchName,
  oldSessionName,
  newSessionName,
  newSessionId,
  newClassId,
  newSectionId,
  promotionStatus
) => {
  const payload = {
    branchName: branchName,
    oldSessionName: oldSessionName,
    newSessionName: newSessionName,
    newSessionId: newSessionId,
    newClassId: newClassId,
    newSectionId: newSectionId,
    promotionStatus: promotionStatus,
  };
  try {
    const response = await baseApi.post(`/api/addStudentPromotion`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateStudentPassword = async (
  branchName,
  sessionName,
  studentId,
  password
) => {
  const payload = {
    branchName: branchName,
    sessionName: sessionName,
    studentId,
    password,
  };

  try {
    const response = await baseApi.post(`/api/changeStudentPassword`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};
