import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import { getAllBranch } from "network/branch/loadBranch";
import { useEffect, useState } from "react";

const useFetchBranches = ({
  page = defaultPage,
  showPerPage = defaultShowPerPage,
  searchTerm = defaultSearchTerm,
}) => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBranches() {
      setLoading(true);
      try {
        const data = await getAllBranch(page, showPerPage, searchTerm);
        setBranches(data?.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchBranches();
  }, [page, showPerPage, searchTerm]);

  return { branches, loading, error };
};

export default useFetchBranches;
