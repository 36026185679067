import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import { getStudentWiseExamMarks } from "network/apiMarkEntries/apiMarkEntries";
import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import React from "react";
import ReactToPrint from "react-to-print";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";

const ExamResultStudent = () => {
  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [page, setPage] = React.useState(defaultPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);
  //Filters

  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");

  const [allExam, setAllExam] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);
  const [filteredMarks, setFilteredMarks] = React.useState({});

  const [isProcessComplete, setIsProcessComplete] = React.useState(false);

  const componentRef = React.useRef(null);

  const user = JSON.parse(localStorage.getItem("user"));

  React.useEffect(() => {
    // async function branchSessionToExamList() {
    //     const data = await getBranchSessionWiseExamList(user?.branch?.branchName, user?.branch?._id, user?.session?.sessionName, user?.session?._id);
    //     setAllExam(data);
    // }
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          user?.branch?.branchName,
          user?.branch?._id,
          user?.session?.sessionName,
          user?.session?._id,
          user?.class?._id,
          user?.section?._id
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule);
    }

    if (
      user?.branch?.branchName &&
      user?.branch?._id &&
      user?.session?.sessionName &&
      user?.session?._id
    ) {
      branchSessionClassSectionToExamList();
    }
  }, [user?.session?._id]);

  React.useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAssignSubjectForFilter(
        user?.branch?.branchName,
        user?.branch?._id,
        user?.session?.sessionName,
        user?.session?._id,
        user?.class?._id,
        user?.section?._id
      );
      await setAllSubject(data);
    }

    if (
      user?.branch?.branchName &&
      user?.branch?._id &&
      user?.session?.sessionName &&
      user?.session?._id
    ) {
      branchSessionToSubject();
    }
  }, [user?.session?._id]);

  const handleFilter = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsProcessComplete(false);
      const response = await getStudentWiseExamMarks(
        user?.branch?.branchName,
        user?.branch?._id,
        user?.session?.sessionName,
        user?.session?._id,
        user?.class?._id,
        user?.section?._id,
        examId,
        subjectId,
        user?._id
      );
      setFilteredMarks(response);
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  React.useEffect(() => {
    if (user?.branch?._id && user?.session?._id) {
      handleFilter();
    }
  }, [page, showPerPage, searchTerm]);
  return (
    <div>
      <SimpleHeader name="Results" parentName="Exam" />
      <Container className="mt--6" fluid>
        <Card>
          <Container fluid>
            <CardBody>
              <section>
                <CardTitle>
                  <h3 className="mb-0">
                    <i className="ni ni-active-40 text-orange" />
                    <span
                      className="ml-2"
                      style={{ fontSize: "large", color: "orange" }}
                    >
                      Select Ground
                    </span>
                    <hr />
                  </h3>
                </CardTitle>
                <Row>
                  <Col md="6" sm="6" lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Exam
                      </label>
                      <Input
                        onChange={(e) => {
                          const values = JSON.parse(e.target.value);
                          setExamName(values?.name);
                          setExamId(values?.id);
                        }}
                        id="exampleFormControlSelect1"
                        type="select"
                      >
                        <option
                          selected={examName === "" && examId === "" && true}
                        >
                          Select
                        </option>
                        {allExam?.map((exam) => (
                          <option
                            value={JSON.stringify({
                              name: exam?.name,
                              id: exam?._id,
                            })}
                          >
                            {exam?.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example4cols1Input"
                      >
                        Subject
                      </label>
                      <Input
                        onChange={(e) => {
                          const values = JSON.parse(e.target.value);
                          setSubjectName(values?.name);
                          setSubjectId(values?.id);
                        }}
                        id="exampleFormControlSelect1"
                        type="select"
                      >
                        <option
                          selected={
                            subjectName === "" && subjectId === "" && true
                          }
                        >
                          Select
                        </option>
                        {allSubject?.map((subject) => (
                          <option
                            value={JSON.stringify({
                              name: subject?.label,
                              id: subject?.value,
                            })}
                          >
                            {subject?.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right">
                  <>
                    {isProcessComplete && filteredMarks?.marks?.length > 0 && (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="buttons-copy buttons-html5"
                            id="print-tooltip"
                          >
                            Print
                          </Button>
                        )}
                        content={() => componentRef.current}
                      />
                    )}
                  </>
                  <Button
                    className="ml-2"
                    onClick={handleFilter}
                    color="success"
                  >
                    Filter
                  </Button>
                </div>
              </section>
              {isProcessComplete && (
                <>
                  {filteredMarks?.marks?.length > 0 ? (
                    <div ref={componentRef}>
                      <Container fluid className="mt-2">
                        <Table responsive hover bordered>
                          <thead>
                            <tr>
                              <th>SL</th>
                              <th>Name</th>
                              <th>Subject</th>
                              {filteredMarks?.marks?.some(
                                (m) => m?.written
                              ) && <th>Written</th>}
                              {filteredMarks?.marks?.some(
                                (m) => m?.practical
                              ) && <th>Practical</th>}
                              {filteredMarks?.marks?.some((m) => m?.viva) && (
                                <th>Viva</th>
                              )}
                              {filteredMarks?.marks?.some(
                                (m) => m?.presentation
                              ) && <th>Presentation</th>}
                              {filteredMarks?.marks?.some((m) => m?.paper1) && (
                                <th>Paper 1</th>
                              )}
                              {filteredMarks?.marks?.some((m) => m?.paper2) && (
                                <th>Paper 2</th>
                              )}
                              {filteredMarks?.marks?.some((m) => m?.paper3) && (
                                <th>Paper 3</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredMarks?.marks?.map((x, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{x?.firstName + " " + x?.lastName}</td>
                                <td>{filteredMarks?.subject?.subjectName}</td>
                                {x?.written &&
                                  (x?.written ? (
                                    <td>{x?.written}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.practical &&
                                  (x?.practical ? (
                                    <td>{x?.practical}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.attendance &&
                                  (x?.attendance ? (
                                    <td>{x?.attendance}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.viva &&
                                  (x?.viva ? <td>{x?.viva}</td> : <td>-</td>)}
                                {x?.presentation &&
                                  (x?.presentation ? (
                                    <td>{x?.presentation}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper1 &&
                                  (x?.paper1 ? (
                                    <td>{x?.paper1}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper2 &&
                                  (x?.paper2 ? (
                                    <td>{x?.paper2}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper3 &&
                                  (x?.paper3 ? (
                                    <td>{x?.paper3}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Container>
                    </div>
                  ) : (
                    <div className="text-center">
                      <h3>No Data Found</h3>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default ExamResultStudent;
