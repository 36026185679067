import baseApi from "../../api/baseApi";

export const addStudentQualities = async (payload) => {
    try {
        const response = await baseApi.post(`/api/addStudentQualities`, payload, {
            headers: {"Content-type": "application/json"}
        });
        if (response.statusText !== "OK") {
            return `"danger","Something Went Wrong","Session"`;
        } else {
            return response?.data;
        }
    } catch (e) {
        console.log(e.response);
        return "Something went wrong"; // Return an error message
    }
};

export const getOneStudentQualities = async (studentId, branchName, sessionName) => {
    try {
        const response = await baseApi.get(`/api/getOneStudentQualities/${studentId}?sessionName=${sessionName}&branchName=${branchName}`, {
            headers: {"Content-type": "application/json"}
        });
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return response?.data;
        }
    } catch (e) {
        console.log(e.response);
        return "Something went wrong"; // Return an error message
    }
};

export const updateStudentQualities = async (studentId, branchName, sessionName, payload) => {
    try {
        const response = await baseApi.put(`/api/updateStudentQualities/${studentId}?sessionName=${sessionName}&branchName=${branchName}`, payload, {
            headers: {"Content-type": "application/json"}
        });
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return response?.data?.message;
        }
    } catch (e) {
        console.log(e.response);
        return "Something went wrong"; // Return an error message
    }
};
