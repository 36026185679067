export const semesters = [
  {
    name: "First Semester (3-7)",
    value: "1stSemester",
    classes: [
      {
        name: "Three",
        value: "three",
        numeric: 3,
      },
      {
        name: "Four",
        value: "four",
        numeric: 4,
      },
      {
        name: "Five",
        value: "five",
        numeric: 5,
      },
      {
        name: "Six",
        value: "six",
        numeric: 6,
      },
      {
        name: "Seven",
        value: "seven",
        numeric: 7,
      },
    ],
  },
  {
    name: "Second Semester (3-7)",
    value: "2ndSemester",
    classes: [
      {
        name: "Three",
        value: "three",
        numeric: 3,
      },
      {
        name: "Four",
        value: "four",
        numeric: 4,
      },
      {
        name: "Five",
        value: "five",
        numeric: 5,
      },
      {
        name: "Six",
        value: "six",
        numeric: 6,
      },
      {
        name: "Seven",
        value: "seven",
        numeric: 7,
      },
    ],
  },
  {
    name: "Half Yearly (8-9)",
    value: "half_yearly",
    classes: [
      {
        name: "Eight",
        value: "eight",
        numeric: 8,
      },
      {
        name: "Nine",
        value: "nine",
        numeric: 9,
      },
    ],
  },
  {
    name: "Annual (8-9)",
    value: "yearly",
    classes: [
      {
        name: "Eight",
        value: "eight",
        numeric: 8,
      },
      {
        name: "Nine",
        value: "nine",
        numeric: 9,
      },
    ],
  },
  {
    name: "Pre Qualifying (10)",
    value: "pre_qualifying",
    classes: [
      {
        name: "Ten",
        value: "ten",
        numeric: 10,
      },
    ],
  },
  {
    name: "Interim (11-12)",
    value: "interim",
    classes: [
      {
        name: "Eleven",
        value: "eleven",
        numeric: 11,
      },
      {
        name: "Twelve",
        value: "twelve",
        numeric: 12,
      },
    ],
  },
  {
    name: "Qualifying (10-12)",
    value: "qualifying",
    classes: [
      {
        name: "Ten",
        value: "ten",
        numeric: 10,
      },
      {
        name: "Eleven",
        value: "eleven",
        numeric: 11,
      },
      {
        name: "Twelve",
        value: "twelve",
        numeric: 12,
      },
    ],
  },
  {
    name: "Preparation (10-12)",
    value: "preparation",
    classes: [
      {
        name: "Ten",
        value: "ten",
        numeric: 10,
      },
      {
        name: "Eleven",
        value: "eleven",
        numeric: 11,
      },
      {
        name: "Twelve",
        value: "twelve",
        numeric: 12,
      },
    ],
  },
  {
    name: "Pre Cambridge (10-12)",
    value: "pre_cambridge",
    classes: [
      {
        name: "Ten",
        value: "ten",
        numeric: 10,
      },
      {
        name: "Eleven",
        value: "eleven",
        numeric: 11,
      },
      {
        name: "Twelve",
        value: "twelve",
        numeric: 12,
      },
    ],
  },
];
