import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import NotifyContext from "context/NotifyContext";
import { getAllTeacherSchedule } from "network/apiClassSchedule/apiClassSchedule";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import {
  addMarkEntries,
  getFilterWiseExamSchedule,
  getResultsPublishedAndLockedStatus,
  getStudentForMarkEntry,
} from "network/apiMarkEntries/apiMarkEntries";
import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import React from "react";
import {
  Button,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";

const AddMarks = () => {
  const { Notify } = React.useContext(NotifyContext);

  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [allExam, setAllExam] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);

  const [filteredExam, setFilteredExam] = React.useState({});
  const [filteredStudent, setFilteredStudent] = React.useState([]);
  const [rowsData, setRowsData] = React.useState([]);
  const resultDetails = rowsData?.map((x) => {
    return {
      studentId: x?._id,
      written: x?.written,
      practical: x?.practical,
      attendance: x?.attendance,
      viva: x?.attendance,
      presentation: x?.presentation,
      paper1: x?.paper1,
      paper2: x?.paper2,
      paper3: x?.paper3,
    };
  });

  const [isAdded, setIsAdded] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(false);
  const [isPublish, setIsPublish] = React.useState(false);

  const [isProcessComplete, setIsProcessComplete] = React.useState(false);

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const fullMark = Number(filteredExam[name + "FullMark"]);
    const rowsInput = [...filteredStudent];

    if (fullMark < Number(value)) {
      Notify("warning", "Invalidated mark", "Marks");
      evnt.target.value = fullMark;
    } else {
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const currentSession = JSON.parse(localStorage.getItem("session"));

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      setAllBranch(data?.data);
    }

    loadBranch();
  }, []);

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(branchId);
      setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);
      setAllClass(data?.data);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  React.useEffect(() => {
    // async function branchSessionToExamList() {
    //     const data = await getBranchSessionWiseExamList(user?.branch?.branchName, user?.branch?._id, user?.session?.sessionName, user?.session?._id);
    //     setAllExam(data);
    // }
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionClassSectionToExamList();
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  React.useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAllTeacherSchedule(
        user?.branch?.branchName,
        user?.branch?._id,
        currentSession?.name,
        currentSession?.id,
        classId,
        sectionId,
        user?._id
      );
      if (data[0] === undefined) {
        setAllSubject([]);
      } else {
        const output = await data[0]?.saturday
          ?.concat(data[0]?.sunday)
          ?.concat(data[0]?.monday)
          ?.concat(data[0]?.wednesday)
          ?.concat(data[0]?.thursday)
          ?.concat(data[0]?.tuesday)
          ?.concat(data[0]?.friday);
        const ids = await output.map((o) => o.subjectId);
        const filtered = await output.filter(
          ({ subjectId }, index) => !ids.includes(subjectId, index + 1)
        );
        setAllSubject(filtered);
      }
    }
    async function branchSessionAToSubject() {
      const data = await getAssignSubjectForFilter(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId
      );
      setAllSubject(data);
    }
    if (user?.role !== "teacher") {
      branchSessionAToSubject();
    } else {
      if (
        user?.branch?.branchName &&
        user?.branch?._id &&
        currentSession?.name &&
        currentSession?.id &&
        classId &&
        sectionId
      ) {
        branchSessionToSubject();
      }
    }
  }, [sectionId, classId, examId]);

  const handleFilter = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsProcessComplete(false);
      setIsPublish(false);
      setIsLocked(false);
      setIsAdded(false);

      const isPublishAndLocked = await getResultsPublishedAndLockedStatus(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );
      setIsPublish(isPublishAndLocked?.isPublish || false);
      setIsLocked(isPublishAndLocked?.isLocked || false);

      const schedules = await getFilterWiseExamSchedule(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );
      const student = await getStudentForMarkEntry(
        branchName,
        sessionName,
        branchId,
        sessionId,
        classId,
        sectionId
      );

      setFilteredStudent(
        student?.data
          ?.map((x) => {
            return {
              firstName: x?.firstName,
              lastName: x?.lastName,
              roll: Number(x?.roll),
              regNo: x?.regNo,
              _id: x?._id,
            };
          })
          ?.sort((firstItem, secondItem) => firstItem.roll - secondItem.roll)
      );
      setFilteredExam(schedules?.examSchedule[0]);
    } catch (error) {
      Notify("error", error?.response?.data?.message, "Marks");
    } finally {
      await store.dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  const handleSubmit = async () => {
    await store.dispatch(ShowLoader());
    const response = await addMarkEntries(
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      sectionId,
      examId,
      subjectId,
      resultDetails
    );
    if (response?.message === "Successfully Added") {
      Notify("success", response?.message, "Marks");
      setBranchName("");
      setBranchId("");
      setSessionName("");
      setSessionId("");
      setClassName("");
      setClassId("");
      setSectionName("");
      setSectionId("");
      setExamName("");
      setExamId("");
      setSubjectName("");
      setSubjectId("");
      setFilteredExam({});
      setFilteredStudent([]);
      setRowsData([]);
      setIsProcessComplete(false);
      setIsPublish(false);
      setIsLocked(false);
      setIsAdded(false);
      await store.dispatch(HideLoader());
    } else if (response?.message === "Already Added! Please Edit") {
      await store.dispatch(HideLoader());
      Notify("success", response?.message, "Marks");
    } else {
      await store.dispatch(HideLoader());
      Notify("danger", "You made a mistake! Please Check", "Marks");
    }
  };

  function keyPressHandler(e) {
    let fType = e.target.name;
    let isEnter = e.which === 13 ? true : false;
    if (isEnter && fType === "written") {
      let nextId = Number(e.target.id.replace("written_", "")) + 1;
      let nextEl = document.querySelector("#written_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "practical") {
      let nextId = Number(e.target.id.replace("practical_", "")) + 1;
      let nextEl = document.querySelector("#practical_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "attendance") {
      let nextId = Number(e.target.id.replace("attendance_", "")) + 1;
      let nextEl = document.querySelector("#attendance_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "viva") {
      let nextId = Number(e.target.id.replace("viva_", "")) + 1;
      let nextEl = document.querySelector("#viva_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "presentation") {
      let nextId = Number(e.target.id.replace("presentation_", "")) + 1;
      let nextEl = document.querySelector("#presentation_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "paper1") {
      let nextId = Number(e.target.id.replace("paper1_", "")) + 1;
      let nextEl = document.querySelector("#paper1_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "paper2") {
      let nextId = Number(e.target.id.replace("paper2_", "")) + 1;
      let nextEl = document.querySelector("#paper2_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "paper3") {
      let nextId = Number(e.target.id.replace("paper3_", "")) + 1;
      let nextEl = document.querySelector("#paper3_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "phone") {
      let nextId = Number(e.target.id.replace("phone_", "")) + 1;
      let nextEl = document.querySelector("#phone_" + nextId);
      if (nextEl) nextEl.focus();
    }
    if (isEnter && fType === "brand") {
      let nextId = Number(e.target.id.replace("brand_", "")) + 1;
      let nextEl = document.querySelector("#brand_" + nextId);
      if (nextEl) nextEl.focus();
    }
  }

  return (
    <div>
      <section>
        <CardTitle>
          <h3 className="mb-0">
            <i className="ni ni-active-40 text-orange" />
            <span
              className="ml-2"
              style={{ fontSize: "large", color: "orange" }}
            >
              Select Ground
            </span>
            <hr />
          </h3>
        </CardTitle>
        {user?.role === "superAdmin" && (
          <Row>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Branch
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setBranchName(values?.name);
                    setBranchId(values?.id);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option
                    selected={branchName === "" && branchId === "" && true}
                  >
                    Select
                  </option>
                  {allBranch?.map((branch) => (
                    <option
                      value={JSON.stringify({
                        name: branch?.branchName,
                        id: branch?._id,
                      })}
                    >
                      {branch?.branchName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Session
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setSessionName(values?.name);
                    setSessionId(values?.id);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option
                    selected={sessionName === "" && sessionId === "" && true}
                  >
                    Select
                  </option>
                  {allSession?.map((session) => (
                    <option
                      value={JSON.stringify({
                        name: session?.sessionName,
                        id: session?._id,
                      })}
                    >
                      {session?.sessionName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="3" sm="3" lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example4cols1Input"
              >
                Class
              </label>
              <Input
                onChange={(e) => {
                  const values = JSON.parse(e.target.value);
                  setClassName(values?.name);
                  setClassId(values?.id);
                }}
                id="exampleFormControlSelect1"
                type="select"
              >
                <option selected={className === "" && classId === "" && true}>
                  Select
                </option>
                {allClass?.map((cls) => (
                  <option
                    value={JSON.stringify({
                      name: cls?.className,
                      id: cls?._id,
                    })}
                  >
                    {cls?.className}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3" sm="3" lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example4cols1Input"
              >
                Section
              </label>
              <Input
                onChange={(e) => {
                  const values = JSON.parse(e.target.value);
                  setSectionName(values?.name);
                  setSectionId(values?.id);
                }}
                id="exampleFormControlSelect1"
                type="select"
              >
                <option
                  selected={sectionName === "" && sectionId === "" && true}
                >
                  Select
                </option>
                {allSection?.map((section) => (
                  <option
                    value={JSON.stringify({
                      name: section?.sectionName,
                      id: section?._id,
                    })}
                  >
                    {section?.sectionName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3" sm="3" lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example4cols1Input"
              >
                Select Exam
              </label>
              <Input
                onChange={(e) => {
                  const values = JSON.parse(e.target.value);
                  setExamName(values?.name);
                  setExamId(values?.id);
                }}
                id="exampleFormControlSelect1"
                type="select"
              >
                <option selected={examName === "" && examId === "" && true}>
                  Select
                </option>
                {allExam?.map((exam) => (
                  <option
                    value={JSON.stringify({
                      name: exam?.name,
                      id: exam?._id,
                    })}
                  >
                    {exam?.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3" sm="3" lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example4cols1Input"
              >
                Subject
              </label>
              <Input
                onChange={(e) => {
                  const values = JSON.parse(e.target.value);
                  setSubjectName(values?.name);
                  setSubjectId(values?.id);
                }}
                id="exampleFormControlSelect1"
                type="select"
              >
                <option
                  selected={subjectName === "" && subjectId === "" && true}
                >
                  Select
                </option>
                {allSubject?.map((subject) => (
                  <option
                    value={JSON.stringify({
                      name: subject?.subjectName || subject?.label,
                      id: subject?.subjectId || subject?.value,
                    })}
                  >
                    {subject?.subjectName || subject?.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <div className="text-right mt-4">
          <Button onClick={handleFilter} color="info">
            Filter
          </Button>
        </div>
      </section>
      {isProcessComplete && (
        <>
          {Object.keys(filteredExam)?.length > 0 &&
          filteredStudent?.length > 0 &&
          !isPublish &&
          !isLocked &&
          !isAdded ? (
            <div className="mt-4">
              <Container fluid>
                <Table responsive hover bordered>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Roll</th>
                      {filteredExam?.writtenFullMark &&
                        filteredExam?.writtenPassMark && (
                          <th>Written ({filteredExam?.writtenFullMark})</th>
                        )}
                      {filteredExam?.practicalFullMark &&
                        filteredExam?.practicalPassMark && (
                          <th>Practical ({filteredExam?.practicalFullMark})</th>
                        )}
                      {filteredExam?.attendanceFullMark &&
                        filteredExam?.attendancePassMark && (
                          <th>
                            Attendance ({filteredExam?.attendanceFullMark})
                          </th>
                        )}
                      {filteredExam?.vivaFullMark &&
                        filteredExam?.vivaPassMark && (
                          <th>Viva ({filteredExam?.vivaFullMark})</th>
                        )}
                      {filteredExam?.presentationFullMark &&
                        filteredExam?.presentationPassMark && (
                          <th>
                            Presentation ({filteredExam?.presentationFullMark})
                          </th>
                        )}
                      {filteredExam?.paper1FullMark &&
                        filteredExam?.paper1PassMark && (
                          <th>Paper 1 ({filteredExam?.paper1FullMark})</th>
                        )}
                      {filteredExam?.paper2FullMark &&
                        filteredExam?.paper2PassMark && (
                          <th>Paper 2 ({filteredExam?.paper2FullMark})</th>
                        )}
                      {filteredExam?.paper3FullMark &&
                        filteredExam?.paper3PassMark && (
                          <th>Paper 3 ({filteredExam?.paper3FullMark})</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStudent?.map((x, index) => (
                      <tr>
                        <td>
                          {x?.firstName} {x?.lastName}
                        </td>
                        <td>{x?.roll}</td>
                        {filteredExam?.writtenFullMark &&
                          filteredExam?.writtenPassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"written_" + index}
                                  type="number"
                                  name="written"
                                  min={0}
                                  max={Number(
                                    filteredExam?.writtenFullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.practicalFullMark &&
                          filteredExam?.practicalPassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"practical_" + index}
                                  type="number"
                                  name="practical"
                                  min={0}
                                  max={Number(
                                    filteredExam?.practicalFullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.attendanceFullMark &&
                          filteredExam?.attendancePassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"attendance_" + index}
                                  type="number"
                                  name="attendance"
                                  min={0}
                                  max={Number(
                                    filteredExam?.attendanceFullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.vivaFullMark &&
                          filteredExam?.vivaPassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"viva_" + index}
                                  type="number"
                                  name="viva"
                                  min={0}
                                  max={Number(filteredExam?.vivaFullMark || 0)}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.presentationFullMark &&
                          filteredExam?.presentationPassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"presentation_" + index}
                                  type="number"
                                  name="presentation"
                                  min={0}
                                  max={Number(
                                    filteredExam?.presentationFullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.paper1FullMark &&
                          filteredExam?.paper1PassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"paper1_" + index}
                                  type="number"
                                  name="paper1"
                                  min={0}
                                  max={Number(
                                    filteredExam?.paper1FullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.paper2FullMark &&
                          filteredExam?.paper2PassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"paper2_" + index}
                                  type="number"
                                  name="paper2"
                                  min={0}
                                  max={Number(
                                    filteredExam?.paper2FullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                        {filteredExam?.paper3FullMark &&
                          filteredExam?.paper3PassMark && (
                            <td>
                              <FormGroup>
                                <Input
                                  onWheel={() => document.activeElement.blur()}
                                  id={"paper3_" + index}
                                  type="number"
                                  name="paper3"
                                  min={0}
                                  max={Number(
                                    filteredExam?.paper3FullMark || 0
                                  )}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  onKeyPress={keyPressHandler}
                                />
                              </FormGroup>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
              <div className="text-right mt-4">
                <Button onClick={handleSubmit} color="success">
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center mt-4">
              {isLocked ? (
                <h3>Mark is locked</h3>
              ) : isPublish ? (
                <h3>Result is Already Published, Please Try to Edit</h3>
              ) : isAdded ? (
                <h3>Mark Already Added, Please Try to Edit</h3>
              ) : (
                <h3>No Data Found</h3>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddMarks;
