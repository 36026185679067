import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage, isSupported} from "firebase/messaging";

let firebaseConfig = {
    apiKey: "AIzaSyCmQdmRTmTeLXUMVHt6n1mtLDAk6LeVcJ0",
    authDomain: "st-joseph-bb1e3.firebaseapp.com",
    projectId: "st-joseph-bb1e3",
    storageBucket: "st-joseph-bb1e3.appspot.com",
    messagingSenderId: "1084036202352",
    appId: "1:1084036202352:web:c140354ad50428254699b5"
};

const isBrowserSupport = isSupported().then(data => data).catch(e => console.log(e));

const firebaseApp = isBrowserSupport ? initializeApp(firebaseConfig) : null;
const messaging = isBrowserSupport ? getMessaging(firebaseApp) : null;

export const getTokenFinal = (setTokenFound, setCurrentToken) => {
    return getToken(messaging, {vapidKey: 'BES94YefPvWneU8M0NNa3Mae3KgcjarEmLda6JbPWdGCg_LApnZhEGyoqSlgtoKiio2LUX-MHKJlN8-9o1dIWMs'}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            setCurrentToken(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            setCurrentToken("");
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log(payload);
            resolve(payload);
        });
    });
