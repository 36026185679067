import baseApi from "../../api/baseApi";

export const addClassSchedule = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  className,
  classId,
  sectionName,
  sectionId,
  editDay,
  scheduleDetails
) => {
  const payload = {
    branchName: branchName,
    branchId: branchId,
    sessionName: sessionName,
    sessionId: sessionId,
    className: className,
    classId: classId,
    sectionName: sectionName,
    sectionId: sectionId,
    ...(editDay === "saturday" && { saturday: scheduleDetails }),
    ...(editDay === "sunday" && { sunday: scheduleDetails }),
    ...(editDay === "monday" && { monday: scheduleDetails }),
    ...(editDay === "tuesday" && { tuesday: scheduleDetails }),
    ...(editDay === "wednesday" && { wednesday: scheduleDetails }),
    ...(editDay === "thursday" && { thursday: scheduleDetails }),
    ...(editDay === "friday" && { friday: scheduleDetails }),
    ...(editDay === "saturday" && { saturday: scheduleDetails }),
  };
  try {
    const response = await baseApi.post(`/api/addClassSchedule`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateOneAssignSubject = async (
  id,
  branchName,
  branchId,
  sessionName,
  sessionId,
  className,
  classId,
  classNumeric,
  sectionName,
  sectionId,
  subjects
) => {
  const payload = {
    branchName: branchName,
    branchId: branchId,
    sessionName: sessionName,
    sessionId: sessionId,
    className: className,
    classId: classId,
    classNumeric: classNumeric,
    sectionName: sectionName,
    sectionId: sectionId,
    subjects: subjects,
  };
  try {
    const response = await baseApi.put(
      `/api/updateOneAssignSubject/${id}`,
      payload,
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getAllClassSchedule = async (
  page,
  showPerPage,
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getClassScheduleList?page=${page}&limit=${showPerPage}&branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getAllTeacherSchedule = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  teacherId
) => {
  try {
    const response = await baseApi.get(
      `/api/getTeacherScheduleList?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&teacherId=${teacherId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getCurrentDayClassSchedule = async (
  branchName,
  branchId,
  currentDay,
  teacher
) => {
  try {
    const response = await baseApi.get(
      `/api/getClassScheduleByDay?branchName=${branchName}&branchId=${branchId}&day=${currentDay}&teacher=${teacher}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};
