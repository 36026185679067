import React from "react";
import Login from "views/pages/examples/Login.js";
import ExamResult from "views/resultMark/examResult/ExamResult";
import ExamResultAdmin from "views/resultMark/examResult/ExamResultAdmin";
import ExamResultStudent from "views/resultMark/examResult/ExamResultStudent";
import MarkSheet from "views/resultMark/markSheet/MarkSheet";
import PublishResult from "views/resultMark/publishResult/PublishResult";
import StudentListForQualities from "views/studentQualities/StudentListForQualities";
import TabulationPreCambridge from "views/tabulation/(10)/TabulationPreCambridge";
import TabulationPreparation from "views/tabulation/(10)/TabulationPreparation";
import TabulationPreQualification from "views/tabulation/(10)/TabulationPreQualification";
import TabulationQualification from "views/tabulation/(10)/TabulationQualification";
import TabulationInterim_11_12 from "views/tabulation/(11-12)/TabulationInterim";
import TabulationPreCambridge_11_12 from "views/tabulation/(11-12)/TabulationPreCambridge";
import TabulationPreparation_11_12 from "views/tabulation/(11-12)/TabulationPreparation";
import TabulationQualification_11_12 from "views/tabulation/(11-12)/TabulationQualification";
import TabulationExam from "views/tabulation/(3-7)/TabulationExam";
import TabulationSemester from "views/tabulation/(3-7)/TabulationSemester";
import TabulationHalfYearlyNew from "views/tabulation/(8-9)/TabulationHalfYearlyNew";
import TabulationYearlyNew from "views/tabulation/(8-9)/TabulationYearlyNew";

const NewScheduleList = React.lazy(() =>
  import("./views/examSchedule/NewSchedule/NewScheduleList")
);
const AcademicAttachmentList = React.lazy(() =>
  import("./views/acamedicAttachments/uploadContent/AcademicAttachmentList")
);
const AttachmentTypeList = React.lazy(() =>
  import("./views/acamedicAttachments/attachmentType/AttachmentTypeList")
);
const Promotion = React.lazy(() =>
  import("./views/pages/academic/promotion/Promotion")
);
const TeacherScheduleList = React.lazy(() =>
  import("./views/teacherSchedule/TeacherScheduleList")
);
const ClassScheduleList = React.lazy(() =>
  import("./views/classSchedule/ClassScheduleList")
);
const ListEmployees = React.lazy(() =>
  import("./views/employees/listEmployees/ListEmployees")
);
const AddEmployees = React.lazy(() =>
  import("./views/employees/addEmployees/AddEmployees")
);
const DepartmentList = React.lazy(() =>
  import("./views/employees/department/DepartmentList")
);
const DesignationList = React.lazy(() =>
  import("./views/employees/designation/DesignationList")
);
const SubjectAssign = React.lazy(() =>
  import("./views/pages/academic/subjectAssign/SubjectAssign")
);
const SubjectList = React.lazy(() => import("./views/subject/SubjectList"));
const StudentList = React.lazy(() =>
  import("./views/studentProfile/StudentList")
);
const GroupSetting = React.lazy(() =>
  import("./views/pages/groupSetting/GroupSetting")
);
const AddStudent = React.lazy(() => import("./views/admission/AddStudent"));
const ManageSession = React.lazy(() =>
  import("./views/pages/session/ManageSession")
);
const Branch = React.lazy(() => import("./views/pages/branch/Branch"));
const SectionSetting = React.lazy(() =>
  import("./views/pages/academic/sectionSetting/SectionSetting")
);
const ClassSetting = React.lazy(() =>
  import("./views/pages/academic/classSetting/ClassSetting")
);
const Dashboard = React.lazy(() =>
  import("views/pages/dashboards/Dashboard.js")
);
const Calendar = React.lazy(() => import("views/pages/Calendar.js"));
const AllEventList = React.lazy(() =>
  import("./views/events/allEvent/AllEventList")
);
const EventTypeList = React.lazy(() =>
  import("./views/events/eventType/EventTypeList")
);
const ExpenseList = React.lazy(() =>
  import("./views/officeAccounting/expense/ExpenseList")
);
const DepositList = React.lazy(() =>
  import("./views/officeAccounting/deposit/DepositList")
);
const VoucherHeadList = React.lazy(() =>
  import("./views/officeAccounting/voucherHead/VoucherHeadList")
);
const AccountList = React.lazy(() =>
  import("./views/officeAccounting/account/AccountList")
);
const StudentFeesReport = React.lazy(() =>
  import("./views/reports/StudentFeesReport")
);
const FeesPayList = React.lazy(() =>
  import("./views/pages/studentAccounting/feesPay/FeesPayList")
);
const StudentFeesSetupList = React.lazy(() =>
  import("./views/pages/studentAccounting/feesSetup/StudentFeesSetupList")
);
const FeesTypeList = React.lazy(() =>
  import("./views/pages/studentAccounting/feesType/FeesTypeList")
);
const ExamScheduleList = React.lazy(() =>
  import("./views/examSchedule/ExamScheduleList")
);
const ExamList = React.lazy(() =>
  import("./views/examSetup/examList/ExamList")
);
const MarkDistributionList = React.lazy(() =>
  import("./views/examSetup/markDistribution/MarkDistributionList")
);
const ExamHallList = React.lazy(() =>
  import("./views/examSetup/examHall/ExamHallList")
);
const AddEmployeeAttendance = React.lazy(() =>
  import("./views/attendance/employee/AddEmployeeAttendance")
);
const AddStudentAttendance = React.lazy(() =>
  import("./views/attendance/student/AddStudentAttendance")
);
const StudentSubject = React.lazy(() =>
  import("./views/pages/academic/studentSubject/StudentSubject")
);
const ActiveSession = React.lazy(() =>
  import("./views/activeSession/ActiveSession")
);
const HomeworkEvulation = React.lazy(() =>
  import("./views/homeWork/homeWorkEvulation/HomeworkEvulation")
);
const HomeWorkList = React.lazy(() =>
  import("./views/homeWork/createHomeWork/HomeWorkList")
);
const MyIssuedList = React.lazy(() =>
  import("./views/library/myIssuedBooks/MyIssuedList")
);
const BooksStatus = React.lazy(() =>
  import("./views/library/libraryStatus/BooksStatus")
);
const BooksCategoryList = React.lazy(() =>
  import("./views/library/booksCategory/BooksCategoryList")
);
const BooksList = React.lazy(() => import("./views/library/books/BooksList"));
const GenerateAdmitCard = React.lazy(() =>
  import("./views/cardManagement/GenerateAdmitCard/GenerateAdmitCard")
);
const AdmitCardTemplate = React.lazy(() =>
  import("./views/cardManagement/AdmitCardTemplate/AdmitCardTemplate")
);
const EmployeeIdCard = React.lazy(() =>
  import("./views/cardManagement/EmployeeIdCard/EmployeeIdCard")
);
const StudentIdCard = React.lazy(() =>
  import("./views/cardManagement/StudentIdCard/StudentIdCard")
);
const IdCardTemplate = React.lazy(() =>
  import("./views/cardManagement/IdCardTemplate/IdCardTemplate")
);
const GenerateEmployee = React.lazy(() =>
  import("./views/certificate/generateEmployee/GenerateEmployee")
);
const GenerateStudent = React.lazy(() =>
  import("./views/certificate/generateStudent/GenerateStudent")
);
const CertificateTemplate = React.lazy(() =>
  import("./views/certificate/certificateTemplate/CertificateTemplate")
);
const IncomeVsExpense = React.lazy(() =>
  import("./views/financialReport/IncomeVsExpense")
);
const BulkImport = React.lazy(() => import("./views/admission/BulkImport"));
const TeacherScheduleForTeacher = React.lazy(() =>
  import("./views/teacherSchedule/TeacherScheduleForTeacher")
);
const EmployeeEvents = React.lazy(() =>
  import("./views/events/allEvent/EmployeeEvents")
);
const StudentEvents = React.lazy(() =>
  import("./views/events/allEvent/StudentEvents")
);
const BranchWiseBookList = React.lazy(() =>
  import("./views/library/books/BranchWiseBookList")
);
const IncomeReport = React.lazy(() =>
  import("./views/financialReport/IncomeReport")
);
const ExpenseReport = React.lazy(() =>
  import("./views/financialReport/expenseReport/ExpenseReport")
);
const EmployeeAttendanceReport = React.lazy(() =>
  import("./views/attendance/employee/EmployeeAttendanceReport")
);
const FeesReportList = React.lazy(() =>
  import("./views/pages/studentAccounting/studentView/FeesReportList")
);
const SpecificStudentAttendance = React.lazy(() =>
  import("./views/attendance/student/SpecificStudentAttendance")
);
const StudentAttendanceReport = React.lazy(() =>
  import("./views/attendance/student/StudentAttendanceReport")
);
const StudentExamSchedule = React.lazy(() =>
  import("./views/examSchedule/StudentExamSchedule")
);
const HomeworkEvaluationStudent = React.lazy(() =>
  import("./views/homeWork/homeWorkEvulation/HomeworkEvaluationStudent")
);
const AttachmentBook = React.lazy(() =>
  import("./views/acamedicAttachments/attachmentBook/AttachmentBook")
);
const StudentClassSchedule = React.lazy(() =>
  import("./views/classSchedule/StudentClassSchedule")
);
const AdmissionFeesCollection = React.lazy(() =>
  import(
    "./views/pages/studentAccounting/admissionFees/AdmissionFeesCollection"
  )
);
const PeriodList = React.lazy(() => import("./views/period/PeriodList"));
const StudentAssignedSubject = React.lazy(() =>
  import("./views/pages/academic/subjectAssign/StudentAssignedSubject")
);
const DueList = React.lazy(() => import("./views/reports/DueList"));
const HeadWiseSummary = React.lazy(() =>
  import("./views/reports/HeadWiseSummary")
);
const PaymentSummary = React.lazy(() =>
  import("./views/reports/PaymentSummary")
);
const CollectionReport = React.lazy(() =>
  import("./views/reports/CollectionReport")
);
const SpecialFeesList = React.lazy(() =>
  import("./views/pages/studentAccounting/specialFees/SpecialFeesList")
);
const FeesPayListAccountant = React.lazy(() =>
  import("./views/pages/studentAccounting/feesPay/FeesPayListAccountant")
);
const SearchProfile = React.lazy(() =>
  import("./views/studentProfile/SearchProfile")
);
const HomeWorkStudent = React.lazy(() =>
  import("./views/homeWork/createHomeWork/HomeWorkStudent")
);
const OnlineAdmission = React.lazy(() =>
  import("./views/admission/onlineAdmission/OnlineAdmission")
);
const AdmissionFeesList = React.lazy(() =>
  import("./views/admissionFees/AdmissionFeesList")
);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin",
    invisible: ["librarian", "technicalSupport", "admissionSupport"],
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    invisible: [
      "student",
      "teacher",
      "admin",
      "superAdmin",
      "accountant",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
  },
  {
    path: "/branch",
    name: "Branch",
    icon: "ni ni-tie-bow text-green",
    component: Branch,
    layout: "/admin",
    invisible: [
      "student",
      "teacher",
      "accountant",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
  },
  {
    collapse: true,
    name: "Academic",
    icon: "ni ni-credit-card text-primary",
    state: "academicCollapse",
    invisible: ["accountant", "librarian"],
    views: [
      {
        path: "/manageSession",
        name: "- Session",
        miniName: "S",
        component: ManageSession,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "technicalSupport",
          "academicSupport",
        ],
      },
      {
        path: "/classSetting",
        name: "- Class",
        miniName: "C",
        component: ClassSetting,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/sectionSetting",
        name: "- Section",
        miniName: "S",
        component: SectionSetting,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/manageGroup",
        name: "- Group",
        miniName: "G",
        component: GroupSetting,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/manageSubject",
        name: "- Subject",
        miniName: "S",
        component: SubjectList,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/manageStudentSubject",
        name: "- Subject List",
        miniName: "S",
        component: StudentSubject,
        layout: "/admin",
        invisible: ["teacher", "superAdmin", "student", "academicSupport"],
      },
      {
        path: "/assignSubject",
        name: "- Assigned Subjects",
        miniName: "S",
        component: SubjectAssign,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/assignSubjectStudents",
        name: "- Assigned Subjects",
        miniName: "S",
        component: StudentAssignedSubject,
        layout: "/admin",
        invisible: ["teacher", "superAdmin", "admin", "academicSupport"],
      },
      {
        path: "/classSchedule",
        name: "- Class Schedule",
        miniName: "S",
        component: ClassScheduleList,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/studentClassSchedule",
        name: "- Class Schedule",
        miniName: "S",
        component: StudentClassSchedule,
        layout: "/admin",
        invisible: ["superAdmin", "teacher", "academicSupport", "admin"],
      },
      {
        path: "/teacherClassSchedule",
        name: "- Class Schedule",
        miniName: "S",
        component: TeacherScheduleForTeacher,
        layout: "/admin",
        invisible: ["superAdmin", "student", "academicSupport", "admin"],
      },
      {
        path: "/teacherSchedule",
        name: "- Teacher Schedule",
        miniName: "S",
        component: TeacherScheduleList,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/studentPromotion",
        name: "- Student Promotion",
        miniName: "P",
        component: Promotion,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
    ],
  },
  {
    collapse: true,
    name: "Academic Attachment",
    icon: "ni ni-archive-2 text-green",
    state: "attachmentCollapse",
    invisible: [
      "accountant",
      "librarian",
      "technicalSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/attachmentType",
        name: "Attachment Type",
        miniName: "T",
        component: AttachmentTypeList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/uploadContent",
        name: "Upload Content",
        miniName: "C",
        component: AcademicAttachmentList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/attachmentBooks",
        name: "Attachment List",
        miniName: "A",
        component: AttachmentBook,
        layout: "/admin",
        invisible: ["teacher", "superAdmin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Employees",
    icon: "ni ni-user-run text-red",
    state: "employeesCollapse",
    invisible: [
      "student",
      "teacher",
      "accountant",
      "librarian",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/listEmployees",
        name: "List Employees",
        miniName: "L",
        component: ListEmployees,
        layout: "/admin",
      },
      {
        path: "/addDesignation",
        name: "Add Designation",
        miniName: "S",
        component: DesignationList,
        layout: "/admin",
      },
      {
        path: "/addDepartment",
        name: "Add Department",
        miniName: "S",
        component: DepartmentList,
        layout: "/admin",
      },
      {
        path: "/addEmployee",
        name: "Add Employee",
        miniName: "S",
        component: AddEmployees,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Admission",
    icon: "ni ni-paper-diploma text-purple",
    state: "admissionCollapse",
    invisible: ["student", "teacher", "accountant", "librarian"],
    views: [
      {
        path: "/addStudent",
        name: "Add Student",
        miniName: "S",
        component: AddStudent,
        layout: "/admin",
      },
      {
        path: "/bulkImport",
        name: "Bulk Import",
        miniName: "I",
        component: BulkImport,
        layout: "/admin",
      },
      {
        path: "/onlineAdmission",
        name: "Online Admission",
        miniName: "O",
        component: OnlineAdmission,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Student Details",
    icon: "ni ni-hat-3 text-orange",
    state: "studentCollapse",
    invisible: ["technicalSupport", "admissionSupport", "librarian"],
    views: [
      {
        path: "/view",
        name: "Student List",
        miniName: "S",
        component: StudentList,
        layout: "/admin",
        invisible: [
          "technicalSupport",
          "admissionSupport",
          "student",
          "librarian",
        ],
      },
      {
        path: "/searchView",
        name: "Student Profile",
        miniName: "S",
        component: SearchProfile,
        layout: "/admin",
        invisible: [
          "teacher",
          "admin",
          "technicalSupport",
          "admissionSupport",
          "superAdmin",
          "accountant",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Card Management",
    icon: "ni ni-badge text-blue ",
    state: "cardManagementCollapse",
    invisible: [
      "student",
      "teacher",
      "accountant",
      "librarian",
      "academicSupport",
      "superAdmin",
    ],
    views: [
      {
        path: "/id-card-template",
        name: "ID Card Template",
        miniName: "ID",
        component: IdCardTemplate,
        layout: "/admin",
      },
      {
        path: "/student-id-card",
        name: "Student ID Card",
        miniName: "SID",
        component: StudentIdCard,
        layout: "/admin",
      },
      {
        path: "/employee-id-card",
        name: "Employee ID Card",
        miniName: "EID",
        component: EmployeeIdCard,
        layout: "/admin",
      },
      {
        path: "/admit-card-template",
        name: "Admit Card Template",
        miniName: "ADT",
        component: AdmitCardTemplate,
        layout: "/admin",
      },
      {
        path: "/generate-admit-card",
        name: "Generate Admit Card",
        miniName: "GAC",
        component: GenerateAdmitCard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Certificate",
    icon: "ni ni-paper-diploma text-danger",
    state: "certificateCollapse",
    invisible: [
      "student",
      "teacher",
      "accountant",
      "librarian",
      "academicSupport",
      "superAdmin",
    ],
    views: [
      {
        path: "/certificate-template",
        name: "Certificate Template",
        miniName: "CT",
        component: CertificateTemplate,
        layout: "/admin",
      },
      {
        path: "/generate-student",
        name: "Generate Student",
        miniName: "GS",
        component: GenerateStudent,
        layout: "/admin",
      },
      {
        path: "/Generate-employee",
        name: "Generate Employee",
        miniName: "GE",
        component: GenerateEmployee,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Attendance",
    icon: "ni ni-square-pin text-blue",
    state: "attendanceCollapse",
    views: [
      {
        path: "/studentAttendance",
        name: "Student",
        miniName: "S",
        component: AddStudentAttendance,
        layout: "/admin",
        invisible: [
          "student",
          "accountant",
          "librarian",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/attendanceReport",
        name: "Student Attendance",
        miniName: "S",
        component: SpecificStudentAttendance,
        layout: "/admin",
        invisible: [
          "teacher",
          "accountant",
          "librarian",
          "academicSupport",
          "admissionSupport",
          "superAdmin",
        ],
      },
      {
        path: "/employeeAttendance",
        name: "Employee",
        miniName: "S",
        component: AddEmployeeAttendance,
        layout: "/admin",
        invisible: ["student"],
      },
    ],
  },
  {
    collapse: true,
    name: "Exam Master",
    icon: "ni ni-building text-primary",
    state: "examMasterCollapse",
    invisible: [
      "student",
      "accountant",
      "librarian",
      "admissionSupport",
      "teacher",
    ],
    views: [
      {
        path: "/examHall",
        name: "Exam Hall",
        miniName: "E",
        component: ExamHallList,
        invisible: ["teacher", "superAdmin", "academicSupport", "admin"],
        layout: "/admin",
      },
      {
        path: "/markDistribution",
        name: "Mark Distribution",
        miniName: "D",
        component: MarkDistributionList,
        invisible: ["teacher", "superAdmin", "academicSupport", "admin"],
        layout: "/admin",
      },
      {
        path: "/examList",
        name: "Exam Setup",
        miniName: "E",
        component: ExamList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Exam Schedule",
    icon: "ni ni-watch-time text-red",
    state: "examScheduleCollapse",
    invisible: ["accountant", "librarian", "admissionSupport", "teacher"],
    views: [
      {
        path: "/examSchedule",
        name: "Schedule (3-7)",
        miniName: "S",
        component: ExamScheduleList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/exam_schedule_8_12",
        name: "Schedule (8-12)",
        miniName: "S",
        component: NewScheduleList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/studentExamSchedule",
        name: "Exam Schedule",
        miniName: "S",
        component: StudentExamSchedule,
        layout: "/admin",
        invisible: ["teacher", "superAdmin", "admin", "academicSupport"],
      },
    ],
  },
  {
    collapse: true,
    name: "Exam Result",
    icon: "ni ni-bulb-61 text-purple",
    state: "examResultCollapse",
    invisible: ["accountant", "librarian", "admissionSupport"],
    views: [
      {
        path: "/markSheet",
        name: "Mark Sheet",
        miniName: "M",
        component: MarkSheet,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/examResult",
        name: "Exam Result",
        miniName: "S",
        component: ExamResult,
        layout: "/admin",
        invisible: ["student", "superAdmin", "admin", "academicSupport"],
      },
      {
        path: "/examResult_admin",
        name: "Exam Result",
        miniName: "S",
        component: ExamResultAdmin,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
      {
        path: "/examResult_student",
        name: "Exam Result",
        miniName: "S",
        component: ExamResultStudent,
        layout: "/admin",
        invisible: ["teacher", "superAdmin", "academicSupport", "admin"],
      },
      {
        path: "/publishResult",
        name: "Publish Result",
        miniName: "P",
        component: PublishResult,
        layout: "/admin",
        invisible: ["student", "teacher"],
      },
    ],
  },
  {
    collapse: true,
    name: "Student Qualities",
    icon: "ni ni-bulb-61 text-orange",
    state: "studentQualitiesCollapse",
    invisible: ["student", "accountant", "librarian", "admissionSupport"],
    views: [
      {
        path: "/student-qualities",
        name: "Student Qualities",
        miniName: "S",
        component: StudentListForQualities,
        layout: "/admin",
        invisible: ["student", "accountant", "librarian", "admissionSupport"],
      },
    ],
  },
  {
    collapse: true,
    name: "Tabulation Sheet",
    icon: "ni ni-diamond text-blue",
    state: "tabulationCollapse",
    invisible: ["accountant", "librarian", "admissionSupport", "student"],
    views: [
      {
        collapse: true,
        name: "Tabulation Sheet (3-7)",
        state: "tabulationCollapse_3_7",
        invisible: ["accountant", "librarian", "admissionSupport", "student"],
        views: [
          {
            path: "/exam_tabulation_3_7",
            name: "Exam Tabulation (3-7)",
            miniName: "T",
            component: TabulationExam,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/semester_tabulation_3_7",
            name: "Semester Tabulation (3-7)",
            miniName: "T",
            component: TabulationSemester,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
        ],
      },
      {
        collapse: true,
        name: "Tabulation Sheet (8-9)",
        state: "tabulationCollapse_8_9",
        invisible: ["accountant", "librarian", "admissionSupport", "student"],
        views: [
          {
            path: "/half_yearly_tabulation_8_9",
            name: "Half Yearly (8-9)",
            miniName: "T",
            component: TabulationHalfYearlyNew,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/yearly_tabulation_8_9",
            name: "Annual (8-9)",
            miniName: "T",
            component: TabulationYearlyNew,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
        ],
      },
      {
        collapse: true,
        name: "Tabulation Sheet (10)",
        state: "tabulationCollapse_10",
        invisible: ["accountant", "librarian", "admissionSupport", "student"],
        views: [
          {
            path: "/pre_qualification_tabulation_10",
            name: "Pre Qualification (10)",
            miniName: "T",
            component: TabulationPreQualification,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/qualification_tabulation_10",
            name: "Qualification (10)",
            miniName: "T",
            component: TabulationQualification,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/pre_cambridge_tabulation_10",
            name: "Pre-Cambridge (10)",
            miniName: "T",
            component: TabulationPreCambridge,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/preparation_tabulation_10",
            name: "Preparation (10)",
            miniName: "T",
            component: TabulationPreparation,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
        ],
      },
      {
        collapse: true,
        name: "Tabulation Sheet (11-12)",
        state: "tabulationCollapse_11_12",
        invisible: ["accountant", "librarian", "admissionSupport", "student"],
        views: [
          {
            path: "/interim_tabulation_11_12",
            name: "Interim (11-12)",
            miniName: "T",
            component: TabulationInterim_11_12,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/qualification_tabulation_11_12",
            name: "Qualification (11-12)",
            miniName: "T",
            component: TabulationQualification_11_12,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/pre_cambridge_tabulation_11_12",
            name: "Pre-Cambridge (11-12)",
            miniName: "T",
            component: TabulationPreCambridge_11_12,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
          {
            path: "/preparation_tabulation_11_12",
            name: "Preparation (11-12)",
            miniName: "T",
            component: TabulationPreparation_11_12,
            layout: "/admin",
            invisible: ["student", "teacher"],
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Library",
    icon: "ni ni-single-copy-04 text-orange",
    state: "libraryCollapse",
    invisible: ["teacher", "accountant"],
    views: [
      {
        path: "/books",
        name: "Books",
        miniName: "S",
        component: BooksList,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "accountant",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/branchWiseBooks",
        name: "Books",
        miniName: "S",
        component: BranchWiseBookList,
        layout: "/admin",
        invisible: ["superAdmin"],
      },
      {
        path: "/booksCategory",
        name: "Books Category",
        miniName: "S",
        component: BooksCategoryList,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "accountant",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/booksMyIssue",
        name: "My Issues",
        miniName: "S",
        component: MyIssuedList,
        layout: "/admin",
      },
      {
        path: "/booksStatus",
        name: "Books Status",
        miniName: "S",
        component: BooksStatus,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "accountant",
          "academicSupport",
          "admissionSupport",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Home Work",
    icon: "ni ni-vector text-blue",
    state: "homeWorkCollapse",
    invisible: [
      "accountant",
      "librarian",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/homeWork",
        name: "Homework",
        miniName: "S",
        component: HomeWorkList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/homeWorkList",
        name: "Homework List",
        miniName: "S",
        component: HomeWorkStudent,
        layout: "/admin",
        invisible: ["teacher", "superAdmin"],
      },
      {
        path: "/homeWorkStudent",
        name: "Homework Report",
        miniName: "S",
        component: HomeworkEvaluationStudent,
        layout: "/admin",
        invisible: ["teacher", "superAdmin"],
      },
      {
        path: "/homeworkEvaluation",
        name: "Homework Evaluation",
        miniName: "S",
        component: HomeworkEvulation,
        layout: "/admin",
        invisible: ["student"],
      },
    ],
  },
  {
    collapse: true,
    name: "Student Accounting",
    icon: "ni ni-money-coins text-blue",
    state: "studentAccountingCollapse",
    invisible: [
      "student",
      "teacher",
      "admin",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/feesType",
        name: "Fees Type",
        miniName: "S",
        component: FeesTypeList,
        layout: "/admin",
        invisible: [
          "teacher",
          "admin",
          "accountant",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
          "superAdmin",
        ],
      },
      {
        path: "/studentFeesSetup",
        name: "Fees Setup",
        miniName: "S",
        component: StudentFeesSetupList,
        layout: "/admin",
      },
      {
        path: "/specialFeesSetup",
        name: "Special Fees",
        miniName: "S",
        component: SpecialFeesList,
        layout: "/admin",
      },
      {
        path: "/studentFeesCollection",
        name: "Pay Fees Old",
        miniName: "S",
        component: FeesPayList,
        layout: "/admin",
        invisible: [
          "teacher",
          "admin",
          "accountant",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
          "superAdmin",
        ],
      },
      {
        path: "/studentFeesCashier",
        name: "Pay Fees",
        miniName: "S",
        component: FeesPayListAccountant,
        layout: "/admin",
      },
      {
        path: "/admissionFeesCollection",
        name: "Admission Fees",
        miniName: "A",
        component: AdmissionFeesCollection,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/studentFeesSummary",
    name: "Fees Summary",
    icon: "ni ni-money-coins text-blue",
    component: FeesReportList,
    layout: "/admin",
    invisible: [
      "teacher",
      "admin",
      "accountant",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
      "superAdmin",
    ],
  },
  {
    collapse: true,
    name: "Office Accounting",
    icon: "ni ni-money-coins text-red",
    state: "officeAccountingCollapse",
    invisible: [
      "student",
      "teacher",
      "admin",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/accountsLists",
        name: "Account",
        miniName: "S",
        component: AccountList,
        layout: "/admin",
      },
      {
        path: "/voucherHeads",
        name: "Voucher Head",
        miniName: "V",
        component: VoucherHeadList,
        layout: "/admin",
      },
      {
        path: "/deposits",
        name: "New Deposit",
        miniName: "V",
        component: DepositList,
        layout: "/admin",
      },
      {
        path: "/expenses",
        name: "New Expense",
        miniName: "E",
        component: ExpenseList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Notice & Event",
    icon: "ni ni-notification-70 text-primary",
    state: "eventCollapse",
    views: [
      {
        path: "/eventType",
        name: "Notice / Event Type",
        miniName: "S",
        component: EventTypeList,
        layout: "/admin",
        invisible: [
          "student",
          "accountant",
          "librarian",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/studentEvents",
        name: "Notices",
        miniName: "S",
        component: StudentEvents,
        layout: "/admin",
        invisible: [
          "teacher",
          "accountant",
          "librarian",
          "academicSupport",
          "admissionSupport",
          "superAdmin",
          "admin",
        ],
      },
      {
        path: "/events",
        name: "Notices",
        miniName: "S",
        component: AllEventList,
        layout: "/admin",
        invisible: [
          "student",
          "accountant",
          "librarian",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/employeeEvents",
        name: "My Notice",
        miniName: "S",
        component: EmployeeEvents,
        layout: "/admin",
        invisible: ["student", "superAdmin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    icon: "ni ni-ui-04 text-purple",
    state: "reportsCollapse",
    invisible: ["student"],
    views: [
      {
        path: "/studentFeesReport",
        name: "Student Fees",
        miniName: "S",
        component: StudentFeesReport,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "admin",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/feesCollectionReport",
        name: "Collection Summary",
        miniName: "S",
        component: CollectionReport,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "admin",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/paymentSummary",
        name: "Payment Summary",
        miniName: "S",
        component: PaymentSummary,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "admin",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/headWiseSummary",
        name: "Head Wise Summary",
        miniName: "S",
        component: HeadWiseSummary,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "admin",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/dueListSummary",
        name: "Due List",
        miniName: "D",
        component: DueList,
        layout: "/admin",
        invisible: [
          "student",
          "teacher",
          "admin",
          "librarian",
          "technicalSupport",
          "academicSupport",
          "admissionSupport",
        ],
      },
      {
        path: "/studentAttendanceReport",
        name: "Student Attendance",
        miniName: "S",
        component: StudentAttendanceReport,
        layout: "/admin",
        invisible: ["student", "accountant", "librarian", "admissionSupport"],
      },
      {
        path: "/employeeAttendanceReport",
        name: "Employee Attendance",
        miniName: "S",
        component: EmployeeAttendanceReport,
        layout: "/admin",
        invisible: ["student"],
      },
    ],
  },
  {
    collapse: true,
    name: "Financial Report",
    icon: "ni ni-money-coins text-pink",
    state: "financialReportCollapse",
    invisible: [
      "student",
      "teacher",
      "admin",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/expenseReport",
        name: "Expense Report",
        miniName: "R",
        component: ExpenseReport,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/depositReport",
        name: "Deposit Report",
        miniName: "D",
        component: IncomeReport,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/incomeVsExpense",
        name: "Income Vs Expense",
        miniName: "D",
        component: IncomeVsExpense,
        layout: "/admin",
        invisible: ["student"],
      },
    ],
  },
  {
    collapse: true,
    name: "Setting",
    icon: "ni ni-settings-gear-65 text-blue",
    state: "settingCollapse",
    invisible: [
      "student",
      "teacher",
      "accountant",
      "librarian",
      "academicSupport",
      "admissionSupport",
    ],
    views: [
      {
        path: "/activeSession",
        name: "Active Session",
        miniName: "A",
        component: ActiveSession,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/period",
        name: "Periods",
        miniName: "P",
        component: PeriodList,
        layout: "/admin",
        invisible: ["student"],
      },
      {
        path: "/admissionFees",
        name: "Admission Fees",
        miniName: "A",
        component: AdmissionFeesList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin",
    invisible: [
      "student",
      "teacher",
      "admin",
      "superAdmin",
      "accountant",
      "librarian",
      "technicalSupport",
      "academicSupport",
      "admissionSupport",
    ],
  },
];

export default routes;
