import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import { useEffect, useState } from "react";

const useFetchSubjectsForFilter = ({
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
}) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSubjectsForFilter() {
      setLoading(true);
      try {
        const data = await getAssignSubjectForFilter(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
        setSubjects(data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      fetchSubjectsForFilter();
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  return { subjects, loading, error };
};

export default useFetchSubjectsForFilter;
