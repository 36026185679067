import { semesters as semestersData } from "constants/data/semesters";
import { useEffect, useState } from "react";

const useClassSemesters = ({ numeric, isAll = false }) => {
  const [semesters, setSemesters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBranches() {
      setLoading(true);
      try {
        const data = isAll
          ? semestersData
          : semestersData.filter((semester) =>
              semester?.classes?.some((cls) => cls.numeric === numeric)
            );
        setSemesters(data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    if (numeric || isAll) {
      fetchBranches();
    }
  }, [numeric, isAll]);

  return { semesters, loading, error };
};

export default useClassSemesters;
