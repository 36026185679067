import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import NotifyContext from "context/NotifyContext";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import {
  deleteFilterWiseExamMarks,
  getFilterWiseExamMarks,
  getFilterWiseExamSchedule,
  getResultsPublishedAndLockedStatus,
} from "network/apiMarkEntries/apiMarkEntries";
import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import React from "react";
import ReactToPrint from "react-to-print";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";
import Swal from "sweetalert2";
import AddMarks from "./AddMarks";
import EditMarks from "./EditMarks";

import { utils, writeFileXLSX } from "xlsx";

const DownloadCSVButton = ({
  examName,
  className,
  sectionName,
  subjectName,
  data,
}) => {
  const table = React.useRef();
  const handleExport = React.useCallback(() => {
    const wb = utils.table_to_book(table.current);
    writeFileXLSX(wb, `${examName} - ${subjectName} - Mark Sheet.xlsx`);
  });

  return (
    <>
      <div className="d-none">
        <table
          style={{ borderCollapse: "collapse", borderWidth: "1px" }}
          ref={table}
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="4">
                Exam {examName}
              </th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td style={{ textAlign: "center" }} colSpan="4">
                Class: ({className}), Section: ({sectionName})
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td style={{ textAlign: "center" }} colSpan="4">
                Subject: {subjectName}
              </td>
            </tr>
            <tr>
              <th>Student ID</th>
              <th>Roll</th>
              <th>Name</th>
              <>
                {data?.some((m) => m?.written) && <th>Written</th>}
                {data?.some((m) => m?.practical) && <th>Practical</th>}
                {data?.some((m) => m?.viva) && <th>Viva</th>}
                {data?.some((m) => m?.presentation) && <th>Presentation</th>}
                {data?.some((m) => m?.paper1) && <th>Paper 1</th>}
                {data?.some((m) => m?.paper2) && <th>Paper 2</th>}
                {data?.some((m) => m?.paper3) && <th>Paper 3</th>}
              </>
            </tr>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.regNo}</td>
                  <td>{item?.roll}</td>
                  <td>{item?.firstName + " " + item?.lastName}</td>
                  <>
                    {item?.written &&
                      (item?.written ? <td>{item?.written}</td> : <td>-</td>)}
                    {item?.practical &&
                      (item?.practical ? (
                        <td>{item?.practical}</td>
                      ) : (
                        <td>-</td>
                      ))}
                    {item?.attendance &&
                      (item?.attendance ? (
                        <td>{item?.attendance}</td>
                      ) : (
                        <td>-</td>
                      ))}
                    {item?.viva &&
                      (item?.viva ? <td>{item?.viva}</td> : <td>-</td>)}
                    {item?.presentation &&
                      (item?.presentation ? (
                        <td>{item?.presentation}</td>
                      ) : (
                        <td>-</td>
                      ))}
                    {item?.paper1 &&
                      (item?.paper1 ? <td>{item?.paper1}</td> : <td>-</td>)}
                    {item?.paper2 &&
                      (item?.paper2 ? <td>{item?.paper2}</td> : <td>-</td>)}
                    {item?.paper3 &&
                      (item?.paper3 ? <td>{item?.paper3}</td> : <td>-</td>)}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Button outline color="success" onClick={handleExport}>
        <span>Download CSV</span>
      </Button>
    </>
  );
};

const ExamResultAdmin = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const currentSession = JSON.parse(localStorage.getItem("session"));

  const { Notify } = React.useContext(NotifyContext);

  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [page, setPage] = React.useState(defaultPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);
  //Filters
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [allExam, setAllExam] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);
  const [filteredMarks, setFilteredMarks] = React.useState({});
  const [examSchedule, setExamSchedule] = React.useState([]);

  const [editStatus, setEditStatus] = React.useState(false);
  const [editableMarks, setEditableMarks] = React.useState([]);

  const [isLocked, setIsLocked] = React.useState(false);
  const [isPublish, setIsPublish] = React.useState(false);

  const [isProcessComplete, setIsProcessComplete] = React.useState(false);

  const [tabs, setTabs] = React.useState(1);
  const componentRef = React.useRef(null);

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  React.useEffect(() => {
    async function loadBranch() {
      setBranchId("");
      const data = await getAllBranch(page, showPerPage, searchTerm);
      setAllBranch(data?.data);
    }

    loadBranch();
  }, [page, showPerPage, searchTerm]);

  React.useEffect(() => {
    async function branchToSession() {
      setSessionName("");
      setSessionId("");
      const data = await branchWiseSession(branchId);
      setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      setClassName("");
      setClassId("");
      const data = await sessionWiseClass(sessionId);
      setAllClass(data?.data);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      setSectionName("");
      setSectionId("");
      setExamName("");
      setExamId("");
      setSubjectName("");
      setSubjectId("");
      const data = await classWiseSection(classId);
      setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  React.useEffect(() => {
    // async function branchSessionToExamList() {
    //     const data = await getBranchSessionWiseExamList(user?.branch?.branchName, user?.branch?._id, user?.session?.sessionName, user?.session?._id);
    //     setAllExam(data);
    // }
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionClassSectionToExamList();
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  React.useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAssignSubjectForFilter(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId
      );
      setAllSubject(data);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionToSubject();
    } else {
      setAllSubject([]);
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  const handleFilter = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsProcessComplete(false);
      setFilteredMarks({});
      const response = await getFilterWiseExamMarks(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );

      if (Object.keys(response[0])?.length > 0) {
        setFilteredMarks(response[0]);
        const isPublishAndLocked = await getResultsPublishedAndLockedStatus(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId,
          examId,
          subjectId
        );
        setIsPublish(isPublishAndLocked?.isPublish || false);
        setIsLocked(isPublishAndLocked?.isLocked || false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  React.useEffect(() => {
    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId &&
      examId &&
      subjectId
    ) {
      handleFilter();
    }
  }, [page, showPerPage, searchTerm, tabs]);

  const handleDelete = async () => {
    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId &&
      examId &&
      subjectId
    ) {
      const result = await Swal.fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result?.isConfirmed) {
        store.dispatch(ShowLoader());
        const response = await deleteFilterWiseExamMarks(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId,
          examId,
          subjectId
        );
        Notify("success", response?.message, "Marks");
        store.dispatch(HideLoader());
      }
    }
  };

  const handleEdit = async () => {
    store.dispatch(ShowLoader());
    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId &&
      examId &&
      subjectId
    ) {
      const editableData = await getFilterWiseExamMarks(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );
      const examSchedule = await getFilterWiseExamSchedule(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        examId,
        subjectId
      );
      setExamSchedule([]);
      setExamSchedule(examSchedule?.examSchedule);
      setEditableMarks([]);
      setEditableMarks(editableData);

      if (editableData?.length > 0 && examSchedule?.examSchedule?.length > 0) {
        setEditStatus(true);
      }

      setTabs(2);
    }
    store.dispatch(HideLoader());
  };

  return (
    <div>
      <SimpleHeader name="Results" parentName="Exam" />
      <Container className="mt--6" fluid>
        <Card>
          <Container fluid>
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 1,
                    })}
                    onClick={() => {
                      setEditStatus(false);
                      setTabs(1);
                    }}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Results
                  </NavLink>
                </NavItem>
                {user?.role !== "academicSupport" && (
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 2,
                      })}
                      onClick={() => setTabs(2)}
                      role="tab"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="ni ni-cloud-download-95 mr-2" />
                      {editStatus === false ? "Add" : "Edit"} Marks
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </div>
          </Container>
          <Container fluid>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <section>
                      <CardTitle>
                        <h3 className="mb-0">
                          <i className="ni ni-active-40 text-orange" />
                          <span
                            className="ml-2"
                            style={{ fontSize: "large", color: "orange" }}
                          >
                            Select Ground
                          </span>
                          <hr />
                        </h3>
                      </CardTitle>
                      <Row>
                        <Col md="6" sm="6" lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Branch
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setBranchName(values?.name);
                                setBranchId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  branchName === "" && branchId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allBranch?.map((branch, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: branch?.branchName,
                                    id: branch?._id,
                                  })}
                                >
                                  {branch?.branchName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6" lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Session
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setSessionName(values?.name);
                                setSessionId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  sessionName === "" && sessionId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allSession?.map((session, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: session?.sessionName,
                                    id: session?._id,
                                  })}
                                >
                                  {session?.sessionName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Class
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setClassName(values?.name);
                                setClassId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  className === "" && classId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allClass?.map((cls, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: cls?.className,
                                    id: cls?._id,
                                  })}
                                >
                                  {cls?.className}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Section
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setSectionName(values?.name);
                                setSectionId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  sectionName === "" && sectionId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allSection?.map((section, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: section?.sectionName,
                                    id: section?._id,
                                  })}
                                >
                                  {section?.sectionName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Exam
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setExamName(values?.name);
                                setExamId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  examName === "" && examId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allExam?.map((exam, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: exam?.name,
                                    id: exam?._id,
                                  })}
                                >
                                  {exam?.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Subject
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setSubjectName(values?.name);
                                setSubjectId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                disabled
                                value=""
                                selected={
                                  subjectName === "" && subjectId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allSubject?.map((subject, i) => (
                                <option
                                  key={i}
                                  value={JSON.stringify({
                                    name: subject?.label,
                                    id: subject?.value,
                                  })}
                                >
                                  {subject?.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-right">
                        <Button onClick={handleFilter} color="success">
                          Filter
                        </Button>
                      </div>
                    </section>
                    {isProcessComplete === true &&
                      Object.keys(filteredMarks)?.length > 0 && (
                        <div className="mt-4 text-center">
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                color="primary"
                                outline
                                size="md"
                                className="buttons-copy buttons-html5"
                                id="print-tooltip"
                              >
                                Print
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                          {filteredMarks?.marks?.length > 0 && (
                            <DownloadCSVButton
                              examName={filteredMarks?.exam?.name}
                              className={filteredMarks?.class?.className}
                              sectionName={filteredMarks?.section?.sectionName}
                              subjectName={filteredMarks?.subject?.subjectName}
                              data={filteredMarks?.marks}
                            />
                          )}
                          {!isLocked && (
                            <>
                              <Button
                                disabled={
                                  user?.role === "academicSupport" || isLocked
                                }
                                onClick={handleEdit}
                                outline
                                color="info"
                              >
                                Edit
                              </Button>
                              <Button
                                disabled={
                                  user?.role === "academicSupport" || isLocked
                                }
                                onClick={handleDelete}
                                outline
                                color="warning"
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </div>
                      )}

                    {isProcessComplete === true &&
                      Object.keys(filteredMarks)?.length > 0 &&
                      isLocked && (
                        <div className="mt-2 text-red text-center text-uppercase">
                          Mark is locked, not editable yet!
                        </div>
                      )}

                    {isProcessComplete === true && (
                      <>
                        {Object.keys(filteredMarks)?.length > 0 ? (
                          <div ref={componentRef}>
                            <Container fluid className="mt-4">
                              <Table responsive hover bordered>
                                <thead>
                                  <tr>
                                    <th>Student Name</th>
                                    <th>Roll</th>
                                    <th>Exam</th>
                                    <th>Subject</th>
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.written
                                    ) && <th>Written</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.practical
                                    ) && <th>Practical</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.viva
                                    ) && <th>Viva</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.presentation
                                    ) && <th>Presentation</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.paper1
                                    ) && <th>Paper 1</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.paper2
                                    ) && <th>Paper 2</th>}
                                    {filteredMarks?.marks?.some(
                                      (m) => m?.paper3
                                    ) && <th>Paper 3</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredMarks?.marks?.map((x, index) => (
                                    <tr key={index}>
                                      <td>
                                        {x?.firstName} {x?.lastName}
                                      </td>
                                      <td>{x?.roll}</td>
                                      <td>{filteredMarks?.exam?.name}</td>
                                      <td>
                                        {filteredMarks?.subject?.subjectName}
                                      </td>
                                      {x?.written &&
                                        (x?.written ? (
                                          <td>{x?.written}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.practical &&
                                        (x?.practical ? (
                                          <td>{x?.practical}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.attendance &&
                                        (x?.attendance ? (
                                          <td>{x?.attendance}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.viva &&
                                        (x?.viva ? (
                                          <td>{x?.viva}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.presentation &&
                                        (x?.presentation ? (
                                          <td>{x?.presentation}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.paper1 &&
                                        (x?.paper1 ? (
                                          <td>{x?.paper1}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.paper2 &&
                                        (x?.paper2 ? (
                                          <td>{x?.paper2}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                      {x?.paper3 &&
                                        (x?.paper3 ? (
                                          <td>{x?.paper3}</td>
                                        ) : (
                                          <td>-</td>
                                        ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Container>
                          </div>
                        ) : (
                          <div className="text-center mt-4">
                            <h3>No Data Found</h3>
                          </div>
                        )}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="tabs2">
                    {editStatus === false ? (
                      <AddMarks />
                    ) : (
                      <EditMarks
                        data={editableMarks[0]}
                        examSchedule={examSchedule[0]}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default ExamResultAdmin;
