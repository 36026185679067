import React from 'react';
import {localServer} from "../../../../api/baseURL.json";
import {useLocation} from "react-router-dom";
import qs from "querystring";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => qs.parse(search), [search]);
}

const PaymentFailed = () => {
    const data = useQuery();

    React.useEffect(() => {
        window.opener.postMessage({type: "failed", data, source: "school"}, "https://portal.sjs.edu.bd");
    }, [data]);
    return (
        <div className="text-center text-red">
            <h1>Payment Failed</h1>
        </div>
    );
};

export default PaymentFailed;
