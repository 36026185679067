import { sessionWiseClass } from "network/helpers/basedOnApis";
import { useEffect, useState } from "react";

const useFetchSessionClasses = ({ sessionId }) => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchClasses() {
      setLoading(true);
      try {
        const data = await sessionWiseClass(sessionId);
        setClasses(data?.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (sessionId) {
      fetchClasses();
    }
  }, [sessionId]);

  return { classes, loading, error };
};

export default useFetchSessionClasses;
