import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// plugins styles from node_modules
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "select2/dist/css/select2.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "../src/assets/css/hideInputFeildArrows.css";
import "../src/assets/css/printStyle.css";
import "../src/assets/css/progress.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { NotifyProvider } from "./context/NotifyContext";
import FullscreenLoader from "./helpers/Fullscreen-Loader";
import store from "./redux/store";
import PaymentCancelled from "./views/pages/studentAccounting/studentView/PaymentCancelled";
import PaymentFailed from "./views/pages/studentAccounting/studentView/PaymentFailed";
import PaymentSuccess from "./views/pages/studentAccounting/studentView/PaymentSuccess";

ReactDOM.render(
  <Provider store={store}>
    <NotifyProvider>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Route
            exact={true}
            path="/paymentSuccess"
            component={PaymentSuccess}
          />
          <Route exact={true} path="/paymentFailed" component={PaymentFailed} />
          <Route
            exact={true}
            path="/paymentCancel"
            component={PaymentCancelled}
          />
          <Route path="/auth" component={AuthLayout} />
          {/*                <Redirect from="*" to="/auth/login"/>*/}
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </BrowserRouter>
      <FullscreenLoader />
    </NotifyProvider>
  </Provider>,
  document.getElementById("root")
);
