import { FormGroup, Input } from "reactstrap";

export const SelectOption = ({
  data = [],
  label = "",
  type = "select",
  name = "",
  id,
  children,
  ...props
}) => {
  return (
    <FormGroup>
      <label
        className="d-block form-control-label"
        {...(id && { htmlFor: id })}
      >
        {label}
      </label>
      <Input type={type} name={name} {...(id && { htmlFor: id })} {...props}>
        <option>Select</option>
        {data?.length > 0 &&
          data?.map((item, index) => (
            <option key={index} value={item?.value}>
              {item?.name}
            </option>
          ))}
      </Input>
    </FormGroup>
  );
};
