import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import React from "react";
import { useHistory } from "react-router";
import { SearchPanel, SearchPanelVariant } from "react-search-panel";
import {
  Badge,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  Media,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import baseApi from "../../api/baseApi";
import {
  employeeProfilePicture,
  studentProfilePicture,
} from "../../api/baseURL.json";
import NotifyContext from "../../context/NotifyContext";
import { onMessageListener } from "../../firebase";
import { getGlobalSearchInfo } from "../../network/dashboard/apiDashboard";
import { branchWiseSession } from "../../network/helpers/basedOnApis";
import { getOneStudent } from "../../network/student/apiManageStudent";
import ChangePasswordModal from "../../views/passwordChange/ChangePasswordModal";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const history = useHistory();
  React.useEffect(() => {
    (async function check() {
      try {
        const status = await baseApi.get(`/api/check_session`);
      } catch (e) {
        if (e?.response?.data?.error?.message === "jwt expired") {
          localStorage.removeItem("user");
          localStorage.removeItem("stj_v");
          history.replace("/auth/login");
        }
      }
    })();
  }, []);
  const [notifications, setNotifications] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [session, setSession] = React.useState("");
  const [input, setInput] = React.useState("");
  const [result, setResult] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const currentSession = JSON.parse(localStorage?.getItem("session"));
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  React.useEffect(() => {
    const token = JSON.parse(localStorage.getItem("stj_v"));
    if (!token) {
      localStorage.removeItem("user");
      localStorage.removeItem("stj_v");
      history.replace("/auth/login");
    }
  }, []);

  const makeSearch = async () => {
    const response = await getGlobalSearchInfo(
      user?.branch?.branchName,
      user?.branch?._id,
      input
    );
    const choise = response?.students?.map((x) => {
      return {
        key: x?._id,
        description: `${x?.firstName} ${x?.lastName} (${x?.roll})`,
      };
    });
    setResult(choise);
  };

  const getSelectedStudent = async () => {
    const response = await getOneStudent(
      selectedChoices,
      user?.branch?.branchName,
      currentSession?.name
    );
    history.push({
      pathname: "/admin/searchView",
      state: { data: response[0] },
    });
  };

  React.useEffect(() => {
    if (input?.length > 1) {
      makeSearch();
    }
  }, [input]);

  React.useEffect(() => {
    if (selectedChoices?.length > 1) {
      getSelectedStudent();
    }
  }, [selectedChoices]);

  React.useEffect(() => {
    if (session !== "") {
      localStorage?.setItem("session", session);
    }
  }, [session]);
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(user?.branch?._id);
      await setAllSession(data?.data);
    }

    if (user?.role !== "superAdmin") {
      branchToSession();
    }
  }, [user?.branch?._id]);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("stj_v");
    history.replace("/auth/login");
  };

  const { Notify } = React.useContext(NotifyContext);

  onMessageListener().then((payload) => {
    console.log(payload);
    setNotifications([payload?.notification]);
    Notify("danger", payload?.notification?.body, payload?.notification?.title);
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    await setChangePasswordModal(true);
  };

  const handleStudentPasswordChange = async (e) => {
    e.preventDefault();
    await setChangePasswordModal(true);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            {user?.role !== "superAdmin" && user?.role !== "student" && (
              <SearchPanel
                choices={result}
                float
                maximumHeight={100}
                variant={SearchPanelVariant.link}
                onChange={(event) => setInput(event?.target?.value)}
                onSelectionChange={(e) => {
                  setSelectedChoices(e[0]?.key);
                }}
                placeholder="Search"
                shadow
                value={input}
                width={300}
              />
            )}

            {user?.role !== "superAdmin" && (
              <Form className="ml-4">
                <Input
                  style={{ width: "120px" }}
                  onChange={(e) => setSession(e.target.value)}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option value={JSON.stringify("noSessionSelected")}>
                    No Session Selected
                  </option>
                  {allSession?.map((session) => (
                    <option
                      selected={session?._id === currentSession?.id && true}
                      value={JSON.stringify({
                        name: session?.sessionName,
                        id: session?._id,
                        startDate: session?.startDate,
                        endDate: session?.endDate,
                      })}
                    >
                      {session?.sessionName}
                    </option>
                  ))}
                </Input>
              </Form>
            )}
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-bell-55" />
                  <sub>
                    <Badge
                      color="danger"
                      className="badge-sm badge-circle badge-floating border-white"
                    >
                      {notifications?.length}
                    </Badge>
                  </sub>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have{" "}
                      <strong className="text-info">
                        {notifications?.length}
                      </strong>{" "}
                      notifications.
                    </h6>
                  </div>

                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <div>
                        <h2 className="mb-0 text-sm">
                          {notifications[0]?.title}
                        </h2>
                        <p>{notifications[0]?.body}</p>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          user?.role === "student"
                            ? `${studentProfilePicture}/${user?.profilePhoto}`
                            : `${employeeProfilePicture}/${user?.profilePhoto}`
                        }
                      />
                    </span>
                    <Media
                      style={{ cursor: "pointer" }}
                      className="ml-2 d-none d-lg-block"
                    >
                      <span className="mb-0 text-sm font-weight-bold">
                        {user?.role === "student"
                          ? `${user?.firstName} ${user?.lastName}`
                          : user?.employeeName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">
                      Welcome! <b className="text-purple"> {user?.role} </b>
                    </h6>
                  </DropdownItem>
                  {user?.role !== "student" && (
                    <DropdownItem onClick={(e) => handlePasswordChange(e)}>
                      <i className="ni ni-single-02" />
                      <span>Change Password</span>
                    </DropdownItem>
                  )}

                  {user?.role === "student" && (
                    <DropdownItem
                      onClick={(e) => handleStudentPasswordChange(e)}
                    >
                      <i className="ni ni-single-02" />
                      <span>Change Password</span>
                    </DropdownItem>
                  )}

                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={logOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
        <ChangePasswordModal
          modalOpen={changePasswordModal}
          setModalOpen={setChangePasswordModal}
        />
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
