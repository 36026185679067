import React from "react";
import { Button, Input, Modal } from "reactstrap";
import NotifyContext from "../../context/NotifyContext";
import { updateEmployeePassword } from "../../network/apiManageEmployees/apiManageEmployees";
import { updateStudentPassword } from "../../network/student/apiManageStudent";

const ChangePasswordModal = ({ modalOpen, setModalOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [newPassword, setNewPassword] = React.useState("");
  const { Notify } = React.useContext(NotifyContext);

  const handleChange = async () => {
    if (user?.role === "student") {
      const update = await updateStudentPassword(
        user?.branch?.branchName,
        user?.session?.sessionName,
        user?._id,
        newPassword
      );
      if (update?.message === "Password Updated Successfully") {
        await Notify("success", update?.message, "Password");
        await setNewPassword("");
        await setModalOpen(!modalOpen);
      }
    } else {
      const response = await updateEmployeePassword(user?._id, newPassword);
      if (response === "Password Changed") {
        await Notify("success", response, "Password");
        await setNewPassword("");
        await setModalOpen(!modalOpen);
      }
    }
  };
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-sm"
        modalClassName=" bd-example-modal-xl"
      >
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="modal-title" id="exampleModalScrollableTitle">
              Change Password
            </h5>
          </div>
        </div>
        <div className="modal-body">
          <Input
            type="text"
            placeholder="Type New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <div className="mt-4 text-right">
            <Button color="warning" size="sm" onClick={handleChange}>
              Change
            </Button>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            outline
            size="lg"
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
