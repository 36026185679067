import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Modal } from "reactstrap";
import SingleSheet from "./SingleSheet";
import "./style.css";

const MarkSheetModalBulk = ({
  modalOpen,
  setModalOpen,
  className,
  classNumeric,
  semester,
  bulkMarks,
  highestMarks,
  fullMark,
  filteredSubjects,
  paperMarks,
}) => {
  const componentRef = React.useRef(null);

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-xl"
      >
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="modal-title" id="exampleModalScrollableTitle">
              Report Cards
            </h5>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  size="md"
                  className="buttons-copy buttons-html5 mr-auto ml-5"
                  id="print-tooltip"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <div ref={componentRef}>
          {bulkMarks?.length &&
            bulkMarks?.map((student, i) => (
              <SingleSheet
                key={i}
                style={{ width: "100%", marginBottom: "450px" }}
                className={className}
                classNumeric={classNumeric}
                semester={semester}
                marks={student?.marks}
                qualities={student?.qualities || {}}
                highestMarks={highestMarks}
                fullMark={fullMark}
                studentInfo={student}
                filteredSubjects={filteredSubjects}
                paperMarks={paperMarks}
              />
            ))}
          <style jsx>{`
            @media print {
              @page {
                size: 1240px 1754px; /* At 150 DPI/PPI */
                margin: 0;
              }

              .printable-content {
                width: 100%;
                height: 100%;
                margin: 0;
                margin-bottom: 0;
              }

              .page-break {
                page-break-before: always;
              }
            }
          `}</style>
        </div>
        <div style={{ marginTop: "400px" }} className="modal-footer">
          <Button
            color="primary"
            outline
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MarkSheetModalBulk;
