import React from 'react';
import {useLocation} from "react-router-dom";
import qs from "querystring";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => qs.parse(search), [search]);
}

const PaymentSuccess = () => {
    const data = useQuery();

    React.useEffect(() => {
        window.opener.postMessage({type: "success", data, source: "school"}, "https://portal.sjs.edu.bd");
    }, [data]);

    return (
        <div className="text-center text-green">
            <h1>Payment Successfully</h1>
        </div>
    );
};

export default PaymentSuccess;
