import baseApi from "../../api/baseApi";

export const sessionWiseClass = async (id) => {
    try {
        if (id) {
            const response = await baseApi.get(`/api/sessionWiseClass/${id}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const classWiseSection = async (id) => {
    try {
        if (id) {
            const response = await baseApi.get(`/api/classWiseSection/${id}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseGroup = async (id) => {
    try {
        if (id) {
            const response = await baseApi.get(`/api/branchWiseGroup/${id}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseSession = async (id) => {
    try {
        if (id) {
            const response = await baseApi.get(`/api/branchWiseSession/${id}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseSubject = async (branchName, branchId, sessionName, sessionId) => {
    try {
        if (branchName && branchId && sessionName && sessionId) {
            const response = await baseApi.get(`/api/branchWiseSubject?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseTeacher = async (branchId) => {
    try {
        if (branchId) {
            const response = await baseApi.get(`/api/branchWiseTeacher?branchId=${branchId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseEmployee = async (branchId) => {
    try {
        if (branchId) {
            const response = await baseApi.get(`/api/branchWiseEmployee?branchId=${branchId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const branchWiseAccountant = async (branchId) => {
    try {
        if (branchId) {
            const response = await baseApi.get(`/api/branchWiseAccountant?branchId=${branchId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const getStudentAsFilter = async (branchName, sessionName, branchId, sessionId, classId, sectionId) => {
    try {
        if (branchId) {
            const response = await baseApi.get(`/api/getStudentAsFilter?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const getBranchWiseMarkDistribution = async (branchId) => {
    try {
        if (branchId) {
            const response = await baseApi.get(`/api/getBranchWiseMarkDistribution?branchId=${branchId}`);
            if (response.statusText !== "OK") {
                return "Something Went Wrong";
            } else {
                return response?.data;
            }
        }
    } catch (e) {
        console.log(e.response);
    }
};
