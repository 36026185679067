import baseApi from "../../api/baseApi";

export const addAssignSubject = async (branchName, branchId, sessionName, sessionId, className, classId, classNumeric, sectionName, sectionId, subjects) => {
    const payload = {
        branchName: branchName,
        branchId: branchId,
        sessionName: sessionName,
        sessionId: sessionId,
        className: className,
        classId: classId,
        classNumeric: classNumeric,
        sectionName: sectionName,
        sectionId: sectionId,
        subjects: subjects
    };
    try {
        const response = await baseApi.post(`/api/addAssignSubject`, payload, {
            headers: { "Content-type": "application/json" }
        });
        if (response.statusText !== "OK") {
            return `"danger","Something Went Wrong","Session"`;
        } else {
            return response?.data;
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const updateOneAssignSubject = async (id, branchName, branchId, sessionName, sessionId, className, classId, classNumeric, sectionName, sectionId, subjects) => {
    const payload = {
        branchName: branchName,
        branchId: branchId,
        sessionName: sessionName,
        sessionId: sessionId,
        className: className,
        classId: classId,
        classNumeric: classNumeric,
        sectionName: sectionName,
        sectionId: sectionId,
        subjects: subjects
    };
    try {
        const response = await baseApi.put(`/api/updateOneAssignSubject/${id}`, payload, {
            headers: { "Content-type": "application/json" }
        });
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return response?.data?.message;
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const getAllAssignSubject = async (page, showPerPage, searchTerm, branchName, branchId, sessionName, sessionId, classId, sectionId) => {
    try {
        const response = await baseApi.get(`/api/getAllAssignSubject?page=${page}&limit=${showPerPage}&search=${searchTerm}&branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`);
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return response?.data;
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const getOneAssignSubject = async (id, branchName, sessionName) => {
    try {
        const response = await baseApi.get(`/api/getOneAssignSubject/${id}?branchName=${branchName}&sessionName=${sessionName}`);
        if (response.statusText !== "OK") {
            console.log("Something Went Wrong");
        } else {
            return response.data;
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const deleteAssignSubject = async (id, branchName, sessionName) => {
    try {
        const response = await baseApi.delete(`/api/deleteOneAssignSubject/${id}?branchName=${branchName}&sessionName=${sessionName}`);
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return { deleteStatus: response?.data?.message };
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const getAssignSubjectForFilter = async (branchName, branchId, sessionName, sessionId, classId, sectionId) => {
    try {
        const response = await baseApi.get(`/api/getAssignSubjectForFilter?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`);
        if (response.statusText !== "OK") {
            return "Something Went Wrong";
        } else {
            return response?.data?.data[0]?.subjects;
        }
    } catch (e) {
        console.log(e.response);
    }
};
